import { MAXIMUM_FILE_SIZE } from "constants/FileSize";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import apiLibrary from "services/api";
import { RootState } from "store";
import { fetchOrganizationProfileAction } from "store/organizationProfile/reducer.actions";
import { Toasts } from "view/components/Toasts";
import CameraIcon from "assets/icons/HeroIcons/CameraIcon";
import { setLoadingProgress } from "store/loadingBar";
import { IOrganization } from "store/organizationProfile";
import { TailSpin } from "react-loader-spinner";
import { getRandomProgress } from "utils/getRandomProgress";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";
import useCustomBranding from "hooks/useCustomBranding";

export const ProfileImage = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );
  const { theme, getBrandPlaceholder } = useCustomBranding();
  const { organizationId } = useParams();
  const { organizations } = usePermissions();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const profileImageRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      dispatch(setLoadingProgress(getRandomProgress()));

      try {
        const fileSize = file.size / 1024 / 1024; // convert bytes to mb
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error("File size must be less than 10 mb!");
        }
        setIsLoading(true);
        const uploadResponse = await apiLibrary.file.fileUpload(file);
        const { data } =
          await apiLibrary.OrganizationDirectory.updateOrganization(
            organizationProfile.id,
            {
              organizationImageId: uploadResponse.data.id,
            }
          );

        dispatch(
          fetchOrganizationProfileAction(organizationId ? organizationId : "")
        );
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading(false);
        dispatch(setLoadingProgress(100));
      }
    }
  };

  const handleClick = (imageType: "profile" | "cover") => {
    if (profileImageRef.current) {
      profileImageRef.current.click();
    }
  };

  return (
    <div className="relative flex-grow-0 flex-shrink-0 w-32 h-32 ml-10">
      <div className="relative top-0 left-0 w-32 h-32 rounded-full">

        {isLoading && (
          <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <svg
              className="w-7 h-7 animate-spin text-primaryExtraLight"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              >
              </path>
            </svg>
          </div>
        )}
        <input
          disabled={isLoading}
          type="file"
          name="profile"
          className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
          multiple={false}
          ref={profileImageRef}
          accept=".jpg, .jpeg, .png"
          onChange={(event) => handleImageUpload(event)}
        />

        {organizationProfile?.organizationImage.id || theme?.logo ?
          <img
            className="absolute object-cover h-full w-full left-[-1px] top-[-1px] rounded-full"
            height="100%"
            width="100%"
            src={
              organizationProfile?.organizationImage.file.thumb || theme?.logo
            }
            alt={organizationProfile?.name}
          />
          :
          <div className="bg-primaryExtraLight h-full w-full rounded-full flex items-center justify-center">
            <img className="h-12 w-12 object-contain" style={{ filter: "brightness(100)" }} src={getBrandPlaceholder("profile")} />
          </div>
        }
      </div>

      {organizationProfile.status !== "completed" && organizations.canEditOrganization && (
        <button
          disabled={isLoading}
          type="button"
          onClick={() => handleClick("profile")}
          className="flex justify-center items-center absolute left-[87px] top-[87px] gap-2 p-0.5 rounded-3xl bg-bgWhite dark:bg-secondaryLight w-[40px] h-[40px]"
        >
          <CameraIcon className="w-[25px] h-[25px]" />
        </button>
      )}
    </div>
  );
};
