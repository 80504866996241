import React from "react";
// Components
import { DropZone } from "../DropZone";

interface IProps {
  setFieldValue: any;
  values: any;
  errors: any;
}

export const UploadLogo: React.FC<IProps> = ({
  setFieldValue,
  values,
  errors,
}) => {
  // Which types of files are accepted
  const acceptedFileTypes = {
    "image/jpeg": [".jpg", ".jpeg", ".png"],
  };

  return (
    <>
      <p className="text-sm font-medium font-Overpass text-secondaryMid py-2">Upload Logo</p>
      <div className="w-full">
        {/* DropZone to select the images */}
        <DropZone
          setFieldValue={setFieldValue}
          values={values.logoId}
          errors={errors.logoId}
          acceptedFileTypes={acceptedFileTypes}
          text={
            "For the best result on all devices, dimensions must be maximum 512x512 pixels. The maximum file size is 25MB."
          }
          fieldValue={"logoId"}
        />
      </div>
    </>
  );
};
