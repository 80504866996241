import React, { useState, useEffect } from 'react';
import { RenderCellContentProps } from '../../types';
import ellipsize from 'ellipsize';
import useDateFormatter from 'hooks/formatDateWithPattern';
import { Statuses } from 'Components/QualityAssurance/Statuses';
import apiLibrary from 'services/api';
import { Toasts } from 'view/components/Toasts';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { filtersInitialState } from 'store/filters/initialState';
import ReviewersDropdown from '../ReviewersDropdown';
import useRoles from 'hooks/roles';
import { fetchQualityAssuranceObservationsAction } from 'store/qualityAssurance';
import { useParams } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import usePermissions from 'hooks/usePermissions';
import { Link } from 'react-router-dom';
import useCustomBranding from 'hooks/useCustomBranding';

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
  observationId,
  programManagers,
  rowData,
}) => {
  const [selectedReviewer, setSelectedReviewer] = useState('');
  const { qualityAssuranceObservationsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const [changeColor, setChangeColor] = useState<any>();
  const { communityId, programId } = useParams();
  const { observations } = usePermissions();
  const { getBrandPlaceholder } = useCustomBranding();
  const { isInternalReviewer, isExternalReviewer, isUserInternalReviewer } =
    useRoles();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const link = `/quality-assurance/${rowData?.original?.id}/profile`;
  const StatusColor = () => {
    switch (rowData.original.flag) {
      case 'red':
        setChangeColor(' bg-accent_1Light text-accent_1Dark');
        break;
      case 'green':
        setChangeColor('bg-tertiaryLight  text-tertiary');
        break;
      case 'blue':
        setChangeColor('bg-primaryExtraLight text-textLink');
        break;
      case 'grey':
        setChangeColor('bg-brandHover text-secondaryMid');
        break;
      case 'orange':
        setChangeColor('bg-accent_1LightExtraMid text-accent_tertiary_red');
        break;
      default:
        setChangeColor('bg-primaryExtraLight text-textLink');
        break;
    }
  };
  useEffect(() => {
    StatusColor();
  }, [rowData.original.flag]);
  const allowedStatuses = [
    'assigned_to_me',
    'assigned_to_others',
    'unassigned',
  ];
  const isFilterMatch = allowedStatuses.includes(
    qualityAssuranceObservationsFilter?.status
  );

  const handleReviewerChange = async (event: any) => {
    setSelectedReviewer(event.target.value as string);    
    try {
      const res = await apiLibrary.QualityAssurance.attachReviewer(
        observationId,
        event.target.value
      );
      Toasts.success(res.message);
      dispatch(fetchQualityAssuranceObservationsAction(communityId, programId));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    }
  };

  const { formatDate } = useDateFormatter();

  if (
    typeof cellValue === 'object' &&
    cellValue !== null &&
    (columnDef.id === 'programName' ||
      columnDef.id === 'observer' ||
      columnDef.id === 'communityName')
  ) {
    return (
      <Link to={link}>
        <div className='flex justify-start w-[250px] items-center relative '>
          <img
            src={
              cellValue?.image?.thumb
                ? cellValue?.image?.thumb
                : getBrandPlaceholder("listingProfile")
            }
            alt=''
            height='100'
            width='100'
            className='object-cover mx-2 Img_user_Data'
            style={{ flexShrink: 0 }}
          />
          <div
            style={{ flexWrap: 'wrap' }}
            className=' flex flex-col w-full justify-start items-start py-2'
          >
            <p className='text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark'>
              {ellipsize(`${cellValue?.name}`, 50)}
            </p>
          </div>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === 'observationCode') {
    return (
      <Link to={link}>
        <div
          className={`relative flex items-start flex-grow py-1 px-3 ${
            columnDef.id === 'address' ? 'justify-start' : 'justify-start'
          }`}
        >
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {ellipsize(`${cellValue.observationCode}`, 150)}
          </p>
        </div>
      </Link>
    );
  } else if (cellValue !== null && columnDef.id === 'reviewer') {
    if (isExternalReviewer()) {
      return (
        <div className='flex justify-start w-[150px] items-center relative'>
          <div
            style={{ flexWrap: 'wrap' }}
            className='flex flex-col w-full justify-start items-start relative gap-0.5 px-3'
          >
            <p className='text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark'>
              {ellipsize(`${cellValue?.name}`, 50)}
            </p>
          </div>
        </div>
      );
    }
    return isFilterMatch ? (
      <div className='flex gap-1'>
          <ReviewersDropdown
            placeholder={
              cellValue?.name === ' ' ? 'Assign to' : cellValue?.name
            }
            data={programManagers}
            value={selectedReviewer}
            handleChange={handleReviewerChange}
          />
      </div>
    ) : (
      <div className='flex justify-start w-[150px] items-center relative'>
        <div
          style={{ flexWrap: 'wrap' }}
          className='flex flex-col w-full justify-start items-start relative gap-0.5'
        >
          <p className='text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark'>
            {ellipsize(`${cellValue?.name}`, 50)}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === 'dateUpdated') {
    return (
      <Link to={link}>
        <div
          className={`relative flex items-start flex-grow py-1 px-3 ${
            columnDef.id === 'address' ? 'justify-start' : 'justify-start'
          }`}
        >
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {formatDate(cellValue?.toString())}
          </p>
        </div>
      </Link>
    );
  } else if (columnDef.id === 'status' && cellValue !== null) {
    const updatedStatus =
      cellValue.toLowerCase() === 'completed'
        ? 'complete'
        : cellValue.toLowerCase() === 'incompleted'
          ? 'incomplete'
          : cellValue;
    return (
      <Link to={link}>
        <div className='w-[130px] status'>
          <Statuses status={updatedStatus} changeColor={changeColor} />
        </div>
      </Link>
    );
  } else if (cellValue !== null) {
    return (
      <Link to={link}>
        <div
          className={`relative flex items-start flex-grow py-1 px-3 ${
            columnDef.id === 'address' ? 'justify-start' : 'justify-start'
          }`}
        >
          <p
            className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}
          >
            {ellipsize(`${cellValue}`, 50)}
          </p>
        </div>
      </Link>
    );
  } else {
    return null;
  }
};
