import usePermissions from "hooks/usePermissions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "store";
import { programsCommunityAction } from "store/filters";
import { openContactInfoModalAction } from "store/modals";
import { IOrganization } from "store/organizationProfile";

const generateDynamicProgramLinks = (organizationProfile: any) => {
  const { programs, organizations } = usePermissions();
  return [
    // {
    //   title: "Observations",
    //   link: `/programs/${organizationProfile?.id}/observations`,
    // },

    // {
    //   title: "Organizations",
    //   link: `/programs/${organizationProfile?.id}/organizations`,
    // },
    {
      title: "Communities",
      link: `/organizations/${organizationProfile?.id}/communities`,
      isVisible: true,
    },
    {
      title: "Programs",
      link: `/organizations/${organizationProfile?.id}/programs`,
      isVisible: programs.canViewProgram,
    },
    {
      title: "Members",
      link: `/organizations/${organizationProfile.id}/users`,
      isVisible: true,
    },
    {
      title: "Contact Info",
      link: ``,
      isVisible: organizations.canViewContactInfo,
    },
    // { title: "Forms", link: `/programs/${organizationProfile?.id}/forms` },
    // {
    //   title: "Protocols",
    //   link: `/programs/${organizationProfile?.id}/protocols`,
    // },
    // { title: "Files", link: `/programs/${organizationProfile?.id}/files` },
    // {
    //   title: "Data Access Policy",
    //   link: `/programs/${organizationProfile?.id}/dataAccessPolicy/${organizationProfile?.dapId}`,
    // },
    // {
    //   title: "Management",
    //   link: `/programs/${organizationProfile?.id}/management`,
    // },
  ];
};

export const ProfileNavigationLinks = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const navigationLinks = generateDynamicProgramLinks(organizationProfile);

  const handleClick = (event: any, link: any) => {
    if (link.title === "Contact Info") {
      dispatch(openContactInfoModalAction(organizationProfile?.contactInfo));
    }
  };

  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-8">
      <div className="flex flex-wrap items-center justify-start gap-2 ml-20">
        {navigationLinks?.map(
          (link: any, index: number) =>
            link.isVisible && (
              <Link
                key={index}
                to={link.link}
                onClick={(event) => handleClick(event, link)}
                className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg bg-primaryExtraLight h-[32px] mr-1 hover:bg-primary_20 dark:bg-primary_40"
              >
                <div className="h-[32px] flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2 rounded">
                  <button className="flex-grow-0 flex-shrink-0 p-1 text-sm text-center text-secondaryMid dark:text-textMain ">
                    {link.title}
                  </button>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};
