import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import useCustomBranding from "hooks/useCustomBranding";

interface TooltipProps {
  title: string;
  children: any;
  showTooltip?: boolean; // Receive showTooltip from props
  toggleTooltip?: () => void;
  moveFromRight?: boolean | undefined;
}

const CustomToolTip: React.FC<TooltipProps> = ({
  title,
  children,
  showTooltip,
  toggleTooltip,
  moveFromRight,
}) => {
  const { primaryColor } = useCustomBranding();
  return (
    <Tooltip
      title={title}
      arrow
      open={showTooltip}
      onOpen={toggleTooltip}
      onClose={toggleTooltip}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: primaryColor,
            marginRight: moveFromRight ? "22px" : "0px",
            "& .MuiTooltip-arrow": {
              color: primaryColor,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
