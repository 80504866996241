import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import BpCheckbox from "view/components/CheckBox";
import { Box } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { useDispatch } from "react-redux";
import { closeViewDnsBrandModalAction } from "store/modals";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { CheckIcon, CopyIcon } from "assets/icons/HeroIcons";
import { fetchDNSRecordAction } from "store/viewDNSRecord";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { IfetchDNSRecord } from "store/viewDNSRecord/initialState";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  // width: auto,
  backgroundColor: "#ffff",
};

export const viewDnsbrandModal: React.FC<any> = () => {
  const { viewDnsBrandModal } = useSelector((state: RootState) => state.modals);
  const { data, stateIs } = useSelector<RootState, IfetchDNSRecord>(
    (state) => state?.viewDNSRecord
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [updateCopyData, setUpdateCopyData] = useState("");

  const handleClose = () => {
    dispatch(closeViewDnsBrandModalAction());
  };

  useEffect(() => {
    dispatch(fetchDNSRecordAction(viewDnsBrandModal.data.id));
  }, [viewDnsBrandModal?.data?.id]);

  const handleCopyClick = (item: any) => {
    setIsLoading(true);
    setUpdateCopyData(item.value);
    navigator.clipboard
      .writeText(item.value)
      .then(() => {
        setTimeout(() => {
          setUpdateCopyData("");
          setIsLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };
  return (
    <Modal
      open={viewDnsBrandModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight">
        <div className="flex flex-col justify-start items-start w-[900px] rounded-lg">
          <div className="w-full pt-2 flex justify-end">
            <button onClick={handleClose} className="mr-3">
              <XCloseIcon className="" fill="#2C3236" />
            </button>
          </div>
          <div className="w-full pl-7 pr-7 pb-7">
            <p className="text-3xl font-bold dark:text-textMain pb-5 capitalize">
              {viewDnsBrandModal?.data?.name}
            </p>
            <table className="w-full border-gray-300 rounded-lg shadow-md">
              <thead className="bg-bgBluish_2 dark:bg-bgtetriary">
                <tr className="border-b bg-gray-100 text-left">
                  <th className="p-3"></th>
                  <th className="p-3 text-left text-sm font-medium text-secondaryMid dark:text-caption">
                    Host
                  </th>
                  <th className="p-3  text-left text-sm font-medium text-secondaryMid dark:text-caption">
                    Value
                  </th>
                  <th className="p-3 w-32 text-left text-sm font-medium text-secondaryMid dark:text-caption">
                    Record Type
                  </th>
                  <th className="p-3  text-left text-sm font-medium text-secondaryMid dark:text-caption">
                    TTL
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item: any, index: number) => (
                  <tr
                    key={index}
                    className="border-b hover:bg-gray-50 transition"
                  >
                    <td className="p-3"></td>
                    <td className="p-3 text-left text-sm text-textMid dark:text-caption">
                      {item.name}
                    </td>
                    <td className="p-3 text-left text-sm text-textMid dark:text-caption">
                      {item.value}
                    </td>
                    <td className="p-3 text-left text-sm text-textMid dark:text-caption">
                      {item.recordType}
                    </td>
                    <td className="p-3 text-left text-sm text-textMid dark:text-caption">
                      {item.ttl}
                    </td>
                    <td className="p-3 text-left">
                      <button
                        onClick={() => handleCopyClick(item)}
                        disabled={isLoading}
                        className="w-[143px] h-[40px] bg-primary text-textWhite rounded-lg flex items-center justify-center gap-2 text-sm font-semibold"
                      >
                        {item.value !== updateCopyData ? (
                          <>
                            <span>
                              <CopyIcon fill="#FFFFFF" />
                            </span>
                            Copy
                          </>
                        ) : (
                          <>
                            <span>
                              <CheckIcon fill="#FFFFFF" />
                            </span>
                            Copied{" "}
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
