import React, { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import SearchInput from 'view/components/SearchInput';
import usePermissions from 'hooks/usePermissions';
import { messagesContactPersonSearchAction } from 'store/filters';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import apiLibrary from 'services/api';
import { fetchContactPersonsAction } from 'store/messages/reducer.actions';
import { filtersInitialState } from 'store/filters/initialState';
import { getBrandPlaceholder } from 'utils/customBranding';
import useCustomBranding from 'hooks/useCustomBranding';

interface ContactsListProps {
  selectedContact: any;
  contactPersonList: any;
  handleSelectedContact: (contact: any) => void;
  handleSelectedOption: (value: any) => void;
}

const ContactsList: React.FC<ContactsListProps> = ({
  selectedContact,
  handleSelectedContact,
}) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [scrollHeight, setScrollHeight] = useState<any>();
  const { messageContactPersonSearchFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state?.Filters);
  const { messageAppIdFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const { primaryColor } = useCustomBranding();

  const [contacts, setContacts] = useState<any>([]);

  const { conversations } = usePermissions();
  const dispatch: any = useDispatch();

  const categorizedContacts = processContacts(contacts);

  const fetchContactsData = async (isLoadMore: boolean, page: number = 1) => {
    setIsLoading(true);
    try {
      const data = {
        searchString: searchValue,
        newChat: true,
        page: page,
        appName: messageAppIdFilter?.appId
      };
      const response = await apiLibrary.Messages.getAllContactPersons(data);
      if (isLoadMore) {
        setContacts((pre: any[]) => {
          return [...pre, ...response?.data?.contactPersons];
        });
      } else {
        setContacts(response?.data?.contactPersons);
      }
      setTotalPages(response?.data?.totalPages);
      setHasMoreData(response?.data?.totalPages > page);
    } catch (error: any) {
      console.error('Failed to fetch program dropdown values:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      fetchContactsData(true, page);
      return page;
    });
  };
  useEffect(() => {
    fetchContactsData(false);
  }, [searchValue]);

  useEffect(() => {
    searchValue?.length &&
      dispatch(messagesContactPersonSearchAction(searchValue));
  }, [searchValue]);

  useEffect(() => {
    messageContactPersonSearchFilter?.searchString &&
      dispatch(fetchContactPersonsAction(messageAppIdFilter?.appId));
  }, [messageContactPersonSearchFilter]);

  useEffect(() => {
    const handleResize = () => {
      // Set breakpoint for 'lg' screen size; adjust px value as needed
      const lgBreakpoint = 1440;
      if (window.innerWidth > lgBreakpoint) {
        setScrollHeight(window.innerHeight - 140); // or any value you want for large screens
      } else {
        setScrollHeight(window.innerHeight - 130); // or any value you want for smaller screens
      }
    };

    // Set initial height
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {conversations.canCreateConversations && (
        <div className='w-full px-2 mb-6'>
          <SearchInput
            type='text'
            placeholder='Search name'
            inputValue={searchValue}
            handleOnChange={setSearchValue}
          />
        </div>
      )}

      {conversations.canViewContactPersons && (
        <div
          id='scrollableDiv'
          className='md:max-h-[77vh] 2xl:max-h-[79vh] overflow-y-auto'
        >
          <InfiniteScroll
            dataLength={contacts?.length}
            next={handleLoadMore}
            hasMore={hasMoreData}
            scrollableTarget='scrollableDiv'
            loader={
              <div className='flex items-center justify-center w-full loader'>
                <TailSpin
                  height='30'
                  width='30'
                  color={primaryColor}
                  ariaLabel='tail-spin-loading'
                  radius='2'
                  wrapperStyle={{}}
                  wrapperClass='tailspin-loader'
                  visible={true}
                />
              </div>
            }
            pullDownToRefresh={true}
            refreshFunction={() => { }}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8595; Pull down to refresh &#8595;
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>
                &#8593; Release to refresh &#8593;
              </h3>
            }
          >
            <h3 className='text-lg text-secondary dark:text-textMain font-medium pl-5 mb-3'>
              Contacts
            </h3>

            {contacts?.length === 0 && !isLoading && (
              <div className='w-full flex items-center justify-center py-32 text-textMid font-semibold dark:text-inputText'>
                No Record Found
              </div>
            )}

            {hasMoreData && isLoading && (
              <div className='flex items-center justify-center w-full loader'>
                <TailSpin
                  height='30'
                  width='30'
                  color={primaryColor}
                  ariaLabel='tail-spin-loading'
                  radius='2'
                  wrapperStyle={{}}
                  wrapperClass='tailspin-loader'
                  visible={true}
                />
              </div>
            )}

            {Object?.keys(categorizedContacts)?.length > 0 &&
              Object?.keys(categorizedContacts)
                .sort()
                .map((letter: string) => (
                  <div key={letter} className='mb-4'>
                    <h3 className='text-base text-textLightExtra font-medium pl-5 mb-2'>
                      {letter}
                    </h3>
                    {categorizedContacts[letter]?.map(
                      (contact: any, index: number, array: any[]) => (
                        <>
                          <div
                            className={`flex justify-between gap-3 py-2 items-center mb-2 cursor-pointer rounded-md  px-2  ${selectedContact?.userId === contact?.id
                              ? 'bg-primaryExtraLight dark:bg-primaryExtraLight group isActive'
                              : ''
                              }`}
                            id={`conversation${contact?.value}`}
                            onClick={() => handleSelectedContact(contact)}
                            key={contact?.value}
                          >
                            <div className='rounded-full w-9 h-9 shrink-0'>
                              <img
                                src={contact?.personImage || getBrandPlaceholder("userProfile")}
                                alt='Img'
                                className='w-full h-full rounded-full'
                              />
                            </div>
                            <div className='flex-1'>
                              <div className='flex items-center justify-between'>
                                <h3
                                  className={`text-[15px]font-medium text-secondary font-Overpass w-full ${selectedContact?.userId === contact?.id
                                    ? 'dark:text-secondary'
                                    : 'dark:text-textMain'
                                    }`}
                                >
                                  {contact?.firstName} {contact?.lastName}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`ml-14 mr-3 my-2 ${index < array?.length - 1 ? 'border-b border-lineMid dark:border-lineLight' : ''}`}
                          ></div>
                        </>
                      )
                    )}
                  </div>
                ))}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default ContactsList;

// categorized contacts
export function processContacts(contacts: any) {
  const categorizedContacts = contacts?.reduce((acc: any, contact: any) => {
    const firstLetter = contact?.firstName?.charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(contact);
    return acc;
  }, {});

  return categorizedContacts;
}
