import React, { FC, useCallback, useEffect, useState } from "react";
// Components
import { AddOrganizations } from "./Components/AddOrganizations";
import { Toasts } from "view/components/Toasts";
import apiLibrary from "services/api";
import TextInput from "view/components/InputFields/TextInput";
import { ViewOrganization } from "./Components/ViewOrganization";
import brandPlaceholder from "assets/images/Placeholders/brandPlaceholderImg.png";
import { useParams } from "react-router-dom";

interface IProps {
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  values: any;
  editMode?: boolean;
}

export const Details: React.FC<IProps> = ({
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  values,
  editMode
}) => {
  // State to store the organizations
  const [entity, setEntity] = useState<any>({ organizations: [] });

  // useEffect to execute the  fetchOrganization data
  useEffect(() => {
    fetchAllOrganizations(values.organizationId);
  }, [values.organizationId]);

  // Convert organization data into object
  const convertDataForMultiselectDropDowns = (data: any) => {
    return data.map(({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    }));
  };

  // Fetch all organizations
  const fetchAllOrganizations = useCallback(
    async (organizationId: any, searchQuery = "") => {
      try {
        const queryParams: any = {};
        // Add organizationId to queryParams if it exists
        if (organizationId) {
          queryParams.organizationId = organizationId.value;
        }

        // Add searchQuery in the required format
        if (searchQuery.trim() !== "") {
          queryParams.searchString = searchQuery.trim();
        }

        // Fetch data from API
        const { data } =
          await apiLibrary.Brands.getAllOrganizations(queryParams);

        // Handle empty or missing data
        const organizations = data?.organizations || [];

        // Convert data and log the result
        const convertedData = convertDataForMultiselectDropDowns(organizations);

        // Update the state with fetched organizations
        setEntity((prev: any) => {
          const updatedState = {
            ...prev,
            organizations: convertedData, // Always use converted data
          };
          return updatedState;
        });
      } catch (error: any) {
        console.error("Error fetching organizations:", error);
        Toasts.error(error.message || "Failed to fetch data");
      }
    },
    [setEntity]
  );

  // Function to Search the organizations
  const handleSearchOrganization = (searchString: string) => {
    fetchAllOrganizations(values.organizationId, searchString);
  };

  return (
    <div className="w-full bg-bgWhite py-6 rounded-lg">
      <div className="px-12">
        <p className="text-secondaryMid font-Overpass text-xl font-bold py-2">Details</p>
        {/* Organization selection Field */}
        {/* Organization (Full Row) */}
        <div className="flex items-start col-span-full space-x-4">
          <div className="relative">
            <img
              src={brandPlaceholder}
              alt="Organization"
              className="w-12 h-12 object-contain"
            />
          </div>
          <div className="mt-1">
            <p className="text-sm font-Overpass text-textMidLight">Organization</p>
            <p className="text-sm font-Overpass text-textNormal">{values.organizationId?.label}</p>
          </div>
        </div>

        {!editMode ?
          <ViewOrganization
            values={values}
          />
          :
          <div className="mt-5">
            {/* Brand Name Field */}
            <TextInput
              label={"Name"}
              type="text"
              placeholder={"Name"}
              name="name"
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.name}
              error={errors.name}
              touched={touched.name}
              inputClassName="w-full"
              labelClassName="font-Overpass"
              required={true}
            />
          </div>
        }
      </div>
    </div>
  );
};
