import {
  COUNT_DETAILS_QUESTIONS_NAME,
  GPS_INFO_QUESTIONS_NAME,
  HARVEST_QUESTIONS_NAME,
  RIVER_CONDITION_QUESTIONS_NAME,
  SAMPLE_QUESTIONS_NAME,
  SIGHTING_DETAILS_QUESTIONS_NAME,
  SPECIES_DETAILS_QUESTIONS_NAME,
  WEATHER_CONDITIONS_QUESTIONS_NAME,
} from "./panelsQuestionsName";

export const RIVER_CONDITION_QUESTIONS_CHOICES = {
  [RIVER_CONDITION_QUESTIONS_NAME.RiverLevel]: [
    "<1/4ft",
    "1/4ft-1/2ft",
    "1/2ft-2ft",
    "2ft-4ft",
    "4ft-6ft",
    "6ft-10ft",
    "10ft-14ft",
    "14ft-18ft",
    "18ft-22ft",
    "22ft-26ft",
    ">26ft",
    "High water",
    "Low water",
    "Normal water level",
    "Drought conditions",
    "New flow conditions",
    "Flooding",
  ],
  [RIVER_CONDITION_QUESTIONS_NAME.WaterClarity]: ["Clear", "Muddy", "Tanic"],
  [RIVER_CONDITION_QUESTIONS_NAME.TemperatureType]: ["Celsius", "Fahrenheit"],
};

export const GPS_INFO_QUESTIONS_CHOICES = {
  [GPS_INFO_QUESTIONS_NAME.CoordinatesFormat]: [
    "Degrees, minutes, seconds",
    "Decimal degrees",
    "Degrees, decimal minutes",
  ],
  [GPS_INFO_QUESTIONS_NAME.AltitudeFormat]: ["Metric System", "Imperial System " ],
};

export const SPECIES_DETAILS_QUESTIONS_CHOICES = {
  [SPECIES_DETAILS_QUESTIONS_NAME.PresenceAbsence]: [
    "present",
    "absent/not observed",
  ],
  [SPECIES_DETAILS_QUESTIONS_NAME.IdConfidence]: [
    "Sure",
    "Likely",
    "Not Sure",
    "Unconfirmed",
    "Not Applicable",
  ],
  [SPECIES_DETAILS_QUESTIONS_NAME.Sex]: [
    "Male",
    "Female",
    "Combined",
    "Unknown",
  ],
  [SPECIES_DETAILS_QUESTIONS_NAME.AgeClass]: [
    "Juvenile",
    "Newborn (Pup/Calf/Chick)",
    "Unknown",
    "Combined",
    "Subadult",
    "Yearling",
    "Adult",
    "Ocean adult",
    "Spawning adult",
    "Egg",
    "Alevin",
    "Fry",
    "Fingerling",
    "Smolt",
    "Jack salmon",
    "Yearling",
    "Jack salmon",
    "Harem Bull",
    "Year Class/Age 1",
    "Year Class/Age 2",
    "Year Class/Age 3",
    "Year Class/Age 4",
    "Year Class/Age 5",
    "Year Class/Age 6",
    "Year Class/Age 7+",
  ],
};

export const COUNT_DETAILS_QUESTIONS_CHOICES = {
  [COUNT_DETAILS_QUESTIONS_NAME.CountUnits]: [
    "Number",
    "mm",
    "Block Count",
    "degrees C",
    "degrees F",
    "cm",
    "inches",
    "Kilograms",
    "Ounces",
    "Pounds",
    "Gallons",
    "Barrels",
  ],
};

export const SIGHTING_DETAILS_QUESTIONS_CHOICES = {
  [SIGHTING_DETAILS_QUESTIONS_NAME.CoordinatesFormat]:[
    "Degrees, minutes, seconds",
    "Decimal degrees",
    "Degrees, decimal minutes"
  ],
  [SIGHTING_DETAILS_QUESTIONS_NAME.ObservationMethod]: [
    "naked eye",
    "binos.",
    "spotting scope",
    "UAS",
    "Other Method",
    "bigeye bino",
    "bino-10x42",
  ],
  [SIGHTING_DETAILS_QUESTIONS_NAME.ObservationDirection]: [
    "N",
    "S",
    "E",
    "W",
    "NE",
    "NW",
    "SE",
    "SW",
    "NNE",
    "NNW",
    "ENE",
    "ESE",
    "SSE",
    "SSW",
    "WSW",
    "WNW",
    "Overhead",
  ],
  [SIGHTING_DETAILS_QUESTIONS_NAME.DistanceUnits]: [
    "Feet",
    "Yards",
    "Miles",
    "Nautical Miles",
    "Meters",
    "Kilometers",
    "Centimeters",
    "Inches",
  ],
  [SIGHTING_DETAILS_QUESTIONS_NAME.DistanceMethod]: [
    "visual estimate",
    "range finder",
    "known distance",
  ],
  [SIGHTING_DETAILS_QUESTIONS_NAME.Visibility]: [
    "None",
    "excellent",
    "good",
    "fair",
    "poor",
    "twilight",
    "dark",
    "none",
    "obstructed-human",
    "obstructed-animal",
    "obstructed-landscape",
  ],
};

export const WEATHER_CONDITIONS_QUESTIONS_CHOICES = {
  [WEATHER_CONDITIONS_QUESTIONS_NAME.WeatherMethod]: [
    "On-Site Estimate",
    "Airport Weather Station",
    "National Weather Service",
    "Davis Weather Station",
    "Kestrel 3000",
    "Other Method",
    "CATG Weather Station",
    "NOAA Weather Station",
  ],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.WindSpeed]: [
    "<1mph (BFT 0)",
    "1-3mph (BFT 1)",
    "4-7mph (BFT 2)",
    "8-12mph (BFT 3)",
    "13-18mph (BFT 4)",
    "19-24mph (BFT 5)",
    "25-31mph (BFT 6)",
    "32-38mph (BFT 7)",
    "39-46mph (BFT 8)",
    "47-54mph (BFT 9)",
  ],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.WindDirection]: [
    "N",
    "S",
    "E",
    "W",
    "NE",
    "NW",
    "SE",
    "SW",
    "NNE",
    "NNW",
    "ENE",
    "ESE",
    "SSE",
    "SSW",
    "WSW",
    "WNW",
    "Variable",
    "Offshore",
    "Onshore",
  ],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.SeaState]: [
    "<1/4ft",
    "1/4ft-1/2ft",
    "1/2ft-2ft",
    "2ft-4ft",
    "4ft-6ft",
    "6ft-10ft",
    "10ft-14ft",
    "14ft-18ft",
    "18ft-22ft",
    "22ft-26ft",
    ">26ft",
    "High water",
    "Low water",
    "Normal water level",
    "Drought conditions",
    "New flow conditions",
    "Flooding",
  ],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.Tide]: ["Low", "Mid", "High"],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.AirTemperatureUnit]: [
    "Celsius",
    "Fahrenheit",
  ],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.WeatherCondition]: [
    "clear",
    "fog/mist",
    "hail",
    "high overcast",
    "low clouds",
    "overcast",
    "partly sunny",
    "sleet",
    "sunny",
    "drizzle",
    "glare",
    "heat wave on water",
    "lightning",
    "no observation",
    "partly cloudy",
    "rain",
    "snow",
    "thunder",
  ],
  [WEATHER_CONDITIONS_QUESTIONS_NAME.HorizonVisibility]: [
    "Excellent",
    "Good",
    "Fair",
    "Poor",
  ],
};

export const HARVEST_QUESTIONS_CHOICES = {
  [HARVEST_QUESTIONS_NAME.HarvestMethod]: [
    "Rifle",
    "Hook&Line",
    "Longline",
    "Handpicked",
    "Shotgun",
    "Other",
    "Rod and Reel",
    "Set Net",
    "Club",
    "N/A",
  ],
  [HARVEST_QUESTIONS_NAME.RetrievalMethod]: [
    "Sea Dog",
    "Boat",
    "Gaff Hook",
    "ATV",
    "Vehicle",
    "Handpick",
  ],
};

export const SAMPLE_QUESTIONS_CHOICES = {
  [SAMPLE_QUESTIONS_NAME.SampleType]: [
    "Adrenals",
    "Blubber",
    "Brain",
    "Colon",
    "Esophagus",
    "Fetus",
    "Gonads-Testes",
    "Hair/Fur",
    "Heart",
    "Intestine",
    "Kidney",
    "Liver",
    "Lung",
    "Lymph Nodes-Axillary",
    "Lymph Nodes-Hilar",
    "Lymph Nodes-Mesenteric",
    "Lymph Nodes-Other",
    "Lymph Nodes-Prescapular",
    "Lymph Nodes-Submandibular",  
    "Mammary Tissue",
    "Milk",
    "Muscle",
    "Ovaries",
    "Pancreas",
    "Reproductive Tract",
    "Scat",
    "Skin",
    "Skin-Genetics",
    "Skull",
    "Spleen",
    "Stomach",
    "Teeth",
    "Teeth-Lower Canine",
    "Teeth-Upper Canine",
    "Teeth-Upper Left Canine",
    "Teeth-Upper Right 2nd Premolar",
    "Teeth-Upper Right Canine",
    "Thymus",
    "Thyroid",
    "Trachea",
    "Vibrissae/Whisker",
    "Entanglement Debris-Packing Band",
    "Entanglement Debris-Trawl Net",
    "Entanglement Debris-Gill Net",
    "Entanglement Debris-Misc Net",
    "Entanglement Debris-Line",
    "blood",
    "CRRC Samples"
  ],
  [SAMPLE_QUESTIONS_NAME.AmountUnits]: [
    "g",
    "mg",
    "kg",
    "lb",
    "mL",
    "oz"
  ],
};
