import getAllBrands from "./getAllBrands";
import getBrand from "./getBrand";
import getAllOrganizations from "./getAllOrganizations";
import addBrand from "./addBrand";
import archiveOrRestoreBrand from "./archiveOrRestoreBrand";
import updateBrand from "./updateBrand";
import getDnsRecords from "./getDnsRecords";
import getBrandDetailByUrl from "./getBrandDetailByUrl";
import getBrandDetailsByBrandCode from "./getBrandDetailsByBrandCode";

const Brands = {
  getAllBrands,
  getBrand,
  getBrandDetailByUrl,
  getBrandDetailsByBrandCode,
  getAllOrganizations,
  addBrand,
  updateBrand,
  archiveOrRestoreBrand,
  getDnsRecords
};

export default Brands;
