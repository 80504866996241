import React, { useEffect, useMemo, useState } from "react";
// React Hooks
import { useNavigate, useParams } from "react-router-dom";
// Formik
import * as Yup from "yup";
import { Form, Formik } from "formik";
// Components
import { Header } from "../Header";
import { Details, Logos, Settings, Styling } from "./Components";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { fetchSingleBrandAction } from "store/brandDetail";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { IfetchBrand } from "store/brandDetail/initialState";

interface BrandProps {
}

export const BrandForm: React.FC<BrandProps> = ({
}) => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const [editMode, setEditMode] = useState(false);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data, stateIs } = useSelector<RootState, IfetchBrand>(
    (state) => state?.singleBrand
  );

  //  Initial Values state
  const [formInitialValues, setFormInitialValues] = useState<any>({
    organizationId: null,
    name: "",
    adminUrl: "",
    appUrl: "",
    interoperability: false,
    type: "",
    logoId: null,
    faviconImageId: null,
    theme: {},
    loginImageId: {},
    passwordImageId: {},
    status: "", 
    dnsPropagationStatus: ""
  });

  const initialValues = useMemo(() => {
    if (!data.brand) return formInitialValues;
    const { organization, adminUrl, appUrl, brandName, favicon, forgetPasswordImage, logo, status, theme, type, loginImage, dnsPropagationStatus } = data.brand;

    return {
      organizationId: {
        label: organization?.name,
        value: organization?.id,
      },
      name: brandName,
      adminUrl,
      appUrl,
      interoperability: data.brand.interoperability,
      type,
      logoId: logo,
      faviconImageId: favicon,
      theme: {
        ...theme,
        primaryColor: theme.colorCode,
      },
      loginImageId: loginImage,
      passwordImageId: forgetPasswordImage,
      status,
      dnsPropagationStatus
    };
  }, [data.brand]);

  const viewMode: boolean = !!brandId && !editMode; // !! Converts value to boolean

  // Schema for brand's form
  const brandFormValidationSchema = Yup.object().shape({
    organizationId: Yup.object().shape({
      value: Yup.number().required("Organization is required"),
      label: Yup.string().required("Organization is required"),
    }),
    name: Yup.string().required("Name is Required"),
    adminUrl: Yup.string()
      .required("Admin URL is required")
      .url("Please enter a valid Admin URL"), // Ensures it's a valid URL
    appUrl: Yup.string()
      .required("App URL is required")
      .url("Please enter a valid App URL"), // Ensures it's a valid URL
    interoperability: Yup.boolean(),
    type: Yup.string(),
    logoId: Yup.object().nullable(),
    faviconImageId: Yup.object().nullable(),
    theme: Yup.object().shape({
      primaryColor: Yup.string().required("Theme is required"),
      secondaryColor: Yup.string().required("Theme is required"),
      name: Yup.string().required("Theme is required"),
    }),
    loginImageId: Yup.object().nullable(),
    passwordImageId: Yup.object().nullable(),
  });

  // Form submittion button
  const handleSubmit = async (values: any) => {
    try {
      const formValues = {
        ...values,
        theme: values.theme
          ? {
            name: values.theme?.name,
            colorCode: values.theme?.primaryColor,
            secondaryColor: values.theme?.secondaryColor,
          }
          : undefined,
        faviconImageId: values.faviconImageId?.id || null,
        loginImageId: values.loginImageId?.fileId || values.loginImageId?.id || null,
        logoId: values.logoId?.id || null,
        organizationId: values.organizationId?.value || null,
        passwordImageId: values.passwordImageId?.fileId || values.passwordImageId?.id || null,
      };

      if (brandId) {
        const { data, message } = await apiLibrary.Brands.updateBrand(Number(brandId), formValues);
        if (data?.id) {
          Toasts.success(message || "Brand updated successfully");
        }
      } else {
        const { data, message } = await apiLibrary.Brands.addBrand(formValues);
        if (data?.id) {
          Toasts.success(message || "Brand created successfully");
        }
      }

      navigate("/brands/list", { replace: true }); // Ensure no navigation loop
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const onEditBrand = (state: boolean) => {
    setEditMode(state)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={brandFormValidationSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        isValid,
        submitForm, 
        resetForm
      }) => {
        return (
          <Form className="w-full">
            <Header
              brandName={values.name}
              isSubmitting={isSubmitting}
              isValid={isValid}
              handleSubmit={submitForm}
              handleEditBrand={onEditBrand}
              editMode={editMode}
              values={values}
              resetForm={resetForm}
            />
            {/* Details Section */}
            <div className="py-4">
              <Details
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                values={values}
                viewMode={viewMode}
                editMode={editMode}
              />
            </div>
            {/* Settings Section */}
            <div className="py-4">
              <Settings
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
                viewMode={viewMode}
              />
            </div>
            {/* Logos */}
            <div className="py-4">
              <Logos
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                editMode={editMode}
              />
            </div>
            {/* Styling */}
            <div className="py-4">
              <Styling
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                editMode={editMode}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
