/***** Import the React library and useState hooks *****/
import React, { useEffect, useState } from "react";
/***** Components *****/
import AuthLayout from "../Components/AuthLayout";
import FormInput from "../Components/Inputs/formInput";
import Button from "view/components/Button";
import FormHeader from "../Components/FormHeader";
import { Toasts } from "view/components/Toasts";
import {
  Apple,
  FacebookLoginButton,
  GoogleLoginButton,
  Office365Login,
} from "../Components/SocialLogins";
/***** API services *****/
import apiLibrary from "services/api";
/***** Store utils *****/
import { loginAction } from "store/auth";
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from "utils/caseConvertor";
import { useDispatch, useSelector } from "react-redux";
/***** External tools *****/
import { Form, Formik } from "formik";
import Cookies from "universal-cookie";
import { useNavigate, useLocation, Link } from "react-router-dom";
/***** Assets *****/
import SignInImg from "assets/images/Auth/SignInImg.png";
// Defining the validation schema for form fields
import { loginValidationSchema } from "utils/validationSchemas";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { RoutesConstants } from "constants/RoutesConstants";
import Head from "view/components/Head";
import useRoles from "hooks/roles";
import { RootState } from "store";
import { observerAppUrl } from "config";
import { getCurrentBranding } from "utils/customBranding";
import useCustomBranding from "hooks/useCustomBranding";
import backgroundPlaceholderImage from "assets/images/Placeholders/background-placeholder-image.jpg";

// Defining the interface for the form values
interface FormValues {
  phoneOrEmail: string;
  password: string;
}

interface LoginPageImage {
  value: null | string; // Assuming the value can be a string or null
  description: string;
  key: string;
  fileId: number;
  filePath: string;
}

export const SignIn = () => {
  /***** Initializing Redux-related hooks *****/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { IsUserLoggedIn, isExternalDataEndReviewer } = useRoles();
  const auth = useSelector((state: RootState) => state.auth);
  /***** Initializing cookies and rememberMe state *****/
  const cookies = new Cookies();
  const [rememberMe, setRememberMe] = useState(false);
  const [loginFormImage, setLoginFormImage] = useState<LoginPageImage>({
    value: null,
    description: "",
    key: "",
    fileId: 0,
    filePath: "",
  });
  const currentBrand = getCurrentBranding();
  const { theme } = useCustomBranding();
  const [isLoading, setLoading] = useState(false);
  const [shouldRenderForm, setShouldRenderForm] = useState(false);
  // Initializing form initial values
  const initialValues: FormValues = {
    phoneOrEmail: "",
    password: "",
  };
  // Handlers

  /**
   * Handles form submission for logging in.
   *
   * @param {FormValues} values - The form values containing user input.
   * @param {Object} options - Options object containing setSubmitting function.
   * @param {Function} options.setSubmitting - A function to set the submitting state.
   */

  const handleSubmit = async (
    values: FormValues, // Form values object passed to the function
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void } // Destructuring `setSubmitting` function from an object
  ) => {
    try {

      const newValues = {
        ...values,
        appName: theme?.apps?.admin
      }
      dispatch(setLoadingProgress(getRandomProgress()));
      // Call the API to log in and convert keys to snake case
      const res = await apiLibrary.Auth.login(convertKeysToSnakeCase(newValues));
      const data = convertKeysToCamelCase(res.data);

      // Calculate token and refresh token expiry
      const accessTokenExpiry = moment().add(rememberMe ? 100000 : 6, "hours");
      const refreshTokenExpiry = moment().add(365, "days");
      // Save token and refresh token to cookies with expiry
      cookies.set("token", res.data.accessToken, {
        path: "/",
      });
      cookies.set("refreshToken", res.data.refreshToken, {
        path: "/",
        expires: refreshTokenExpiry.toDate(), // Convert moment object to Date
      });

      // Dispatch login action and show success toast
      dispatch(loginAction(data));
      Toasts.success(res.message);
      // Navigate to create password page if required
      if (data.autoGeneratePass) {
        setTimeout(() => {
          navigate("/auth/createPassword");
        }, 500);
      } else {
        // navigate(`/profile/${data.person.personId}/person`);
        // Navigate to profile or reload the page
        if (data.roles.includes("external_reviewer")) {
          navigate(`/quality-assurance/list`);
        } else {
          navigate(RoutesConstants.default);
        }
        // window.location.reload();
      }
    } catch (error: any) {
      console.log("Error while handling form submission:", error);
      // Show error toast if API call fails
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const getLoginFormImage = async () => {
    setLoading(true);
    try {
      const { data } =
        await apiLibrary.stystemSettings.getUniversalImages("login_page_image", currentBrand);

      setLoginFormImage(data.loginPageImage);
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLoginFormImage();
  }, []);
  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  return (
    <>
      <div className="cursor-default ">
        <AuthLayout backgroundImage={theme?.brandCode === "isn" ? loginFormImage?.filePath : theme?.loginImage?.file?.original || backgroundPlaceholderImage}>
          <Head title="Sign In" />
          <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }: any) => (
              <Form className="h-full">
                <div className=" w-[400px] xl:w-[509px] px-6 xl:px-10">
                  <FormHeader />
                  {/* Container for the sign-in form */}
                  <div className="flex flex-col gap-4">
                    <p className="text-xl font-semibold text-secondaryMid dark:text-textWhite">
                      Sign In
                    </p>
                    {/* Input for phone number or email */}
                    <FormInput
                      disabled={isSubmitting}
                      label="Phone Number Or Email"
                      type="text"
                      name="phoneOrEmail"
                      id="phoneOrEmail"
                      placeholder="Phone Number Or Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phoneOrEmail}
                      error={errors.phoneOrEmail}
                      touched={touched.phoneOrEmail}
                    />
                    {/* Input for password */}
                    <FormInput
                      disabled={isSubmitting}
                      label="Password"
                      type="password"
                      placeholder="Password"
                      name="password"
                      id="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password}
                      touched={touched.password}
                    />
                    {/* Remember me checkbox and forgot password link */}
                    <div className="flex items-center justify-between">
                      <RememberMe
                        handleRememberMeChange={handleRememberMeChange}
                        rememberMe={rememberMe}
                      />
                      <Link
                        to="/auth/forgetPassword"
                        className="text-sm font-semibold text-textLink hover:text-primaryMid dark:text-primaryMidLight dark:hover:text-primaryMid"
                      >
                        Forgot Password
                      </Link>
                    </div>
                    {/* Sign-in button */}
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      text="Sign In"
                      filledColor="primary"
                      textColor="textWhite"
                      className="p-2 px-5 hover:bg-primaryMid"
                      width="35"
                      height="10"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                    {/* Divider line */}
                    <Divider />
                    {/* Social login buttons */}
                    <div className="flex items-center justify-start gap-2">
                      <GoogleLoginButton />
                      <Apple />
                      {/* <Office365Login /> */}
                      <FacebookLoginButton />
                    </div>
                    {observerAppUrl && observerAppUrl !== "" && (
                      <div className="flex items-center justify-center w-full gap-2 mt-4 ">
                        <a
                          href={observerAppUrl}
                          target="_blank"
                          className="text-base  font-semibold text-center text-primary hover:underline"
                        >
                          If you are an Observer, please click here to log in.
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </AuthLayout>
      </div>
    </>
  );
};
// Component for the Remember Me checkbox
const RememberMe = ({ handleRememberMeChange, rememberMe }: any) => {
  return (
    <label
      className="flex items-center cursor-pointer"
      style={{
        display: "flex",
        alignItems: "center",
        flex: "none",
        flexGrow: 0,
        zIndex: 1,
      }}
    >
      <input
        type="checkbox"
        id="rememberMeCheckbox"
        onChange={handleRememberMeChange}
        checked={rememberMe}
        className="mr-2"
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "100px",
          flex: "none",
          order: 0,
          flexGrow: 0,
        }}
      />
      <span className="text-secondaryMid dark:text-textMain hover:text-primary dark:hover:text-primaryMid">
        Remember Me
      </span>
    </label>
  );
};
// Component for the divider line
const Divider = () => {
  return (
    <div className="flex gap-2">
      <div className="flex-grow h-0.5 mt-2 bg-lineMid dark:bg-secondaryLight"></div>
      <p className="text-xs text-secondaryMidLight dark:text-caption ">
        or log in with
      </p>
      <div className="flex-grow h-0.5 mt-2 bg-lineMid dark:bg-secondaryLight"></div>
    </div>
  );
};
