import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeUserAlreadyExistsModalAction } from "store/modals/reducer.actions";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { convertKeysToCamelCase } from "utils/caseConvertor";
import Button from "view/components/Button";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchUsersAction } from "store/users";
import { appName } from "hooks/useCustomBranding/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  border: "none",
};

export const UserAlreadyExists: React.FC = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { viewUserAlreadyExistModal } = useSelector(
    (state: RootState) => state.modals
  );
  const handleSubmit = async () => {
    try {
      const data = {
        appName: appName(),
        email: viewUserAlreadyExistModal.data.email,
        phoneNumber: viewUserAlreadyExistModal.data.phoneNumber,
      };
      const res = await apiLibrary.userProfile.inviteUser(data);
      Toasts.success(res?.message);

      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  const handleClose = () => {
    dispatch(closeUserAlreadyExistsModalAction());
  };

  return (
    <>
      <Modal
        open={viewUserAlreadyExistModal.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="bg-bgWhite w-[480px] h-[280px] dark:bg-secondaryLight "
        >
          <div>
            <div className="px-6 relative">
              <button onClick={handleClose} className="right-2 top-1 absolute">
                <XCloseIcon width={24} height={24} viewBox="0 0 24 24" />
              </button>
              <p className="font-semibold text-xl text-textMid dark:text-textMain py-4">
                User Already Exists
              </p>
              <p className="text-sm text-textMid dark:text-textMain py-2">
                A user with this email address or phone number already exists in
                other brands of the system. Would you like to invite this user
                to your brand?
              </p>
              <div className="w-full flex py-2">
                <div className="h-[55px] w-[60px]">
                  <img
                    src={viewUserAlreadyExistModal.data?.personImage}
                    alt=""
                    className="rounded-full h-[48px] w-[48px]"
                  />
                </div>
                <div>
                  <p className="font-semibold text-[15px] text-textMid dark:text-textMain">
                    {viewUserAlreadyExistModal.data?.name}
                  </p>
                  <p className="text-xs text-textLight">
                    {viewUserAlreadyExistModal.data?.email}
                  </p>
                  <p className="text-xs text-textLight">
                    {viewUserAlreadyExistModal.data?.phoneNumber}
                  </p>
                </div>
              </div>
              <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                <Button
                  type="button"
                  text="Cancel"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="outlined"
                  onClick={handleClose}
                />

                <Button
                  // disabled={isLoading}
                  type="button"
                  text="Invite"
                  filledColor="primary"
                  outlinedColor="primary"
                  textColor="textWhite"
                  className="px-5 py-2"
                  width="35"
                  height="13"
                  fontStyle="font-semibold"
                  variant="filled"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
