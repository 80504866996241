import React from "react";
import Box from "@mui/material/Box";
import { AnyAction } from "redux";
import { RootState } from "store";
import { ThunkDispatch } from "redux-thunk";
import { dashboardActions } from "store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { IDashboardWidgets } from "store/dashboard/initialState";
import { ObservationDuration } from "store/dashboard/observations/types";
import DashboardCardHeading from "../DashboardCardHeading";
import { filtersInitialState } from "store/filters/initialState";
import ReactApexChart from "react-apexcharts";
import { IObservations } from "store/dashboard/observations/initialState";
import { ObservationDurationConstants } from "constants/observationDurationConstants";
import MostObservedNoData from "../imgs/MostObservedNoData";
import { DarkMode } from "store/darkMode/initialState";
import { TailSpin } from "react-loader-spinner";
import useCustomBranding from "hooks/useCustomBranding";

export default function DashboardObservations() {
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  const {
    dashboard: { observations },
    Filters: { dashboardFilter },
  } = useSelector<
    RootState,
    { dashboard: IDashboardWidgets; Filters: filtersInitialState }
  >((state) => ({
    dashboard: state.dashboard,
    Filters: state.Filters,
  }));

  const [duration, setDuration] = React.useState<ObservationDuration>(
    ObservationDurationConstants.PER_YEAR.VALUE
  );

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { primaryColor } = useCustomBranding();

  const toggleDuration = (newDuration: ObservationDuration) =>
    setDuration(newDuration);

  React.useEffect(() => {
    dispatch(dashboardActions.fetchObservations(duration));
  }, [dashboardFilter, duration]);

  return (
    <Box className="dark:bg-secondaryLight" width={"100%"} height={"100%"}>
      <DashboardCardHeading
        text="Observations"
        widgetId="observations"
        showChip={observations.stateIs == "Idle" && !!observations.totalCount}
        chipLabel={observations.totalCount}
        durationFilter={true}
        toggleDuration={toggleDuration}
      />

      {observations.stateIs == "Idle" && observations.data?.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <MostObservedNoData />
        </div>
      ) : observations.stateIs === "Pending" ? (
        <div className="flex items-center justify-center w-full h-full loader">
          <TailSpin
            height="50"
            width="50"
            color={primaryColor}
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <ReactApexChart
          height={300}
          series={getSeries(observations.data)}
          type="bar"
          options={{
            yaxis: {
              opposite: true,
              labels: {
                style: {
                  fontFamily: "overpass",
                  colors: isDarkMode ? ["#F2F2F2"] : ["black"],
                },
              },
            },
            xaxis: {
              categories: observations.data.map(
                (item) => item?.day || item?.week || item?.month || item?.year
              ),
              labels: {
                rotate: -45, // Set a fixed rotation
                rotateAlways: true, // Always rotate labels
                style: {
                  fontFamily: "overpass",
                  colors: observations.data
                    .map(
                      (item) =>
                        item?.day || item?.week || item?.month || item?.year
                    )
                    .map(() => {
                      return isDarkMode ? "#F2F2F2" : "black";
                    }),
                },
              },
            },
            chart: {
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 8,
                columnWidth: 30,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#009F88"],
            legend: {
              show: false,
            },
            tooltip: {
              style: {
                fontFamily: "overpass",
              },
            },
          }}
        />
      )}

    </Box>
  );
}

const getSeries = (seriesData: IObservations[]) => {
  return [
    {
      name: "Count",
      data: seriesData.map((item) => item?.count || 0),
      // data: [40, 50, 55, 70, 65],
    },
  ];
};
