import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import { GoToNextBtnProps } from "../../types";
import useCustomBranding from "hooks/useCustomBranding";

export const GoToNextBtn = ({ page, totalPages, handleClickOnBtn }: GoToNextBtnProps) => {
  const { primaryColor } = useCustomBranding();
  return (
    <button
      onClick={handleClickOnBtn}
      title="Next"
      className={`hover:bg-primaryLight dark:bg-bgtetriary group dark:text-inputText dark:border-lineLight dark:hover:text-primary dark:hover:border-primary relative flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed flex-grow-0 flex-shrink-0 w-8 gap-1 px-3 py-[2px] border-2 rounded-lg  border-primary`}
      disabled={page === totalPages}
    >
      <CheveronRightIcon fill={primaryColor} />
    </button>
  );
};
