import React from "react";
// Components
import { CustomizedSwitches } from "view/components/CustomizedSwitches";

interface IProps {
  handleChange: any;
  setFieldValue: any;
  values: any;
  editMode?: boolean;
}

export const Interoperability: React.FC<IProps> = ({
  handleChange,
  setFieldValue,
  values,
  editMode,
}) => {
  return (
    <div>
      <p className="text-lg font-semibold font-Overpass text-secondaryMid py-2">
        ISN Interoperability
      </p>
      <p className="text-sm text-secondaryMid font-Overpass py-1">
        Enable or disable interoperability. By enabling interoperability you are
        allowing the user to access multiple programs across ISN and the branded
        programs, reducing the need to move between apps. By disabling
        interoperability observers will only see programs of this specific brand
        in their branded app.
      </p>
      <div className="flex justify-start ml-[-33px] items-center">
        <CustomizedSwitches
          checked={values.interoperability}
          onChange={(e) => {
            const { checked } = e.target;
            setFieldValue("interoperability", checked);
          }}
          value="Interoperability"
          label=""
          className="disabled:cursor-not-allowed font-Overpass"
          disabled={!editMode}
        />
        <p className="text-sm text-secondaryMid font-Overpass ml-3">
          Interoperability
        </p>
      </div>
    </div>
  );
};
