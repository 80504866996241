import { useState, useEffect, useCallback } from "react";
import apiLibrary from "services/api";
import {
  brandFavicons,
  brandLogos,
  brandPlaceholders,
  staticBrands,
  themes,
} from "./constants";
import { calculateColorRange, hexToRgba } from "./colorRange";
import CustomBrandLogo from "assets/logo/CustomBrandLogo";

const getCurrentBranding = (): string => {
  const currentBranding = process.env.REACT_APP_BRAND_NAME ?? "default";
  return currentBranding.toLocaleLowerCase();
};

const applyTheme = (theme: Record<string, string>) => {
  if (theme) {
    const root = document.documentElement;
    Object.keys(theme).forEach((key) => {
      const cssVariableName = `--${key}`;
      root.style.setProperty(cssVariableName, theme[key]);
    });
  }
};

const useCustomBranding = () => {
  const [theme, setTheme] = useState<any>({});
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);

  const currentBrand = getCurrentBranding();
  const isStaticBrand = staticBrands.includes(currentBrand);

  // if Brand is ISN
  const isBrandISN = theme?.brandCode === "isn";
  const DefaultLogo = isBrandISN ? brandLogos["isn_admin"] : CustomBrandLogo;
  const brandFavicon = isBrandISN
    ? brandFavicons["isn_admin"]
    : theme?.favicon?.file?.thumb || brandFavicons["custom"];
  const primaryColor = isBrandISN
    ? themes["isn_admin"]?.primary
    : theme?.colors?.primary || themes[currentBrand]?.primary;
  const fetchAndApplyTheme = useCallback(async () => {
    try {
      // Check for cached theme in local storage
      let brands = JSON.parse(localStorage.getItem("brands-themes") || "[]");

      const domain = window.location.origin;

      // Check if domain exists in local storageZ
      const existingBrand = brands.find(
        (brand: any) => brand.domain === domain
      );
      if (existingBrand) {
        setTheme(existingBrand.data);
        applyTheme(existingBrand.data.colors);
        setIsThemeLoaded(true);
        return;
      }

      // Fetch the theme from the API if not cached
      const encodedUrl = encodeURIComponent(domain);
      const { data } = await apiLibrary.Brands.getBrandDetailByUrl(encodedUrl);

      const isBrandISN = data?.brandCode === "isn";

      let brandData;

      if (isBrandISN) {
        // Use predefined ISN theme
        brandData = {
          ...data,
          colors: themes["isn_admin"],
          brandName: "Indigenous Sentinels Network",
          brandCode: "isn",
          domain,
        };
      } else {
        // Calculate and apply custom theme for non-ISN brands
        const updatedTheme = calculateColorRange(
          data?.theme?.colorCode,
          themes.isn_admin
        );
        brandData = {
          ...data,
          colors: {
            ...updatedTheme,
            primary: data?.theme?.colorCode,
            primaryExtraLight: data?.theme?.secondaryColor,
            bgHighMidlight: hexToRgba(data?.theme?.secondaryColor, 0.2), // Hovers
            primary_20: hexToRgba(data?.theme?.colorCode, 0.2),
            primary_40: hexToRgba(data?.theme?.colorCode, 0.4),
            textLink: data?.theme?.colorCode,
          },
          brandName: data?.brandName,
          themeName: data?.theme?.name,
          domain,
        };
      }

      // Add or update brand data in localStorage
      brands = brands.filter((brand: any) => brand.domain !== domain); // Remove old entry if exists
      brands.push({ domain, data: brandData }); // Add new brand

      localStorage.setItem("brands-themes", JSON.stringify(brands));

      // Apply the theme
      setTheme(brandData);
      applyTheme(brandData.colors);
    } catch (error) {
      console.error("Failed to fetch theme", error);

      // Apply a fallback theme in case of errors
      const fallbackTheme = themes[getCurrentBranding()] || themes.default;
      setTheme(fallbackTheme);
      applyTheme(fallbackTheme);
    } finally {
      setIsThemeLoaded(true);
    }
  }, []);

  const getBrandPlaceholder = useCallback(
    (type: any) => {
      if (theme?.brandCode === "isn") {
        return brandPlaceholders[type]?.isn_admin;
      }
      return brandPlaceholders[type]?.default;
    },
    [theme]
  );

  useEffect(() => {
    fetchAndApplyTheme();
  }, [fetchAndApplyTheme]);

  return {
    theme,
    isThemeLoaded,
    getBrandPlaceholder,
    isStaticBrand,
    DefaultLogo,
    brandFavicon,
    primaryColor,
  };
};

export default useCustomBranding;
