import {
  ERROR_LOADING_BRANDS,
  GET_ALL_BRANDS,
  RESET_DATA,
  START_LOADING_BRANDS,
  STOP_LOADING_BRANDS,
} from "./action-types";
import initialState from "./initialState";

export const brandsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case START_LOADING_BRANDS:
      return {
        ...state,
        stateIs: "Pending",
      };

    case ERROR_LOADING_BRANDS:
      return {
        ...state,
        stateIs: "Error",
      };

    case STOP_LOADING_BRANDS:
      return {
        ...state,
        stateIs: "Idle",
      };
    case GET_ALL_BRANDS:
      const brands = action.payload.brands.map((item: any) => {
        const {
          id,
          name,
          status,
          type,
          dateCreated,
          organizationName,
          dateArchived,
          domainPropagation,
          brandImage,
        } = item;
        const data = {
          id: id,
          brand: {
            name: name,
            image: brandImage,
            id: id,
          },
          status,
          type,
          dateCreated,
          organizationName,
          dateArchived,
          domainPropagation,
        };

        return data;
      });

      return {
        ...state,
        data: {
          brands: brands,
          totalItems: action.payload.totalItems,
          totalPages: action.payload.totalPages,
          currentPage: action.payload.currentPage,
        },
        message: "",
        stateIs: "Idle",
      };
    case RESET_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};
