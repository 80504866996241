import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProgram } from "store/programProfile/initialState";
import { Link } from "react-router-dom";
import moment from "moment";
import useCustomBranding from "hooks/useCustomBranding";

export const OrganizationsListingCard = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const { getBrandPlaceholder } = useCustomBranding();
  return (
    <div className="flex flex-col w-full rounded-lg shadow-md lg:p-4 xl:p-6 bg-bgWhite dark:bg-secondaryLight dark:border dark:border-lineLight hover:shadow-xl">
      <div className="flex justify-between w-full mb-1">
        <p className="text-base font-semibold dark:text-textMain">{`${programProfile?.organizationsCount} Organizations`}</p>
        {programProfile.canEditProgramDetail && (
          <Link
            to={`/programs/${programProfile?.id}/organizations`}
            className="flex-grow-0 flex-shrink-0 "
          >
            <p className="text-sm font-semibold text-primary dark:text-primaryMidLight hover:text-primaryMid ">
              Manage All
            </p>
          </Link>
        )}
      </div>

      {programProfile?.organizations?.map(
        (organizations: any, index: number) => {
          return (
            <div key={index} className="flex mt-4">
              <div className="w-8">
                <div className="w-8 h-8 mt-2 rounded-full">
                  <img
                    src={
                      organizations?.image?.thumb
                        ? organizations?.image?.thumb
                        : getBrandPlaceholder("listingProfile")
                    }
                    alt={organizations?.name}
                    className="w-full rounded-full"
                  />
                </div>
              </div>
              <div className="ml-4">
                <p className="text-[15px] break-words break-all dark:text-textMain ">
                  {organizations?.name}
                </p>
                <div>
                  <span className="text-xs dark:text-caption ">
                    Joined {moment(organizations?.date).format("DD MMM YYYY")}
                  </span>
                  {organizations?.isLeading ? (
                    <span className="text-xs text-tertiary ml-2 bg-tertiaryLight px-1 py-[2px] rounded dark:bg-primary_50 dark:text-textMain font-Overpass font-normal leading-4">
                      Leading
                    </span>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};
