import { snakeCase } from "change-case";
import {
  convertJsonOfDrawPolygon,
  convertJsonOfDropPin,
  convertJsonOfGetGpsData,
  convertJsonOfPointPicker,
  convertJsonOfShapePicker,
} from "../dataConverters";
import questionTypes from "../questionTypes";
import { v4 as uuidv4 } from "uuid";
import generateUniqueId from "generate-unique-id";
import { uniqueIdGenertorInNumber } from "./structureFormJSON";

function convertQuestionObj(question: any, globalStoreData: any) {
  switch (question.type) {
    case questionTypes.radio:
      return convertTheChoicesOfQuestions({
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        isRequired: question.isRequired ?? false,
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),
      });

    case questionTypes.checkbox:
      return convertTheChoicesOfQuestions({
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
      });

    case questionTypes.dropdown:
      return convertTheChoicesOfQuestions({
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
        placeholder: question.placeholder ?? "Select...",
      });

    case questionTypes.tagbox:
      return convertTheChoicesOfQuestions({
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
        placeholder: question.placeholder ?? "Select...",
      });

    case questionTypes.boolean:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
        labelTrue: question.labelTrue ?? "Yes",
        labelFalse: question.labelFalse ?? "No",
      };

    case questionTypes.file:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
      };

    case questionTypes.imagepicker:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
        showLabel: question.showLabel ?? false,
        multiSelect: question.multiSelect ?? false,
      };

    case questionTypes.text:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
        inputType: question.inputType ?? "text",
        placeholder: question.placeholder ?? "",
      };

    case questionTypes.comment:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
        placeholder: question.placeholder ?? "",
      };

    // case questionTypes.panel:
    //   return {
    //     ...question,
    //     title: question.title ?? "",
    //     description: question.description ?? "",

    //     isRequired: question.isRequired ?? false,
    //   };

    case questionTypes.paneldynamic:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),

        isRequired: question.isRequired ?? false,
      };

    case questionTypes.audio:
      return {
        ...question,
        title: question.title ?? "",
        description: question.description ?? "",
        elementId: uuidv4(),
        questionId: uniqueIdGenertorInNumber(),
        isRequired: question.isRequired ?? false,
      };

    case questionTypes.shapePicker:
      const shapePickerStoredData = globalStoreData.find(
        (d: any) => d?.id === question?._id
      );

      if (shapePickerStoredData) {
        shapePickerStoredData.isRequired = question.isRequired;
        return convertJsonOfShapePicker(question, shapePickerStoredData);
      }

      return question;

    case questionTypes.pointPicker:
      const pointPickerStoredData = globalStoreData.find(
        (d: any) => d?.id === question?._id
      );

      if (pointPickerStoredData) {
        pointPickerStoredData.isRequired = question.isRequired;
        return convertJsonOfPointPicker(question, pointPickerStoredData);
      }

      return question;

    case questionTypes.drawPolygon:
      const drawPolygonStoredData = globalStoreData.find(
        (d: any) => d?.id === question?._id
      );

      if (drawPolygonStoredData) {
        drawPolygonStoredData.isRequired = question.isRequired;
        return convertJsonOfDrawPolygon(question, drawPolygonStoredData);
      }

      return question;

    case questionTypes.dropPin:
      const dropPinStoredData = globalStoreData.find(
        (d: any) => d?.id === question?._id
      );

      if (dropPinStoredData) {
        dropPinStoredData.isRequired = question.isRequired;
        return convertJsonOfDropPin(question, dropPinStoredData);
      }

      return question;

    case questionTypes.getGpsData:
      const getGpsDataStoredData = globalStoreData.find(
        (d: any) => d?.id === question?._id
      );

      if (getGpsDataStoredData) {
        getGpsDataStoredData.isRequired = question.isRequired;
        return convertJsonOfGetGpsData(question, getGpsDataStoredData);
      }

      return question;
    default:
      return question;
  }
}

export default convertQuestionObj;

export function convertTheChoicesOfQuestions(question: any) {
  const updatedQuestion = { ...question };
  if (
    ![
      questionTypes.radio,
      questionTypes.checkbox,
      questionTypes.dropdown,
      questionTypes.tagbox,
    ].includes(updatedQuestion.type)
  ) {
    return updatedQuestion;
  }
  if (Array.isArray(updatedQuestion.choices)) {
    updatedQuestion.choices = updatedQuestion.choices.map((choice: any) =>
      typeof choice === "string" ? { text: choice, value: choice } : choice
    );
  } else {
    updatedQuestion.choices = [];
  }
  if (question.defaultValue && Array.isArray(updatedQuestion.choices)) {
    const defaultChoice = getDefaultValueChoiceFromTheChoices(
      updatedQuestion.defaultValue,
      updatedQuestion.choices
    );
    if (defaultChoice) {
      updatedQuestion.updatedDefaultValue = defaultChoice;
    } else {
      throw new Error(
        `Default value for the Question: ${updatedQuestion.name} is not available in the Choices`
      );
      updatedQuestion.updatedDefaultValue = null;
      delete updatedQuestion.defaultValue;
    }
  }

  return updatedQuestion;
}

function getDefaultValueChoiceFromTheChoices(
  defaultValue: any,
  choices: any[]
) {
  if (Array.isArray(defaultValue)) {
    const updatedChoices = choices.filter((choice: any) =>
      defaultValue.includes(choice.value)
    );
    return updatedChoices.length > 0 ? updatedChoices : null;
  } else {
    const defaultChoice = choices.find((choice: any) =>
      typeof choice === "object"
        ? choice.value === defaultValue
        : choice === defaultValue
    );
    return defaultChoice ?? null;
  }
}

function addUniqueIdInEachQuestion() {
  return uuidv4();
}
