import useCustomBranding from 'hooks/useCustomBranding';
import React from 'react';
import { Helmet } from 'react-helmet';

type HeadProps = {
  title?: string;
  description?: string;
};

const Head: React.FC<HeadProps> = ({ title }) => {
  const { theme, brandFavicon } = useCustomBranding();

  return (
    <Helmet>
      <link rel='icon' href={brandFavicon} type='image/x-icon' />
      <link
        rel='apple-touch-icon'
        href={brandFavicon}
        type='image/x-icon'
      />
      <title>{`${title ? `${title} - ` : ''}${theme?.brandName}`}</title>
    </Helmet>
  );
};

export default Head;
