import { apiClient } from "../config";

interface ExifData {
  [key: string]: any;
}

const postExifData = async (id: number, exifData: ExifData) => {
  try {
    const { data } = await apiClient.post(`/admin/file/${id}/exif`, {
      fileExif: exifData,
    });
    return data;
  } catch (error) {
    console.error("Error posting EXIF data:", error);
    throw error;
  }
};

export default postExifData;
