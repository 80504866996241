import { BrandForm } from "Components/BrandDetails";
import useCustomBranding from "hooks/useCustomBranding";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchBrandDetailsAction } from "store/brandAdminBrandDetails";
import { resetBrandDetails } from "store/brandAdminBrandDetails/reducer.actions";

export const BrandDetails = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { theme } = useCustomBranding();

  useEffect(() => {
    if (theme?.brandCode) {
      dispatch(fetchBrandDetailsAction(theme.brandCode))
    }

    return () => {
      dispatch(resetBrandDetails())
    }
  }, [theme])

  return <BrandForm />;
};
