interface ISNLogoProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const ISNLogo = ({
  width = 20,
  height = 20,
  fill = '#005C89',
  ...restProps
}: ISNLogoProps) => (

  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}
    viewBox="0 0 28 28"
    fill={fill}
    className='flex-shrink-0'
    {...restProps}
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L10 13H0V15H10L0 28H28L18 15H28V13H18L28 0H0ZM18 13V15H10V13H18ZM4.06173 2L10.9848 11H17.0152L23.9383 2H4.06173ZM10.9848 17L4.06173 26H23.9383L17.0152 17H10.9848Z"
      fill={fill}
      width={width}
      height={height}
    />
  </svg>
);
export default ISNLogo;
