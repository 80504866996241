import React from "react";
import { DropZone } from "../DropZone";

interface IProps {
  setFieldValue: any;
  values: any;
  errors: any;
}

export const UploadFavicon: React.FC<IProps> = ({
  setFieldValue,
  values,
  errors,
}) => {
  // Which types of files are accepted
  const acceptedFileTypes = {
    "image/jpeg": [".jpg", ".jpeg", ".png", ".ico"],
  };

  return (
    <>
      <p className="text-sm font-medium text-secondaryMid py-2">
        Upload Favicon
      </p>
      <div className="w-full">
        {/* DropZone to select the images */}
        <DropZone
          setFieldValue={setFieldValue}
          values={values.faviconImageId}
          errors={errors.faviconImageId}
          acceptedFileTypes={acceptedFileTypes}
          text={
            "Please upload a favicon with .ico extension. The maximum file size is 25MB. To generate a favicon from an image, visit "
          }
          fieldValue={"faviconImageId"}
        />
      </div>
    </>
  );
};
