import useDateFormatter from "hooks/formatDateWithPattern";
import { RenderCellContentProps } from "../../types";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import CheckIcon from "assets/icons/HeroIcons/CheckIcon";
import InputMark from "assets/icons/HeroIcons/InputMark";
import useCustomBranding from "hooks/useCustomBranding";

export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  const { getBrandPlaceholder } = useCustomBranding();
  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "recipientName"
  ) {
    return (
      <>
        <div className="relative flex items-center self-stretch justify-start flex-grow gap-3 pr-2 py-3">
          <img
            src={
              cellValue?.personImage?.thumb
                ? cellValue?.personImage?.thumb
                : getBrandPlaceholder("listingProfile")
            }
            alt=""
            height="100"
            width="100"
            className="mx-2 Img_user_Data"
            style={{ flexShrink: 0 }}
          />
          <div className="flex flex-col justify-start items-start flex-grow relative gap-0.5">
            <p className="self-stretch flex-grow-0 flex-shrink-0 text-sm text-left text-textMid w-[180px] break-all">
              {cellValue?.firstName}{" "}{cellValue?.lastName}
            </p>
          </div>
        </div>
      </>
    );
  } else
    if (cellValue !== null &&
      columnDef.id === "notificationCenter" ||
      columnDef.id === "toast" ||
      columnDef.id === "push" ||
      columnDef.id === "email") {
      return (
        <>
          <div className="relative flex items-start flex-grow py-1 px-3">
            {cellValue === true ? (
              <CheckIcon fill="#009F88" />
            ) : cellValue === null ? (
              <InputMark fill="#AFB1B3"/>
            ) : (
              <XCloseIcon fill="#BF200B" />
            )}
          </div>
        </>
      )
    }
    else {
      return null;
    }
};
