import React, { useState } from "react";
// Components
import MultiSelect from "view/components/Multiselect";

export const ViewOrganization: React.FC<any> = ({ values }) => {
    return (
        <div className="grid py-4 gap-6">
            <div className="grid grid-cols-2 gap-4">
                {/* Admin URL */}
                <div className="space-y-1">
                    <p className="text-sm font-Overpass text-textMidLight">Admin URL</p>
                    <p className="text-sm font-Overpass text-textNormal break-words pr-5">{values?.adminUrl}</p>
                </div>

                {/* App URL */}
                <div className="space-y-1">
                    <p className="text-sm font-Overpass text-textMidLight">App URL</p>
                    <p className="text-sm font-Overpass text-textNormal break-words pr-5">{values?.appUrl}</p>
                </div>
            </div>

            {/* DNS Propagation Status */}
            {/* <div className="col-span-full space-y-1">
                <p className="text-sm font-Overpass text-textMidLight">DNS Propagation Status</p>
                <p className={`text-[12px] font-Overpass py-1 px-2 rounded-lg capitalize max-w-max ${getDnsStatusColor(values?.dnsPropagationStatus || "")}`}>
                    {values?.dnsPropagationStatus}</p>
            </div> */}
        </div>
    );
};

const getDnsStatusColor = (name: string): string => {
    switch (name) {
        case "archive":
            return "bg-primaryExtraLight text-primaryMid";
        case "public":
        case "active":
        case "completed":
            return "bg-tertiaryExtraLight text-tertiaryMid";
        case "pending":
            return "bg-accent_1Light text-accent_1Dark";
        default:
            return "white";
    }
};