import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import {
  Filters,
  Header,
  Tabs,
  Pagination,
  Table,
  GridCard,
} from "Components/Objects";
// Hooks
import { ObjectsTable } from "hooks/useTable";
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchObjectsAction } from "store/objects";
import { IfetchObjects } from "store/objects/initialState";
import { TableProps } from "Components/Objects/Table/types";
import { objectsResetFiltersAction, setObjectsFiltersAction } from "store/filters/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { resetObjectsAction } from "store/objects/reducer.actions";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import useCustomBranding from "hooks/useCustomBranding";

export const Objects = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { communityId } = useParams();

  useLayoutEffect(() => {
    dispatch(setObjectsFiltersAction());
  }, []);
  const { data } = useSelector<RootState, IfetchObjects>(
    (state) => state?.allObjects
  );
  const { objectsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = ObjectsTable(data?.objects);

  useEffect(() => {
    if (communityId) {
      dispatch(fetchObjectsAction(communityId));
    } else {
      dispatch(fetchObjectsAction());
    }

    saveFiltersDataToLocalStorage(objectsFilter);
  }, [objectsFilter]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        objectsFilter.searchString === ""
      ) {
        setShowInput(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [objectsFilter.searchString]);

  useEffect(() => {
    return () => {
      // dispatch(objectsResetFiltersAction());
      dispatch(resetObjectsAction());
    };
  }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>
        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default Objects;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { objectsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );
  const { primaryColor } = useCustomBranding();
  const { data, stateIs } = useSelector<RootState, IfetchObjects>(
    (state) => state?.allObjects
  );

  if (objectsFilter.viewType === "grid") {
    return (
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
        {stateIs == "Idle" && data?.objects?.length === 0 ? (
          <NoRecordFound />
        ) : stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color="#005C89"
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <GridCard />
        )}
        <div
          className={`w-full bg-bgWhite flex-grow-0 flex-shrink-0 ${
            stateIs === "Idle" && data?.objects?.length === 0 ? "" : "mt-2"
          }`}
        >
          <Pagination />
        </div>
      </div>
    );
  } else if (objectsFilter.viewType === "list") {
    return (
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
        {stateIs == "Idle" && data?.objects?.length === 0 ? (
          <NoRecordFound />
        ) : stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color={primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <Table
            flexRender={flexRender}
            table={table}
            columnOrder={columnOrder}
            columns={columns}
            columnVisibility={columnVisibility}
            setSorting={setSorting}
            sorting={sorting}
          />
        )}
        <Pagination />
      </div>
    );
  } else {
    return null;
  }
};
