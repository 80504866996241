import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import {
  Filters,
  Header,
  Tabs,
  Pagination,
  Table,
  GridCard,
} from "Components/Programs";
// Hooks
import { ProgramsTable } from "hooks/useTable";

// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { TableProps } from "Components/Programs/Table/types";
import { IfetchPrograms } from "store/programs/initialState";
import { fetchProgramsAction } from "store/programs/reducer.actions";
import { filtersInitialState } from "store/filters/initialState";
import { useParams } from "react-router-dom";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import { programsResetFiltersAction } from "store/filters";
import usePermissions from "hooks/usePermissions";
import { updateProgramsDirectoryColumnsVisibilityByStatus } from "store/directoriesOrders/reducer.actions";
import {
  getFiltersDataFromLocalStorage,
  saveFiltersDataToLocalStorage,
} from "utils/cacheOrRetriveFiltersDate";
import { setProgramFiltersAction } from "store/filters/reducer.actions";
import useCustomBranding from "hooks/useCustomBranding";

export const Programs = () => {
  const [showInput, setShowInput] = useState(false);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useLayoutEffect(() => {
    dispatch(setProgramFiltersAction());
  }, []);
  const inputRef = useRef<HTMLDivElement>(null);
  const { data } = useSelector<RootState, IfetchPrograms>(
    (state) => state?.allprograms
  );

  const { communityId, organizationId } = useParams<{
    communityId: string;
    organizationId: string;
  }>();
  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = ProgramsTable(data?.programs);

  useEffect(() => {
    dispatch(fetchProgramsAction(communityId, organizationId));
    dispatch(
      updateProgramsDirectoryColumnsVisibilityByStatus(programsFilter.status)
    );

    saveFiltersDataToLocalStorage(programsFilter);
  }, [programsFilter]);
  const { programs } = usePermissions();

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>

        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default Programs;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { primaryColor } = useCustomBranding();

  const { programsFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );
  const { data, stateIs } = useSelector<RootState, IfetchPrograms>(
    (state) => state?.allprograms
  );
  if (programsFilter.viewType === "grid") {
    return (
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full">
        {stateIs === "Idle" && data?.programs?.length === 0 ? (
          <NoRecordFound />
        ) : stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color={primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <GridCard />
        )}
        <div
          className={`bg-bgWhite dark:bg-secondaryLight w-full ${
            stateIs === "Idle" && data?.programs?.length === 0 ? "" : "mt-2"
          }`}
        >
          <Pagination className="dark:bg-secondaryLight " />
        </div>
      </div>
    );
  } else if (programsFilter.viewType === "list") {
    return (
      <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
        {stateIs == "Idle" && data?.programs?.length === 0 ? (
          <NoRecordFound />
        ) : stateIs === "Pending" ? (
          <div className="loader min-h-[45vh] justify-center items-center flex w-full">
            <TailSpin
              height="50"
              width="50"
              color={primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="tailspin-loader"
              visible={true}
            />
          </div>
        ) : (
          <Table
            flexRender={flexRender}
            table={table}
            columnOrder={columnOrder}
            columns={columns}
            columnVisibility={columnVisibility}
            setSorting={setSorting}
            sorting={sorting}
          />
        )}
        <Pagination className="px-6 dark:bg-bgtetriary" />
      </div>
    );
  } else {
    return null;
  }
};
