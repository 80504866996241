import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";
import CheveronRightIcon from "assets/icons/HeroIcons/CheveronRightIcon";
import ellipsize from "ellipsize";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

interface LinkItem {
  path: string;
  label: string;
}

interface BreadcrumbProps {
  links: LinkItem[];
  handleClick?: any;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  links,
  handleClick = null,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center">
      {links.length > 0 && ( // Check if there are any links before rendering the Home button
        <button
          onClick={() => {
            if (handleClick !== null) {
              handleClick();
            } else {
              navigate(-1);
            }
          }}
          type="button"
          className="flex items-center mr-2 space-x-2 text-textMid hover:underline"
        >
          <ArrowLeftIcon height={22} width={22} /> {/* Left icon */}
        </button>
      )}
      {links.map((link, index) => (
        <React.Fragment key={index}>
          {index > 0 && link.label && (
            <CheveronRightIcon height={22} width={22} />
          )}
          <Link
            to={link.path}
            className="flex items-center space-x-2 shrink-0 text-textMid hover:underline"
          >
            {/* <p className="text-sm">{sentenceCase(link.label)}</p> */}
            <p className="text-sm capitalize dark:text-textMain">
              {ellipsize(link.label, 100)}
            </p>
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};
