import React, { useEffect, useState } from "react";
import TextInputField from "view/components/InputField";
import SearchIcon from "assets/icons/HeroIcons/SearchIcon";
import PickContactPlaceholder from "assets/images/Placeholders/Pick-contact-placeholder.png";
import ArchivedMessagePlaceholder from "assets/images/Placeholders/Archived-message-placeholder.svg";
import NoMessagePlaceholder from "assets/images/Placeholders/No-message-placeholder.svg";
import NewChatPlaceholder from "assets/images/Placeholders/new-chat-placeholder.svg";
import MessageDetails from "./MessageDetails";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { IChatDetails } from "store/messagesDetail/initialState";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { filtersInitialState } from "store/filters/initialState";
import {
  fetchChatDetailAction,
  resetChatDetails,
} from "store/messagesDetail/reducer.actions";
import { MessageActions } from "Components/MessageDetails/MessageActions";
import { messagesSearchAction } from "store/filters";
import VolumeOffIconOutlined from "assets/icons/HeroIcons/VolumeOffIconOutlined";
import { IfetchRecipients } from "store/messages/initialState";
import RefreshIcon from "assets/icons/HeroIcons/RefreshIcon";
import { getBrandPlaceholder } from "utils/customBranding";

interface DisplayedMessagesProps {
  selectedContact: any | null;
}

const DisplayedMessages: React.FC<DisplayedMessagesProps> = ({
  selectedContact,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [refreshIcon, setRefreshIcon] = useState(false);

  const [messageSearchValue, setMessageSearchValue] = useState("");

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const { data } = useSelector<RootState, IChatDetails>(
    (state) => state?.chatDetails
  );

  const { isLoading } = useSelector<RootState, IChatDetails>(
    (state) => state.chatDetails
  );

  const auth = useSelector((state: RootState) => state.auth);
  const reciever =
    data.receiver?.userId === auth.id ? data.sender : data.receiver;

  const { messageDetailFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  // Different Filters to be applied
  const { messageStatusFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { messageNewChatFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  useEffect(() => {
    if (selectedContact?.id) {
      dispatch(fetchChatDetailAction(selectedContact?.id));
    }
  }, [messageDetailFilter.searchString, selectedContact?.id]);

  // Action creator for Searching messages in the chat.
  useEffect(() => {
    dispatch(resetChatDetails());
    dispatch(messagesSearchAction(messageSearchValue));
  }, [messageSearchValue]);

  // Handlers
  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stop event propagation here to avoid immediate outside click
    setShowInput((prevState: any) => !prevState);
  };

  useEffect(() => {
    if (showInput) {
      const searchInputElement = document.querySelector(
        ".searchInput"
      ) as HTMLInputElement | null;
      if (searchInputElement) {
        searchInputElement.focus();
      }
    }
  }, [showInput]);

  const renderPlaceholder = (type: string) => {
    switch (type) {
      case "Pick Contact":
        return (
          <div className="flex justify-center items-center">
            <img src={PickContactPlaceholder} alt="" />
          </div>
        );
        break;

      case "Pick Archived Contact":
        return (
          <div className="flex justify-center items-center">
            <img src={ArchivedMessagePlaceholder} alt="" />
          </div>
        );
        break;

      case "No Messages":
        return (
          <div className="flex justify-center items-center">
            <img src={NoMessagePlaceholder} alt="" />
          </div>
        );
        break;

      case "New Chat":
        return (
          <div className="flex justify-center items-center">
            <img src={NewChatPlaceholder} alt="new chat" />
          </div>
        );

      default:
        break;
    }
  };
  const recipients = useSelector<RootState, IfetchRecipients>(
    (state) => state?.allRecipients
  );
  const handleReload = () => {
    setRefreshIcon(true);
    dispatch(resetChatDetails());
    if (selectedContact?.id) {
      dispatch(fetchChatDetailAction(selectedContact?.id));
      setRefreshIcon(false);
    }
  };

  return (
    <div className="flex flex-col overflow-hidden h-full max-h-[95vh] min-h-[95vh]">
      {!selectedContact && messageNewChatFilter?.newChat ? (
        renderPlaceholder("New Chat")
      ) : !selectedContact ? (
        renderPlaceholder("Pick Contact")
      ) : !selectedContact && recipients?.data.recipients?.length > 0 ? (
        renderPlaceholder("Pick Contact")
      ) : !selectedContact?.id &&
        !messageStatusFilter.archived &&
        recipients?.data.recipients?.length > 0 ? (
        renderPlaceholder("Pick Contact")
      ) : !selectedContact &&
        messageStatusFilter.archived &&
        recipients?.data.recipients?.length > 0 ? (
        renderPlaceholder("Pick Archived Contact")
      ) : !selectedContact && recipients?.data.recipients?.length > 0 ? (
        renderPlaceholder("No Messages")
      ) : (
        <div className="flex justify-between gap-2 p-4 items-center bg-bgWhite dark:bg-bgtetriary">
          <div className="w-8 h-8 rounded-full">
            <img
              src={
                selectedContact?.personImage ??
                getBrandPlaceholder("userProfile")
              }
              alt="Img"
              className="w-full h-full rounded-full"
            />
          </div>

          {selectedContact?.id && selectedContact.muted ? (
            <div>
              <p className="rounded text-secondaryMidLight text-sm max-w-max font-Overpass flex items-start">
                <VolumeOffIconOutlined fill="#54595F" className="w-4 h-4" />
              </p>
            </div>
          ) : null}

          <div className="flex-1">
            <h2 className="ont-semibold text-secondary font-Overpass dark:text-textMain">
              {selectedContact
                ? ` ${selectedContact?.firstName} ${selectedContact?.lastName}`
                : ""}
            </h2>
          </div>
          <div className="flex gap-4 justify-center items-center">
            <div className="flex mt-2 border-none border-t-0 border-l-0 border-r-0 border-lineMid relative">
              <div
                className={`${
                  showInput ? "w-full mr-4" : "w-0"
                } ml-8 border-2 border-t-0 bg-none border-l-0 border-r-0 border-b-primaryMid`}
              >
                <TextInputField
                  value={messageSearchValue}
                  className="w-[250px] bg-[transparent] searchInput pb-1 dark:text-caption"
                  placeholder="Search Messages"
                  type="search"
                  fieldAs="searchData"
                  onChange={(e: any) => {
                    setMessageSearchValue(e.target.value);
                  }}
                  disabled={isLoading}
                />
              </div>
              <div onClick={toggleInputVisibility} className="cursor-pointer">
                <SearchIcon
                  className={` ${showInput} absolute mt-[-5px] ml-[-17px]`}
                />
              </div>
            </div>
            <div className="flex gap-1 justify-center items-center">
              <button disabled={isLoading}>
                <RefreshIcon className="" onClick={handleReload} />
              </button>
              <MessageActions selectedContact={selectedContact} />
            </div>
          </div>
        </div>
      )}
      {selectedContact?.id && (
        <MessageDetails
          selectedContact={selectedContact}
          renderPlaceholder={renderPlaceholder}
          reciever={reciever}
          chatSearchValue={messageSearchValue}
        />
      )}
    </div>
  );
};

export default DisplayedMessages;
