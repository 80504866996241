export interface IBrandDetail {
    id: number;
    adminUrl: string;
    appUrl: string;
    brandCode: string;
    brandName: string;
    favicon: {
        file: {
            large: string;
            original: string;
            thumb: string;
        },
        id: number;
    };
    forgetPasswordImage: {
        file: {
            large: string;
            original: string;
            thumb: string;
        },
        id: number;
    };
    interoperability: boolean;
    loginImage: {
        file: {
            large: string;
            original: string;
            thumb: string;
        },
        id: number;
    };
    logo: {
        file: {
            large: string;
            original: string;
            thumb: string;
        },
        id: number;
    };
    organization: {
        name: string;
        id: number;
    } | null;
    status: string;
    theme: {
        colorCode: string;
        secondaryColor: string;
        name: string;
    }
    type: string;
    dnsPropagationStatus: string;
}

export interface IBrandDetailData {
    brandDetails: IBrandDetail | null;
}

export interface IfetchBrandDetails {
    data: IBrandDetailData;
    message: string;
    stateIs: "Idle" | "Pending" | "Error";
}

// Create an initial state object
const initialState: IfetchBrandDetails = {
    data: {
        brandDetails: null
    },
    message: "",
    stateIs: "Idle",
};

export default initialState;
