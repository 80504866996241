import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IfetchNotifications } from "store/notificationCenter/initialState";
import PersonPlaceholder from "assets/images/Placeholders/OrganizationPlaceholder.png";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import useDateFormatter from "hooks/formatDateWithPattern";
import { fetchNotificationCenterAction } from "store/notificationCenter";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch } from "react-redux";
import moment from "moment";
import usePermissions from "hooks/usePermissions";

export const NotificationCard = () => {
  const { timeFromNowInTimeZone } = useDateFormatter();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { data } = useSelector<RootState, IfetchNotifications>(
    (state) => state.notificationCenter
  );
  const { notifications } = usePermissions();
  const handleNotificationClick = async (notificationId: number) => {
    try {
      const response =
        await apiLibrary.NotificationCenter.readNotification(notificationId);
      Toasts.success(response.message);
      dispatch(fetchNotificationCenterAction());
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };

  return (
    <div className="flex flex-col w-full dark:bg-secondaryLight">
      <div className="flex items-center justify-between px-4 pt-4 pb-2">
        <p className="text-base font-semibold text-secondaryMid dark:text-textMain">
          Notifications
        </p>
      </div>
      <div className="max-h-[60vh] w-full overflow-y-auto ">
        {data?.notificationsCenter?.map((notification: any, index: number) => {
          return (
            <div
              className={`mb-1  ${
                !notification.isRead &&
                "bg-bgHighMidlight cursor-pointer dark:bg-secondaryLight"
              } `}
              key={index}
              onClick={
                !notification.isRead && notifications.canReadNotifications
                  ? () => handleNotificationClick(notification.id)
                  : undefined
              }
            >
              <div
                className={`flex px-4 py-3 ${!notifications.canReadNotifications && "opacity-50 cursor-not-allowed"}`}
              >
                <div className="flex items-center">
                  {!notification.isRead && (
                    <div className="w-2 h-2 mr-2 rounded-full bg-primaryMidLight"></div>
                  )}

                  <div className=" w-[35px] h-[35px] rounded-full">
                    <img
                      src={
                        notification?.profileImage
                          ? notification?.profileImage
                          : PersonPlaceholder
                      }
                      alt={notification?.title}
                      className="object-cover w-full h-full rounded-full"
                    />
                  </div>
                </div>
                <div className="w-full ml-3">
                  <p className="text-xs font-normal text-textLight">
                    {timeFromNowInTimeZone(notification?.createdAt)}
                  </p>

                  <p
                    className="w-full mr-1 overflow-hidden text-sm font-normal leading-5 custom-html-content text-textNeutralGray dark:text-caption font-Overpass overflow-ellipsis line-clamp-2"
                    dangerouslySetInnerHTML={{
                      __html: notification?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
