import axios from "axios";
import { panelsName, panelsTitle } from "../panelsTitle";
import { baseURL } from "config";
import {
  GPS_INFO_QUESTIONS_NAME,
  RIVER_CONDITION_QUESTIONS_NAME,
  SPECIES_DETAILS_QUESTIONS_NAME,
  HARVEST_QUESTIONS_NAME,
} from "../panelsQuestionsName";
import questionTypes from "../questionTypes";
import { HARVEST_QUESTIONS_CHOICES } from "../panelsQuestionMenuOptions";
// import { Serializer } from "survey-core"

const QUESTIONS_NAME = {
  Region: "Region",
  View: "View",
  VantagePoint: "VantagePoint",
  LocationNote: "LocationNote",
};

export function registerPanels(programId: any) {
  return [
    {
      type: "panel",
      name: panelsName.GENERAL_INFORMATION,

      titleLocation: "hidden",
      panelType: "static",
      title: "General Information",
      elements: [
        // {
        //   type: "html",
        //   html: '<h1 class="panels-title">General Information</h1>',
        // },
        {
          type: "dropdown",
          name: "DataEntry",
          title: "Data Entry",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/observers?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "tagbox",
          name: "Observers",
          title: "Observers",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/observers?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "dropdown",
          name: "Reported",
          title: "Reported",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/observers?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: "StartDate",
          title: "Start Date",
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: "EndDate",
          title: "End Date",
        },
        {
          type: "comment",
          name: "Comments",
          title: "Comments",
          placeholder: "Comments",
        },
        {
          type: "audio",
          name: "AudioComments",
          title: "Audio Comments",
        },
      ],
    },
    {
      type: "panel",
      name: panelsName.ENVIRONMENTAL_CONDITIONS,
      titleLocation: "hidden",
      panelType: "static",
      title: "Environmental Conditions",

      elements: [
        {
          type: "dropdown",
          name: "WeatherMethod",
          title: "Weather Method",
          choices: [
            "On-Site Estimate",
            "Airport Weather Station",
            "National Weather Service",
            "Davis Weather Station",
            "Kestrel 3000",
            "Other Method",
            "CATG Weather Station",
            "NOAA Weather Station",
          ],
        },

        {
          type: "dropdown",
          name: "WindSpeed",
          title: "Wind Speed",
          choices: [
            "<1mph (BFT 0)",
            "1-3mph (BFT 1)",
            "4-7mph (BFT 2)",
            "8-12mph (BFT 3)",
            "13-18mph (BFT 4)",
            "19-24mph (BFT 5)",
            "25-31mph (BFT 6)",
            "32-38mph (BFT 7)",
            "39-46mph (BFT 8)",
            "47-54mph (BFT 9)",
          ],
        },
        {
          type: "text",
          inputType: "number",
          name: "exactWindSpeed",
          title: "Exact Wind Speed",
          placeholder: "",
        },
        {
          type: "dropdown",
          name: "WindSpeedUnits",
          title: "Wind Speed Units",
          choices: ["knots", "km/h", "mph"],
          startWithNewLine: false,
        },
        {
          type: "dropdown",
          name: "WindDirection",
          title: "Wind Direction",
          choices: [
            "N",
            "S",
            "E",
            "W",
            "NE",
            "NW",
            "SE",
            "SW",
            "NNE",
            "NNW",
            "ENE",
            "ESE",
            "SSE",
            "SSW",
            "WSW",
            "WNW",
            "Variable",
            "Offshore",
            "Onshore",
          ],
        },
        {
          type: "dropdown",
          name: "SeaState",
          title: "Sea State/Wave Height",
          choices: [
            "<1/4ft",
            "1/4ft-1/2ft",
            "1/2ft-2ft",
            "2ft-4ft",
            "4ft-6ft",
            "6ft-10ft",
            "10ft-14ft",
            "14ft-18ft",
            "18ft-22ft",
            "22ft-26ft",
            ">26ft",
            "High water",
            "Low water",
            "Normal water level",
            "Drought conditions",
            "New flow conditions",
            "Flooding",
          ],
        },
        {
          type: "dropdown",
          name: "Tide",
          title: "Tide",
          choices: ["Low", "Mid", "High"],
        },
        {
          type: "text",
          inputType: "number",
          name: "AirTemperature",
          title: "Air Temperature",
          placeholder: "",
        },
        {
          type: "dropdown",
          name: "AirTemperatureUnit",
          title: "Air Temperature Unit",
          choices: ["Celsius", "Fahrenheit"],

          // titleLocation: "hidden",
          startWithNewLine: false,
        },
        {
          type: "tagbox",
          name: "WeatherCondition",
          title: "Weather Condition",
          choices: [
            "clear",
            "fog/mist",
            "hail",
            "high overcast",
            "low clouds",
            "overcast",
            "partly sunny",
            "sleet",
            "sunny",
            "drizzle",
            "glare",
            "heat wave on water",
            "lightning",
            "no observation",
            "partly cloudy",
            "rain",
            "snow",
            "thunder",
          ],
        },
        {
          type: "dropdown",
          name: "HorizonVisibility",
          title: "Horizon Visibility",
          choices: ["Excellent", "Good", "Fair", "Poor"],
        },
        {
          type: "dropdown",
          name: RIVER_CONDITION_QUESTIONS_NAME.RiverLevel,
          title: "River Level",
          choices: [
            "<1/4ft",
            "1/4ft-1/2ft",
            "1/2ft-2ft",
            "2ft-4ft",
            "4ft-6ft",
            "6ft-10ft",
            "10ft-14ft",
            "14ft-18ft",
            "18ft-22ft",
            "22ft-26ft",
            ">26ft",
            "High water",
            "Low water",
            "Normal water level",
            "Drought conditions",
            "New flow conditions",
            "Flooding",
          ],
        },
        {
          type: "dropdown",
          // name: RIVER_CONDITION_QUESTIONS_NAME.WaterClarity,
          name: "WaterClarity",

          title: "Water Clarity/Turbidity",
          choices: ["Clear", "Muddy", "Tanic"],
        },
        {
          type: "text",
          inputType: "number",
          // name: RIVER_CONDITION_QUESTIONS_NAME.StreamTemperature,
          name: "WaterTemperature",
          title: "Water Temperature",
          placeholder: 5,
        },
        {
          type: "dropdown",
          // name: RIVER_CONDITION_QUESTIONS_NAME.TemperatureType,
          name: "WaterTemperatureUnit",
          title: "Water Temperature Unit",
          startWithNewLine: false,
          // titleLocation: "hidden",
          choices: ["Celsius", "Fahrenheit"],
        },
        {
          type: "text",
          inputType: "number",
          // name: RIVER_CONDITION_QUESTIONS_NAME.StreamTemperature,
          name: "relativeHumidity",
          title: "Relative Humidity (%)",
          min: 0,
          max: 100,
        },
      ],
    },
    {
      type: "panel",
      name: panelsName.GPS_INFORMATION,
      titleLocation: "hidden",
      panelType: "static",
      title: "GPS Info",
      elements: [
        // {
        //   type: "html",
        //   html: '<h1 class="panels-title">GPS Info</h1>',
        // },
        {
          type: "radiogroup",
          name: "CoordinatesFormat",
          title: "Coordinates Format",
          choices: [
            "Degrees, minutes, seconds",
            "Decimal degrees",
            "Degrees, decimal minutes",
          ],
        },
        {
          name: "getGpsDataBtn",
          type: "html",
          html: '<div>\n      <button class="get-gps-data-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">\n      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13604 4.63604C9.65076 1.12132 15.3492 1.12132 18.864 4.63604C22.3787 8.15076 22.3787 13.8492 18.864 17.364L14.6209 21.607C13.4496 22.7783 11.5514 22.7793 10.3795 21.6074L6.13604 17.364C2.62132 13.8492 2.62132 8.15076 6.13604 4.63604ZM12.5 14C14.1569 14 15.5 12.6569 15.5 11C15.5 9.34315 14.1569 8 12.5 8C10.8431 8 9.5 9.34315 9.5 11C9.5 12.6569 10.8431 14 12.5 14Z" fill="white"/>\n      </svg>Get GPS data</button>\n      </div>',
        },
        {
          type: "text",
          name: "Latitude",
          title: "Latitude",
          placeholder: "Type",
        },
        {
          type: "text",
          name: "Longitude",
          title: "Longitude",
          placeholder: "Type",
          startWithNewLine: false,
        },
        {
          name: "pickOnMapButton",
          type: "html",
          html: '<div>\n      <button class="pick-on-map-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n      <g clip-path="url(#clip0_2167_236744)">\n        <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M12 2V4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M12 20V22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M20 12H22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M2 12H4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n      </g>\n      <defs>\n        <clipPath id="clip0_2167_236744">\n          <rect width="24" height="24" fill="white"/>\n        </clipPath>\n      </defs>\n    </svg>Pick on Map</button>\n      \n      </div>',
          startWithNewLine: false,
        },
        {
          type: "radiogroup",
          name: "AltitudeFormat",
          title: "Altitude Format",
          choices: ["Metric System", "Imperial System "],
        },
        {
          type: "text",
          inputType: "number",
          name: "Altitude",
          title: "Altitude",
          placeholder: "Type",
        },
        {
          type: "text",
          inputType: "number",
          name: "LocationAccuracy",
          title: "Location Accuracy",
          placeholder: "Type",
        },
        {
          type: "text",
          inputType: "number",
          name: "AltitudeAccuracy",
          title: "Altitude Accuracy",
          placeholder: "Type",
        },
      ],
    },
    {
      name: panelsName.GEOGRAPHICAL_INFORMATION,
      title: panelsTitle.GEOGRAPHICAL_INFO,
      type: "panel",
      iconName: `icon-${panelsName.GEOGRAPHICAL_INFORMATION}`,
      elements: [
        // {
        //   type: "html",
        //   html: `<h1 class="panels-title">${panelsTitle.GEOGRAPHICAL_INFO}</h1>`,
        // },
        {
          type: questionTypes.shapePicker,
          name: QUESTIONS_NAME.Region,
          title: "Region",
        },
        {
          type: "html",
          name: QUESTIONS_NAME.Region + "Type",
          html: `<h2 class="map-sub-heading">Shape picker</h2>`,
        },
        {
          type: questionTypes.shapePicker,
          name: QUESTIONS_NAME.View,
          title: "View",
        },
        {
          type: "html",
          name: QUESTIONS_NAME.View + "Type",
          html: `<h2 class="map-sub-heading">Shape picker</h2>`,
        },
        {
          type: questionTypes.pointPicker,
          name: QUESTIONS_NAME.VantagePoint,
          title: "Vantage Point",
        },
        {
          type: "html",
          name: QUESTIONS_NAME.VantagePoint + "Type",
          html: `<h2 class="map-sub-heading">Point picker</h2>`,
        },
        {
          type: "comment",
          name: QUESTIONS_NAME.LocationNote,
          title: "Location Note",
          placeholder: "Location Note",
        },
      ],
    },
    // {
    //   type: "panel",
    //   name: panelsName.RIVER_CONDITION,
    //   titleLocation: "hidden",
    //   panelType: "static",
    //   title: "River Conditions",
    //   iconName: "river",
    //   elements: [
    //     // {
    //     //   type: "html",
    //     //   html: '<h1 class="panels-title">River Conditions</h1>',
    //     // },
    //     {
    //       type: "dropdown",
    //       name: RIVER_CONDITION_QUESTIONS_NAME.RiverLevel,
    //       title: "River Level",
    //       choices: [
    //         "<1/4ft",
    //         "1/4ft-1/2ft",
    //         "1/2ft-2ft",
    //         "2ft-4ft",
    //         "4ft-6ft",
    //         "6ft-10ft",
    //         "10ft-14ft",
    //         "14ft-18ft",
    //         "18ft-22ft",
    //         "22ft-26ft",
    //         ">26ft",
    //         "High water",
    //         "Low water",
    //         "Normal water level",
    //         "Drought conditions",
    //         "New flow conditions",
    //         "Flooding",
    //       ],
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: RIVER_CONDITION_QUESTIONS_NAME.WaterClarity,

    //       title: "Water Clarity",
    //       choices: ["Clear", "Muddy", "Tanic"],
    //
    //     },
    //     {
    //       type: "text",
    //       inputType: "number",
    //       name: RIVER_CONDITION_QUESTIONS_NAME.StreamTemperature,
    //       title: "Stream Temperature,Celsius",
    //       placeholder: 5,
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: RIVER_CONDITION_QUESTIONS_NAME.TemperatureType,
    //       title: "Temperature Unit",
    //       placeholder: 5,
    //       startWithNewLine: false,
    //       // titleLocation: "hidden",
    //       choices: ["Celsius", "Fahrenheit"],
    //
    //     },
    //   ],
    // },
    {
      type: "panel",
      name: panelsName.SPECIES_DETAIL,
      title: "Species Details",
      titleLocation: "hidden",
      panelType: "static",
      elements: [
        // {
        //   type: "html",
        //   html: '<h1 class="panels-title">Species Details</h1>',
        // },
        {
          type: "dropdown",
          title: "Category",
          name: SPECIES_DETAILS_QUESTIONS_NAME.Category,

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/categories?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "dropdown",
          name: "Type",
          title: "Type",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/types?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "dropdown",
          name: "ObjectName",
          title: "Object Name",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/objects?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "dropdown",
          name: "SpecieName",
          title: "Species Name",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/species?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "dropdown",
          name: "PresenceAbsence",
          title: "Presence/Absence",
          choices: ["present", "absent/not observed"],
        },
        {
          type: "dropdown",
          name: "IdConfidence",
          title: "ID Confidence",
          choices: [
            "Sure",
            "Likely",
            "Not Sure",
            "Unconfirmed",
            "Not Applicable",
          ],
        },
        {
          type: "dropdown",
          name: "Sex",
          title: "Sex",
          choices: ["Male", "Female", "Combined", "Unknown"],
        },
        {
          type: "dropdown",
          name: "AgeClass",
          title: "Age Class",
          choices: [
            "Juvenile",
            "Newborn (Pup/Calf/Chick)",
            "Unknown",
            "Combined",
            "Subadult",
            "Yearling",
            "Adult",
            "Ocean adult",
            "Spawning adult",
            "Egg",
            "Alevin",
            "Fry",
            "Fingerling",
            "Smolt",
            "Jack salmon",
            "Yearling",
            "Jack salmon",
            "Harem Bull",
            "Year Class/Age 1",
            "Year Class/Age 2",
            "Year Class/Age 3",
            "Year Class/Age 4",
            "Year Class/Age 5",
            "Year Class/Age 6",
            "Year Class/Age 7+",
          ],

          startWithNewLine: false,
        },
      ],
    },
    {
      type: "panel",
      name: panelsName.COUNT_DETAILS,
      titleLocation: "hidden",
      panelType: "static",
      title: "Count Details",
      elements: [
        {
          type: "text",
          inputType: "number",
          placeholder: "Select",
          name: "ExactCount",
          title: "Exact Count",
        },
        {
          type: "text",
          inputType: "number",
          placeholder: "Select",
          name: "MinimumCount",
          title: "Minimum Count",
        },
        {
          type: "text",
          inputType: "number",
          placeholder: "Select",
          name: "MaximumCount",
          title: "Maximum Count",
          startWithNewLine: false,
        },
        {
          type: "dropdown",
          name: "CountUnits",
          title: "Count Units",
          choices: [
            "Number",
            "mm",
            "Block Count",
            "degrees C",
            "degrees F",
            "cm",
            "inches",
            "Kilograms",
            "Ounces",
            "Pounds",
            "Gallons",
            "Barrels",
          ],
        },
      ],
    },
    {
      type: "panel",
      name: panelsName.SIGHTING_DETAIL,
      title: "Sighting Details",
      titleLocation: "hidden",
      panelType: "static",
      elements: [
        {
          type: "radiogroup",
          name: "CoordinatesFormat",
          title: "Coordinates Format",
          choices: [
            "Degrees, minutes, seconds",
            "Decimal degrees",
            "Degrees, decimal minutes",
          ],
        },
        {
          name: "getGpsDataBtn",
          type: "html",
          html: '<div>\n     \n      <button class="get-gps-data-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">\n      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.13604 4.63604C9.65076 1.12132 15.3492 1.12132 18.864 4.63604C22.3787 8.15076 22.3787 13.8492 18.864 17.364L14.6209 21.607C13.4496 22.7783 11.5514 22.7793 10.3795 21.6074L6.13604 17.364C2.62132 13.8492 2.62132 8.15076 6.13604 4.63604ZM12.5 14C14.1569 14 15.5 12.6569 15.5 11C15.5 9.34315 14.1569 8 12.5 8C10.8431 8 9.5 9.34315 9.5 11C9.5 12.6569 10.8431 14 12.5 14Z" fill="white"/>\n      </svg>Get GPS data</button>\n      </div>',
        },
        {
          type: "text",
          name: "Latitude",
          title: "Latitude",
          placeholder: "Type",
        },
        {
          type: "text",
          name: "Longitude",
          title: "Longitude",
          placeholder: "Type",
          startWithNewLine: false,
        },
        {
          type: "html",
          name: "pickOnMapButton",
          html: '<div>\n      <button class="pick-on-map-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n      <g clip-path="url(#clip0_2167_236744)">\n        <path d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12Z" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M12 2V4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M12 20V22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M20 12H22" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n        <path d="M2 12H4" stroke="#005C89" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n      </g>\n      <defs>\n        <clipPath id="clip0_2167_236744">\n          <rect width="24" height="24" fill="white"/>\n        </clipPath>\n      </defs>\n    </svg>Pick on Map</button>\n      \n      </div>',
          startWithNewLine: false,
        },
        {
          type: "dropdown",
          name: "ObservationMethod",
          title: "Observation Method",
          choices: [
            "naked eye",
            "binos.",
            "spotting scope",
            "UAS",
            "Other Method",
            "bigeye bino",
            "bino-10x42",
          ],
        },
        {
          type: "dropdown",
          name: "ObservationDirection",
          title: "Observation Direction",
          choices: [
            "N",
            "S",
            "E",
            "W",
            "NE",
            "NW",
            "SE",
            "SW",
            "NNE",
            "NNW",
            "ENE",
            "ESE",
            "SSE",
            "SSW",
            "WSW",
            "WNW",
            "Overhead",
          ],
        },
        {
          type: "dropdown",
          name: "DistanceUnits",
          title: "Distance Units",
          placeholder: "Meters",
          choices: [
            "Feet",
            "Yards",
            "Miles",
            "Nautical Miles",
            "Meters",
            "Kilometers",
            "Centimeters",
            "Inches",
          ],
        },
        {
          type: "text",
          inputType: "number",
          name: "MinimumDistance",
          title: "Minimum Distance",
          placeholder: "1",
        },
        {
          type: "text",
          inputType: "number",
          name: "MaximumDistance",
          title: "Maximum Distance",
          placeholder: "1000",
          startWithNewLine: false,
        },
        {
          type: "dropdown",
          name: "DistanceMethod",
          title: "Distance Method",
          choices: ["visual estimate", "range finder", "known distance"],
        },
        {
          type: "dropdown",
          name: "Visibility",
          title: "Visibility",
          choices: [
            "None",
            "excellent",
            "good",
            "fair",
            "poor",
            "twilight",
            "dark",
            "none",
            "obstructed-human",
            "obstructed-animal",
            "obstructed-landscape",
          ],
        },
      ],
    },
    // {
    //   type: "panel",
    //   name: panelsName.WEATHER_CONDITION,
    //   titleLocation: "hidden",
    //   panelType: "static",
    //   title: "Weather Conditions",
    //   elements: [
    //     // {
    //     //   type: "html",
    //     //   html: '<h1 class="panels-title">Weather Conditions</h1>',
    //     // },
    //     {
    //       type: "dropdown",
    //       name: "WeatherMethod",
    //       title: "Weather Method",
    //       choices: [
    //         "On-Site Estimate",
    //         "Airport Weather Station",
    //         "National Weather Service",
    //         "Davis Weather Station",
    //         "Kestrel 3000",
    //         "Other Method",
    //         "CATG Weather Station",
    //         "NOAA Weather Station",
    //       ],
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: "WindSpeed",
    //       title: "Wind Speed",
    //       choices: [
    //         "<1mph (BFT 0)",
    //         "1-3mph (BFT 1)",
    //         "4-7mph (BFT 2)",
    //         "8-12mph (BFT 3)",
    //         "13-18mph (BFT 4)",
    //         "19-24mph (BFT 5)",
    //         "25-31mph (BFT 6)",
    //         "32-38mph (BFT 7)",
    //         "39-46mph (BFT 8)",
    //         "47-54mph (BFT 9)",
    //       ],
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: "WindDirection",
    //       title: "Wind Direction",
    //       choices: [
    //         "N",
    //         "S",
    //         "E",
    //         "W",
    //         "NE",
    //         "NW",
    //         "SE",
    //         "SW",
    //         "NNE",
    //         "NNW",
    //         "ENE",
    //         "ESE",
    //         "SSE",
    //         "SSW",
    //         "WSW",
    //         "WNW",
    //         "Variable",
    //         "Offshore",
    //         "Onshore",
    //       ],
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: "SeaState",
    //       title: "Sea State",
    //       choices: [
    //         "<1/4ft",
    //         "1/4ft-1/2ft",
    //         "1/2ft-2ft",
    //         "2ft-4ft",
    //         "4ft-6ft",
    //         "6ft-10ft",
    //         "10ft-14ft",
    //         "14ft-18ft",
    //         "18ft-22ft",
    //         "22ft-26ft",
    //         ">26ft",
    //         "High water",
    //         "Low water",
    //         "Normal water level",
    //         "Drought conditions",
    //         "New flow conditions",
    //         "Flooding",
    //       ],
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: "Tide",
    //       title: "Tide",
    //       choices: ["Low", "Mid", "High"],
    //
    //     },
    //     {
    //       type: "text",
    //       inputType: "number",
    //       name: "AirTemperature",
    //       title: "Air Temperature",
    //       placeholder: "42",
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: "AirTemperatureUnit",
    //       title: "Air Temperature Unit",
    //       choices: ["Celsius", "Fahrenheit"],
    //
    //       // titleLocation: "hidden",
    //       startWithNewLine: false,
    //     },
    //     {
    //       type: "tagbox",
    //       name: "WeatherCondition",
    //       title: "Weather Condition",
    //       choices: [
    //         "clear",
    //         "fog/mist",
    //         "hail",
    //         "high overcast",
    //         "low clouds",
    //         "overcast",
    //         "partly sunny",
    //         "sleet",
    //         "sunny",
    //         "drizzle",
    //         "glare",
    //         "heat wave on water",
    //         "lightning",
    //         "no observation",
    //         "partly cloudy",
    //         "rain",
    //         "snow",
    //         "thunder",
    //       ],
    //
    //     },
    //     {
    //       type: "dropdown",
    //       name: "HorizonVisibility",
    //       title: "Horizon Visibility",
    //       choices: ["Excellent", "Good", "Fair", "Poor"],
    //
    //     },
    //   ],
    // },
    {
      name: "harvest",
      title: "Harvest",
      iconName: "icon-harvest",
      type: "panel",
      elements: [
        // {
        //   type: "html",
        //   html: `<h1 class="panels-title">${panelsTitle.HARVEST}</h1>`,
        // },
        {
          type: "html",
          html: `<h2 class="panels-title">Retrieval </h2>`,
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: HARVEST_QUESTIONS_NAME.RetrievalDate,
          title: "Retrieval Date And Time",
        },
        // {
        //   type: "text",
        //   inputType: "time",
        //   name: HARVEST_QUESTIONS_NAME.RetrievalTime,
        //   title: "Retrieval Time",
        //   startWithNewLine: false,
        //
        // },
        {
          type: questionTypes.shapePicker,
          name: HARVEST_QUESTIONS_NAME.RetrievalLocation,
          title: "Retrieval Location (Region)",
        },
        {
          type: "html",
          name: HARVEST_QUESTIONS_NAME.RetrievalLocation + "Type",
          html: `<h2 class="map-sub-heading">Shape picker</h2>`,
        },
        {
          type: questionTypes.pointPicker,
          name: HARVEST_QUESTIONS_NAME.ValuedFeautres,
          title: "Retrieval Location (Point)",
        },
        {
          type: "html",
          name: HARVEST_QUESTIONS_NAME.ValuedFeautres + "Type",
          html: `<h2 class="map-sub-heading">Point picker</h2>`,
        },
        {
          type: "html",
          html: `<hr/>`,
        },
        {
          type: "html",
          html: `<h2 class="panels-title">Struck & lost </h2>`,
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: HARVEST_QUESTIONS_NAME.StruckLostDate,
          title: "Struck & Lost Date And Time",
        },
        // {
        //   type: "text",
        //   inputType: "time",
        //   name: HARVEST_QUESTIONS_NAME.StruckLostTime,
        //   title: "Struck & Lost Time",
        //   startWithNewLine: false,
        //
        // },
        {
          type: questionTypes.shapePicker,
          name: HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion,
          title: "Struck & Lost Location (Region)",
        },
        {
          type: "html",
          name: HARVEST_QUESTIONS_NAME.StruckAndLostLocationRegion + "Type",
          html: `<h2 class="map-sub-heading">Shape picker</h2>`,
        },
        {
          type: questionTypes.pointPicker,
          name: HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint,
          title: "Struck & Lost Location (Point)",
        },
        {
          type: "html",
          name: HARVEST_QUESTIONS_NAME.StruckAndLostLocationPoint + "Type",
          html: `<h2 class="map-sub-heading">Point picker</h2>`,
        },
        {
          type: "dropdown",
          name: HARVEST_QUESTIONS_NAME.HarvestMethod,
          title: "Harvest Method",
          choices: HARVEST_QUESTIONS_CHOICES.HarvestMethod,
        },
        {
          type: "text",
          inputType: "number",
          name: HARVEST_QUESTIONS_NAME.ShotsFired,
          title: "Shots Fired",
          placeholder: "1",
        },
        {
          type: "text",
          inputType: "number",
          name: HARVEST_QUESTIONS_NAME.ExactAge,
          title: "Exact Age",
          placeholder: "1",
          startWithNewLine: false,
        },
        {
          type: "text",
          name: HARVEST_QUESTIONS_NAME.ChaseTimeHours,
          title: "Chase Time (Hours)",
          placeholder: "Type",
          inputType: "number",
        },
        {
          type: "text",
          name: HARVEST_QUESTIONS_NAME.ChaseTimeMinutes,
          title: "Chase Time (Minutes)",
          placeholder: "Type",
          inputType: "number",
          startWithNewLine: false,
        },
        {
          type: "dropdown",
          name: HARVEST_QUESTIONS_NAME.RetrievalMethod,
          title: "Retrieval Method",
          choices: HARVEST_QUESTIONS_CHOICES.RetrievalMethod,
        },
      ],
    },
    {
      type: "panel",
      name: panelsName.SAMPLE,
      titleLocation: "hidden",
      panelType: "static",
      title: "Sample",
      elements: [
        {
          type: "text",
          name: "SampleBioId",
          title: "Bio Sample ID",
          placeholder: "Type",
        },
        {
          type: "dropdown",
          name: "SampleType",
          title: "Sample Type",
          choices: [
            "Adrenals",
            "Blubber",
            "Brain",
            "Colon",
            "Esophagus",
            "Fetus",
            "Gonads-Testes",
            "Hair/Fur",
            "Heart",
            "Intestine",
            "Kidney",
            "Liver",
            "Lung",
            "Lymph Nodes-Axillary",
            "Lymph Nodes-Hilar",
            "Lymph Nodes-Mesenteric",
            "Lymph Nodes-Other",
            "Lymph Nodes-Prescapular",
            "Lymph Nodes-Submandibular",
            "Mammary Tissue",
            "Milk",
            "Muscle",
            "Ovaries",
            "Pancreas",
            "Reproductive Tract",
            "Scat",
            "Skin",
            "Skin-Genetics",
            "Skull",
            "Spleen",
            "Stomach",
            "Teeth",
            "Teeth-Lower Canine",
            "Teeth-Upper Canine",
            "Teeth-Upper Left Canine",
            "Teeth-Upper Right 2nd Premolar",
            "Teeth-Upper Right Canine",
            "Thymus",
            "Thyroid",
            "Trachea",
            "Vibrissae/Whisker",
            "Entanglement Debris-Packing Band",
            "Entanglement Debris-Trawl Net",
            "Entanglement Debris-Gill Net",
            "Entanglement Debris-Misc Net",
            "Entanglement Debris-Line",
            "blood",
            "CRRC Samples",
          ],
        },
        {
          type: "text",
          inputType: "number",
          name: "SampleAmount",
          title: "Sample Amount",
          placeholder: "1",
        },
        {
          type: "dropdown",
          name: "AmountUnits",
          title: "Amount Units",
          choices: ["g", "mg", "kg", "lb", "mL", "oz"],
          startWithNewLine: false,
        },
        {
          type: "text",
          name: "SampleCount",
          title: "Sample Count",
          inputType: "number",
          placeholder: "Type",
        },
        {
          type: "dropdown",
          name: "Sampler(S)",
          title: "Sampler(S)",

          choicesByUrl: {
            url: `${baseURL}/common/dropdown/observers?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`,
            valueName: "value",
            titleName: "text",
          },
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: "TissueRemovalTime",
          title: "Tissue Removal Time",
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: "TissueFrozenTime",
          title: "Tissue Frozen Time",
          startWithNewLine: false,
        },
        {
          type: "text",
          name: "ShippedTo",
          title: "Shipped To",
          placeholder: "Type",
        },
        {
          type: "text",
          inputType: "datetime-local",
          name: "ShippingDate",
          title: "Shipping Date",
        },
        {
          type: "text",
          name: "CurrentLocation",
          title: "Current Location",
          placeholder: "Current location",
        },
      ],
    },
  ];
}

export function getQuestionNamesFromAllPanels() {
  let questions: any[] = [];

  registerPanels(122423423424).forEach((element) => {
    if (element.type === "panel" && element?.elements) {
      const panelQuestions = element?.elements
        .filter((item: any) => item.type !== "html")
        ?.map((question) => {
          return { name: question.name, title: question.title };
        });

      questions = questions.concat(panelQuestions);
    }
  });

  return questions;
}
const panelQuestionNames = getQuestionNamesFromAllPanels();

export function initializePropertyVisibility(
  newSelectedElement: any,
  currentElementJson: any,
  panelNames: any,
  Serializer: any
) {
  if (newSelectedElement && panelNames.includes(newSelectedElement.name)) {
    Serializer.findProperty("panel", "showAll").visible = true;
    Serializer.findProperty("panel", "requireAll").visible = true;
  } else if (newSelectedElement && newSelectedElement.getType() === "panel") {
    Serializer.findProperty("panel", "showAll").visible = false;
    Serializer.findProperty("panel", "requireAll").visible = false;
    panelQuestionNames.forEach((element: any) => {
      const showPropertyName = `show${element.name}`;
      const requirePropertyName = `require${element.name}`;
      Serializer.findProperty("panel", showPropertyName).visible = false;
      Serializer.findProperty("panel", requirePropertyName).visible = false;
    });
  }
}

export function updatePropertyVisibility(
  panelElement: any,
  currentElementJson: any,
  Serializer: any
) {
  const showPropertyName = `show${panelElement.name}`;
  const requirePropertyName = `require${panelElement.name}`;

  const showProperty = Serializer.findProperty("panel", showPropertyName);
  const requireProperty = Serializer.findProperty("panel", requirePropertyName);

  if (showProperty && requireProperty) {
    const isNameInCurrentElements = currentElementJson?.elements?.some(
      (element: any) => element.name === panelElement.name
    );
    showProperty.visible = isNameInCurrentElements;
    requireProperty.visible = isNameInCurrentElements;
  }
}

export function handleRequireAll(
  panelSender: any,
  newSelectedElement: any,
  currentElementJson: any,
  creator: any
) {
  const RequireAll = newSelectedElement.getPropertyValue("requireAll");
  currentElementJson?.elements?.forEach((element: any) => {
    const questionFound = creator.survey.getQuestionByName(element.name);
    panelSender.setPropertyValue(`require${element.name}`, RequireAll);
    if (questionFound) {
      questionFound.isRequired = RequireAll;
    }
  });
}

export function handleShowAll(
  creator: any,
  newSelectedElement: any,
  panelSender: any,
  showAll: any,
  findPanelJson: any,
  Serializer: any
) {
  // if (!showAll) {
  const panel = creator.survey.getPanelByName(newSelectedElement.name);
  if (panel && panel.elements) {
    panel.elements.forEach((element: any) => {
      panelSender.setPropertyValue(`show${element.name}`, showAll);
      // panel.removeElement(element);
      element.visible = showAll;
    });
  }
  // } else {
  //   findPanelJson.elements.forEach((elementJson: any) => {
  //     panelSender.setPropertyValue(`require${elementJson.name}`, showAll);
  //     const element = Serializer.createClass(elementJson.type);
  //     element.fromJSON(elementJson);
  //     panelSender.addElement(element);
  //   });
  // }
}

export function handleOtherProperties(
  creator: any,
  newSelectedElement: any,
  panelSender: any,
  findPanelJson: any,
  panelOptions: any,
  Serializer: any
) {
  if (panelOptions.name.startsWith("show")) {
    const dependentQuestions = ["Latitude", "Longitude", "CoordinatesFormat"];
    const htmlButtons = ["getGpsDataBtn", "pickOnMapButton"];

    const dependentPanels = [
      panelsName.GPS_INFORMATION,
      panelsName.SIGHTING_DETAIL,
    ];

    const isShow = newSelectedElement.getPropertyValue(panelOptions.name);
    const questionName = panelOptions.name.substring("show".length);
    const questionFound = creator.survey.getQuestionByName(questionName);

    const updateQuestionProperties = (questions: string[]) => {
      questions.forEach((item: string) => {
        const question = creator.survey.getQuestionByName(item);
        if (question) {
          question.visible = isShow;
          panelSender.setPropertyValue(`show${question.name}`, isShow);
        }
      });
    };

    if (
      newSelectedElement.isPanel &&
      dependentPanels.includes(newSelectedElement.name) &&
      dependentQuestions.includes(questionFound.name)
    ) {
      htmlButtons.forEach((item: any) => {
        const question = creator.survey.getQuestionByName(item);
        if (question) {
          question.visible = isShow;
          panelSender.setPropertyValue(`show${question.name}`, isShow);
        }
      });

      updateQuestionProperties(dependentQuestions);
    }

    if (questionFound) {
      questionFound.visible = isShow;
      panelSender.setPropertyValue(`show${questionFound.name}`, isShow);
    }

    // Uncomment the following block if needed for question removal/addition logic.
    /*
    if (isShow) {
      if (questionJson && index !== -1) {
        const element = Serializer.createClass(questionJson.type);
        element.fromJSON(questionJson);
        panel.elements.splice(index, 0, element);  // Add element back in place
      }
    } else {
      const questionFound = creator.survey.getQuestionByName(questionName);
      if (questionFound) {
        panel.removeElement(questionFound);  // Remove question if hidden
      }
    }
    */
  } else if (panelOptions.name.startsWith("require")) {
    const isRequire = newSelectedElement.getPropertyValue(panelOptions.name);
    const questionName = panelOptions.name?.substring("require".length);

    const questionFound = creator.survey.getQuestionByName(questionName);

    const dependentQuestions = ["Latitude", "Longitude", "CoordinatesFormat"];
    const dependentPanels = [
      panelsName.GPS_INFORMATION,
      panelsName.SIGHTING_DETAIL,
    ];

    const updateQuestionProperties = (questions: string[]) => {
      questions.forEach((item: string) => {
        const question = creator.survey.getQuestionByName(item);
        if (question) {
          question.visible = isRequire;
          panelSender.setPropertyValue(`require${question.name}`, isRequire);
        }
      });
    };

    if (
      newSelectedElement.isPanel &&
      dependentPanels.includes(newSelectedElement.name)
    ) {
      updateQuestionProperties(dependentQuestions);
    }
    if (questionFound) {
      questionFound.isRequired = isRequire;
    }
  }
}

export function findQuestionByName(elements: any, questionName: any) {
  const index = elements.findIndex(
    (element: any) => element.name === questionName
  );
  const questionJson = elements.find((item: any) => item.name === questionName);
  return { questionJson, index };
}

export function propertiesToBeHiddeFromPanels() {
  return [
    "name",
    "title",
    // "description",
    "visible",
    "readOnly",
    "isRequired",
    "questionTitleLocation",
    "questionErrorLocation",
    "questionsOrder",
    "innerIndent",
    "page",
    "startWithNewLine",
    "state",
    "width",
    "minWidth",
    "maxWidth",
    "visibleIf",
    "enableIf",
    "requiredIf",
    "showNumber",
    "showQuestionNumbers",
    "questionStartIndex",
    "requiredErrorText",
  ];
}

export async function fetchDataSources(key: string, programId: any) {
  try {
    const response = await axios.get(
      `${baseURL}/common/dropdown/${key}?token=5iRwAVXnFrQYJ3wtbr6gs3wCZYimIoUup5nwYU323GRrG/OspKqITSWosGqmhimT&program_id=${programId}`
    );

    const updatedChoices = response.data.map((item: any) => ({
      value: item.value,
      text: item.text,
    }));

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
}
