import React, { useEffect, useState } from 'react';
import {
  DocumentIcon,
  MicrophoneIcon,
  PaperClipIcon,
  PhotographIcon,
  VideoCameraIcon,
  VolumeOffIconOutlined,
} from 'assets/icons/HeroIcons';
import useDateFormatter from 'hooks/formatDateWithPattern';
import ellipsize from 'ellipsize';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { filtersInitialState } from 'store/filters/initialState';
import SearchInput from 'Components/Messages/SearchInput';
import { IfetchRecipients } from 'store/messages/initialState';
import { useDispatch } from 'react-redux';
import {
  messagesContactPersonSearchAction,
  messagesFilterReset,
  messagesNewChatAction,
  messagesStatusAction,
} from 'store/filters';
import ContactType from '../MessagesContacts/ContactType';
import usePermissions from 'hooks/usePermissions';
import NewChatIcon from 'assets/icons/HeroIcons/NewChatIcon';
import { fetchContactPersonsAction } from 'store/messages/reducer.actions';
import { TailSpin } from 'react-loader-spinner';
import { resetChatDetails } from 'store/messagesDetail/reducer.actions';
import { getBrandPlaceholder } from 'utils/customBranding';
import useCustomBranding from 'hooks/useCustomBranding';

interface RecipientsListProps {
  selectedContact: any;
  setSelectedContact: (contact: any) => void;
  contactPersonList: any;
  handleSelectedContact: (contact: any) => void;
  handleSelectedOption: (value: any) => void;
}

const RecipientsList: React.FC<RecipientsListProps> = ({
  selectedContact,
  setSelectedContact,
  contactPersonList,
  handleSelectedContact,
  handleSelectedOption,
}) => {
  const { messageStatusFilter, messageContactPersonSearchFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state?.Filters);
  const { data, isLoading } = useSelector<RootState, IfetchRecipients>(
    (state) => state?.allRecipients
  );

  const { messageNewChatFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const { messageAppIdFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );

  const [searchValue, setSearchValue] = useState('');
  const { conversations } = usePermissions();
  const dispatch: any = useDispatch();

  const { timeFromNowInTimeZone } = useDateFormatter();

  const { primaryColor } = useCustomBranding();

  useEffect(() => {
    dispatch(messagesContactPersonSearchAction(searchValue));
  }, [searchValue]);

  useEffect(() => {
    if (messageAppIdFilter?.appId) {
      dispatch(fetchContactPersonsAction(messageAppIdFilter?.appId));
    }
  }, [messageAppIdFilter]);

  useEffect(() => {
    if (
      messageAppIdFilter?.appId &&
      messageContactPersonSearchFilter?.searchString
    ) {
      dispatch(fetchContactPersonsAction(messageAppIdFilter?.appId));
    }
  }, [messageContactPersonSearchFilter]);

  const handleOpenNewChat = () => {
    dispatch(messagesNewChatAction(true));
    dispatch(resetChatDetails());
    dispatch(messagesFilterReset());
    setSelectedContact(null);
  };

  function getFileType(type: string): string {
    if (type.match(/(jpg|jpeg|png|gif|bmp|tiff)$/)) {
      return 'image';
    } else if (type.match(/(mp3|mpeg)$/)) {
      return 'audio';
    } else if (type.match(/(mp4|mpeg|avi|mkv|wmv|flv|mov|webm|quicktime)$/)) {
      return 'video';
    } else if (
      type.match(/(pdf|doc|docx|xls|xlsx|ppt|pptx|geojson|txt|csv|zip|rar|7z)$/)
    ) {
      return 'doc';
    } else {
      return 'unknown';
    }
  }

  const renderFileIcon = (type: string) => {
    const fileType = getFileType(type);

    switch (fileType) {
      case 'image':
        return <PhotographIcon />;
      case 'audio':
        return <MicrophoneIcon />;
      case 'video':
        return <VideoCameraIcon />;
      case 'doc':
        return <DocumentIcon />;
      default:
        return <PaperClipIcon />;
    }
  };
  return (
    <>
      {conversations.canCreateConversations && (
        <div className='w-full px-2 mb-2'>
          <SearchInput
            inputValue={searchValue}
            name='recipients'
            onInputChange={setSearchValue}
            options={contactPersonList || []}
            isMulti={false}
            value={[]}
            onChange={(selectedOption: any) => {
              handleSelectedOption(selectedOption);
            }}
            placeholder='Search'
          />
        </div>
      )}

      <div
        className={`pt-4 overflow-y-auto overflow-x-hidden h-[64vh] conversation-list ${
          isLoading ? 'flex flex-col' : ''
        }`}
      >
        {messageStatusFilter.archived && !isLoading && (
          <ContactType
            title='Contacts'
            handleClick={() => dispatch(messagesStatusAction(false))}
            users={data.contacts}
            archived={messageStatusFilter.archived ? true : false}
          />
        )}

        {conversations.canViewContactPersons && (
          <>
            {data?.recipients && data?.recipients?.length > 0 && !isLoading ? (
              <>
                {data?.recipients?.map((msg, index) => (
                  <div
                    key={index}
                    className={`w-full flex justify-between gap-3 py-1 items-center mb-2 rounded-md px-2 ${
                      selectedContact?.id === msg.id ||
                      messageStatusFilter?.archived
                        ? 'bg-primaryExtraLight dark:bg-primaryExtraLight group isActive'
                        : 'cursor-pointer'
                    }`}
                    id={`conversation${index}`}
                    onClick={() => handleSelectedContact(msg)}
                  >
                    <div className='rounded-full w-9 h-9 shrink-0'>
                      <img
                        src={msg.personImage || getBrandPlaceholder("userProfile")}
                        alt='User Avatar'
                        className='w-full h-full rounded-full'
                      />
                    </div>
                    <div className='flex-1'>
                      <div className='flex items-center justify-between gap-2'>
                        <h2
                          className={`font-semibold text-secondary font-Overpass w-full ${
                            selectedContact?.id === msg.id
                              ? 'dark:text-secondary'
                              : 'dark:text-textMain'
                          }`}
                        >
                          {msg.firstName} {msg.lastName}
                        </h2>
                        <p
                          className={`w-[50%] text-right text-secondaryLight text-[12px] font-Overpass ${
                            selectedContact?.id === msg.id
                              ? 'dark:text-secondary'
                              : 'dark:text-caption'
                          }`}
                        >
                          {timeFromNowInTimeZone(msg.lastMessageAt)}
                        </p>
                      </div>

                      <div className='flex justify-between items-center gap-2'>
                        <div className='flex items-center'>
                          {msg.muted && (
                            <VolumeOffIconOutlined
                              fill='#54595F'
                              className='w-4 h-4 mr-1'
                            />
                          )}
                          <div className='flex items-center'>
                            {msg.lastMessageType === 'text' ||
                            msg.lastMessageType === null ? (
                              <p
                                className={`text-sm rounded text-secondaryMidLight font-Overpass break-all ${
                                  selectedContact?.id === msg.id
                                    ? 'dark:text-secondary'
                                    : 'dark:text-caption'
                                }`}
                              >
                                {ellipsize(msg.lastMessageText, 60) ||
                                  'contact added to chat'}
                              </p>
                            ) : msg.lastMessageType ? (
                              renderFileIcon(msg.lastMessageType)
                            ) : null}
                          </div>
                        </div>

                        {msg.unreadCounter ? (
                          <div className='min-w-[24px] min-h-[24px] flex items-center justify-center px-2 py-1 rounded-md bg-primaryLight'>
                            <p className='text-[12px] text-secondaryLight font-Overpass'>
                              {msg.unreadCounter}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : isLoading ? (
              <div className='loader flex flex-1 justify-center items-center'>
                <TailSpin
                  height='50'
                  width='50'
                  color={primaryColor}
                  ariaLabel='tail-spin-loading'
                  radius='2'
                  wrapperStyle={{}}
                  wrapperClass='tailspin-loader'
                  visible={true}
                />
              </div>
            ) : null}
          </>
        )}
      </div>

      {!messageNewChatFilter?.newChat && (
        <>
          <div className='flex items-center sticky  bg-bgWhite dark:bg-bgtetriary'>
            {!messageStatusFilter.archived && (
              <ContactType
                title='Archive'
                handleClick={() => dispatch(messagesStatusAction(true))}
                users={data.archived}
                archived={messageStatusFilter.archived}
              />
            )}
          </div>

          <div className='w-full flex justify-end'>
            <div
              className='fixed bottom-[8px] cursor-pointer'
              onClick={handleOpenNewChat}
            >
              <NewChatIcon />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecipientsList;
