import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import { Header, Tabs, Pagination, Table } from "Components/AdminsTracking";
// Hooks
import { AdminTrackingTable } from "hooks/useTable";
// Assets
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { filtersInitialState } from "store/filters/initialState";
import { IFetchAdminTracking } from "store/adminTracking/initialState";
import { fetchAdminTrackingAction } from "store/adminTracking";
import { Filters } from "Components/AdminsTracking/Filters";
import NoRecordFound from "view/components/NoRecordFound";
import { TailSpin } from "react-loader-spinner";
import usePermissions from "hooks/usePermissions";
import { log } from "console";
import { adminTrackingResetFiltersAction } from "store/filters";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { setAdminTrackingFiltersAction } from "store/filters/reducer.actions";
import useCustomBranding from "hooks/useCustomBranding";

export const AdminsTracking = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { primaryColor } = useCustomBranding();

  useLayoutEffect(() => {
    dispatch(setAdminTrackingFiltersAction());
  }, []);

  const { data, stateIs } = useSelector<RootState, IFetchAdminTracking>(
    (state) => state?.allAdminTracking
  );
  const { adminTrackingFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state?.Filters
  );
  // const { adminTracking } = usePermissions();

  const { loading } = useSelector<RootState, any>((state) => state?.Loader);
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setSorting,
    sorting,
  } = AdminTrackingTable(data?.adminTracking);

  useEffect(() => {
    dispatch(fetchAdminTrackingAction());
    saveFiltersDataToLocalStorage(adminTrackingFilter);

  }, [adminTrackingFilter]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(adminTrackingResetFiltersAction());
  //   };
  // }, []);
  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          {/* <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div> */}
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
          {stateIs === "Idle" && data.adminTracking?.length === 0 ? (
            <NoRecordFound />
          ) : stateIs === "Pending" ? (
            <div className="loader min-h-[45vh] justify-center items-center flex w-full">
              <TailSpin
                height="50"
                width="50"
                color={primaryColor}
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass="tailspin-loader"
                visible={true}
              />
            </div>
          ) : (
            <Table
              flexRender={flexRender}
              table={table}
              columnOrder={columnOrder}
              columns={columnsConfig}
              columnVisibility={columnVisibility}
              setSorting={setSorting}
              sorting={sorting}
            />
          )}
          <Pagination />
        </div>
      </div>
    </div>
  );
};
export default AdminsTracking;
