import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {
  fill?: string;
  isActive?: boolean;
}

const EmptyDashboardIcon = ({
  // fill = "#6F747B",
  isActive,
  ...restProps
}: IconProps) => (
  <svg
    width="579"
    height="579"
    viewBox="0 0 579 579"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M579 442.817H0V443.107H579V442.817Z" fill="#EBEBEB" />
    <path d="M520.985 461.456H482.63V461.746H520.985V461.456Z" fill="#EBEBEB" />
    <path d="M383.553 464.6H373.486V464.889H383.553V464.6Z" fill="#EBEBEB" />
    <path
      d="M481.471 450.703H459.247V450.993H481.471V450.703Z"
      fill="#EBEBEB"
    />
    <path
      d="M110.765 452.648H60.7475V452.938H110.765V452.648Z"
      fill="#EBEBEB"
    />
    <path
      d="M128.409 452.648H121.076V452.938H128.409V452.648Z"
      fill="#EBEBEB"
    />
    <path d="M260.72 457.539H152.243V457.829H260.72V457.539Z" fill="#EBEBEB" />
    <path
      d="M274.462 391.173H50.8536C47.2093 391.173 44.2437 388.207 44.2437 384.563V70.2446C44.2437 66.6004 47.2093 63.6348 50.8536 63.6348H274.462C278.105 63.6348 281.071 66.6004 281.071 70.2446V384.563C281.071 388.207 278.105 391.173 274.462 391.173ZM50.8536 63.9254C47.368 63.9254 44.5332 66.7602 44.5332 70.2458V384.564C44.5332 388.05 47.368 390.884 50.8536 390.884H274.462C277.947 390.884 280.781 388.05 280.781 384.564V70.2446C280.781 66.7602 277.947 63.9243 274.462 63.9243L50.8536 63.9254Z"
      fill="#EBEBEB"
    />
    <path
      d="M524.933 391.173H301.326C297.681 391.173 294.717 388.207 294.717 384.563V70.2446C294.717 66.6004 297.682 63.6348 301.326 63.6348H524.933C528.578 63.6348 531.543 66.6004 531.543 70.2446V384.563C531.544 388.207 528.578 391.173 524.933 391.173ZM301.326 63.9254C297.841 63.9254 295.006 66.7602 295.006 70.2458V384.564C295.006 388.05 297.841 390.884 301.326 390.884H524.933C528.419 390.884 531.253 388.05 531.253 384.564V70.2446C531.253 66.7602 528.419 63.9243 524.933 63.9243L301.326 63.9254Z"
      fill="#EBEBEB"
    />
    <path
      d="M332.901 305.913H164.912V412.772H332.901V305.913Z"
      fill="#EBEBEB"
    />
    <path
      d="M332.901 305.913H164.912V313.154H332.901V305.913Z"
      fill="#E0E0E0"
    />
    <path
      d="M328.221 311.716C329.426 311.716 330.403 310.739 330.403 309.534C330.403 308.329 329.426 307.353 328.221 307.353C327.016 307.353 326.039 308.329 326.039 309.534C326.039 310.739 327.016 311.716 328.221 311.716Z"
      fill="#FAFAFA"
    />
    <path
      d="M320.972 311.716C322.177 311.716 323.154 310.739 323.154 309.534C323.154 308.329 322.177 307.353 320.972 307.353C319.767 307.353 318.79 308.329 318.79 309.534C318.79 310.739 319.767 311.716 320.972 311.716Z"
      fill="#FAFAFA"
    />
    <path
      d="M313.723 311.716C314.928 311.716 315.905 310.739 315.905 309.534C315.905 308.329 314.928 307.353 313.723 307.353C312.518 307.353 311.541 308.329 311.541 309.534C311.541 310.739 312.518 311.716 313.723 311.716Z"
      fill="#FAFAFA"
    />
    <path
      d="M426.736 335.771H258.747V442.631H426.736V335.771Z"
      fill="#F5F5F5"
    />
    <path
      d="M426.736 335.771H258.747V343.012H426.736V335.771Z"
      fill="#E0E0E0"
    />
    <path
      d="M424.238 339.393C424.238 340.597 423.262 341.574 422.056 341.574C420.852 341.574 419.875 340.598 419.875 339.393C419.875 338.188 420.851 337.211 422.056 337.211C423.261 337.211 424.238 338.187 424.238 339.393Z"
      fill="#FAFAFA"
    />
    <path
      d="M416.989 339.393C416.989 340.597 416.013 341.574 414.807 341.574C413.603 341.574 412.625 340.598 412.625 339.393C412.625 338.188 413.602 337.211 414.807 337.211C416.011 337.211 416.989 338.187 416.989 339.393Z"
      fill="#FAFAFA"
    />
    <path
      d="M409.739 339.393C409.739 340.597 408.762 341.574 407.557 341.574C406.353 341.574 405.375 340.598 405.375 339.393C405.375 338.188 406.351 337.211 407.557 337.211C408.762 337.211 409.739 338.187 409.739 339.393Z"
      fill="#FAFAFA"
    />
    <path
      d="M307.128 100.613C306.986 100.613 306.846 100.627 306.706 100.634C306.885 99.6517 306.993 98.6442 306.993 97.6101C306.993 88.404 299.529 80.9407 290.323 80.9407C290.28 80.9407 290.239 80.9465 290.196 80.9477C287.996 77.1425 283.892 74.5752 279.18 74.5752C274.277 74.5752 270.028 77.3521 267.903 81.4143C266.765 81.1156 265.577 80.9407 264.345 80.9407C256.781 80.9407 250.632 86.9368 250.354 94.4337C247.24 96.7509 245.213 100.448 245.213 104.628C245.213 111.649 250.905 117.34 257.926 117.34C258.979 117.34 259.995 117.198 260.974 116.957H304.622C305.414 117.206 306.255 117.34 307.129 117.34C311.749 117.34 315.494 113.595 315.494 108.976C315.492 104.358 311.747 100.613 307.128 100.613Z"
      fill="#EBEBEB"
    />
    <path
      d="M440.841 133.484C440.731 133.484 440.625 133.494 440.517 133.5C440.655 132.748 440.737 131.976 440.737 131.185C440.737 124.136 435.024 118.423 427.975 118.423C427.941 118.423 427.91 118.427 427.876 118.427C426.192 115.514 423.05 113.549 419.443 113.549C415.688 113.549 412.436 115.675 410.809 118.785C409.938 118.557 409.028 118.423 408.084 118.423C402.293 118.423 397.584 123.014 397.371 128.753C394.987 130.527 393.435 133.359 393.435 136.558C393.435 141.934 397.793 146.291 403.168 146.291C404.099 146.291 404.998 146.152 405.851 145.908H438.676C439.353 146.151 440.079 146.291 440.84 146.291C444.377 146.291 447.244 143.424 447.244 139.888C447.245 136.351 444.379 133.484 440.841 133.484Z"
      fill="#EBEBEB"
    />
    <path
      d="M176.817 142.747C176.708 142.747 176.601 142.758 176.493 142.764C176.631 142.012 176.713 141.24 176.713 140.449C176.713 133.4 171 127.687 163.951 127.687C163.917 127.687 163.886 127.691 163.852 127.691C162.167 124.778 159.026 122.812 155.419 122.812C151.664 122.812 148.412 124.939 146.785 128.049C145.914 127.821 145.004 127.687 144.06 127.687C138.269 127.687 133.56 132.278 133.347 138.017C130.963 139.791 129.411 142.622 129.411 145.822C129.411 151.197 133.769 155.555 139.144 155.555C140.075 155.555 140.974 155.416 141.827 155.172H174.652C175.329 155.415 176.055 155.555 176.816 155.555C180.353 155.555 183.22 152.688 183.22 149.151C183.22 145.615 180.355 142.747 176.817 142.747Z"
      fill="#EBEBEB"
    />
    <path
      d="M91.2296 401.573L89.7728 399.547C89.2401 398.806 88.389 398.36 87.5043 398.36H77.2432C76.3597 398.36 75.5595 398.806 75.1125 399.547L73.8897 401.573C73.4427 402.314 72.6425 402.76 71.7589 402.76H65.6354C64.1752 402.76 63.0612 403.954 63.1457 405.425L65.1282 439.692C65.2151 441.164 66.468 442.356 67.9283 442.356H116.455C117.916 442.356 119.03 441.164 118.944 439.691L116.961 405.424C116.876 403.952 115.624 402.758 114.164 402.758H93.4969C92.6134 402.76 91.7622 402.314 91.2296 401.573Z"
      fill="#E0E0E0"
    />
    <path
      d="M119.015 406.025H74.5567C72.7027 406.025 71.0665 407.516 70.9009 409.355L68.2282 439.027C68.0626 440.866 69.4314 442.356 71.2842 442.356H115.742C117.596 442.356 119.232 440.866 119.398 439.027L122.071 409.355C122.237 407.516 120.869 406.025 119.015 406.025Z"
      fill="#F0F0F0"
    />
    <path
      d="M483.713 402.061L482.256 400.035C481.723 399.293 480.872 398.848 479.989 398.848H469.728C468.844 398.848 468.044 399.293 467.597 400.035L466.375 402.061C465.928 402.802 465.128 403.248 464.245 403.248H458.121C456.661 403.248 455.547 404.441 455.631 405.914L457.614 440.18C457.7 441.652 458.951 442.846 460.412 442.846H508.939C510.399 442.846 511.513 441.652 511.427 440.18L509.445 405.914C509.359 404.442 508.107 403.248 506.647 403.248H485.979C485.097 403.248 484.245 402.802 483.713 402.061Z"
      fill="#E0E0E0"
    />
    <path
      d="M511.499 406.513H467.041C465.187 406.513 463.551 408.003 463.385 409.842L460.713 439.516C460.547 441.355 461.916 442.845 463.769 442.845H508.227C510.081 442.845 511.717 441.355 511.882 439.516L514.555 409.842C514.721 408.004 513.352 406.513 511.499 406.513Z"
      fill="#F0F0F0"
    />
    <path
      d="M343.891 374.375L342.434 372.348C341.902 371.607 341.051 371.161 340.166 371.161H329.905C329.021 371.161 328.221 371.607 327.774 372.348L326.551 374.375C326.104 375.116 325.304 375.562 324.421 375.562H318.297C316.837 375.562 315.723 376.755 315.807 378.227L317.79 412.494C317.876 413.965 319.127 415.159 320.588 415.159H369.115C370.575 415.159 371.689 413.967 371.604 412.494L369.622 378.227C369.536 376.755 368.284 375.562 366.824 375.562H346.156C345.276 375.562 344.425 375.116 343.891 374.375Z"
      fill="#E0E0E0"
    />
    <path
      d="M371.677 378.826H327.22C325.366 378.826 323.729 380.317 323.564 382.155L320.891 411.829C320.725 413.668 322.094 415.158 323.947 415.158H368.405C370.259 415.158 371.895 413.668 372.061 411.829L374.733 382.155C374.899 380.318 373.531 378.826 371.677 378.826Z"
      fill="#F0F0F0"
    />
    <path
      d="M289.5 495.116C413.501 495.116 514.023 489.245 514.023 482.004C514.023 474.762 413.501 468.892 289.5 468.892C165.499 468.892 64.9765 474.762 64.9765 482.004C64.9765 489.245 165.499 495.116 289.5 495.116Z"
      fill="#F5F5F5"
    />
    <path
      d="M187.165 354.242H71.7358C70.2188 354.242 68.989 353.013 68.989 351.496V149.829C68.989 148.312 70.2188 147.082 71.7358 147.082H187.165C188.682 147.082 189.912 148.312 189.912 149.829V351.496C189.912 353.013 188.682 354.242 187.165 354.242Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.7"
      d="M187.165 354.242H71.7358C70.2188 354.242 68.989 353.013 68.989 351.496V149.829C68.989 148.312 70.2188 147.082 71.7358 147.082H187.165C188.682 147.082 189.912 148.312 189.912 149.829V351.496C189.912 353.013 188.682 354.242 187.165 354.242Z"
      fill="white"
    />
    <path
      d="M99.9748 173.324H77.2004V176.413H99.9748V173.324Z"
      fill="#C0C0C0"
    />
    <path
      d="M99.9748 180.272H77.2004V183.361H99.9748V180.272Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 173.324H158.311V176.413H181.086V173.324Z"
      fill="#C0C0C0"
    />
    <path d="M138.625 159.031H115.851V162.12H138.625V159.031Z" fill="#C0C0C0" />
    <path
      d="M181.086 153.628H77.2004V156.716H181.086V153.628Z"
      fill="#C0C0C0"
    />
    <path d="M181.085 189.911H88.7873V193H181.085V189.911Z" fill="#C0C0C0" />
    <path
      d="M181.086 196.087H77.2004V199.175H181.086V196.087Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 202.264H77.2004V205.352H181.086V202.264Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 208.439H77.2004V211.528H181.086V208.439Z"
      fill="#C0C0C0"
    />
    <path
      d="M169.498 214.615H77.2004V217.704H169.498V214.615Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.085 225.833H88.7873V228.921H181.085V225.833Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 232.009H77.2004V235.097H181.086V232.009Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 238.184H77.2004V241.272H181.086V238.184Z"
      fill="#C0C0C0"
    />
    <path d="M181.086 244.36H77.2004V247.449H181.086V244.36Z" fill="#C0C0C0" />
    <path
      d="M169.498 250.536H77.2004V253.625H169.498V250.536Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.085 261.753H88.7873V264.841H181.085V261.753Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 267.929H77.2004V271.017H181.086V267.929Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 274.105H77.2004V277.194H181.086V274.105Z"
      fill="#C0C0C0"
    />
    <path d="M181.086 280.28H77.2004V283.369H181.086V280.28Z" fill="#C0C0C0" />
    <path
      d="M169.498 286.456H77.2004V289.544H169.498V286.456Z"
      fill="#C0C0C0"
    />
    <path d="M181.085 298.832H88.7873V301.92H181.085V298.832Z" fill="#C0C0C0" />
    <path
      d="M181.086 305.008H77.2004V308.096H181.086V305.008Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 311.185H77.2004V314.273H181.086V311.185Z"
      fill="#C0C0C0"
    />
    <path
      d="M181.086 317.359H77.2004V320.448H181.086V317.359Z"
      fill="#C0C0C0"
    />
    <path
      d="M169.498 323.535H77.2004V326.624H169.498V323.535Z"
      fill="#C0C0C0"
    />
    <path d="M181.086 341.34H158.311V344.428H181.086V341.34Z" fill="#C0C0C0" />
    <g opacity="0.2">
      <path
        d="M99.9748 173.324H77.2004V176.413H99.9748V173.324Z"
        fill="white"
      />
      <path
        d="M99.9748 180.272H77.2004V183.361H99.9748V180.272Z"
        fill="white"
      />
      <path
        d="M181.086 173.324H158.311V176.413H181.086V173.324Z"
        fill="white"
      />
      <path d="M138.625 159.031H115.851V162.12H138.625V159.031Z" fill="white" />
      <path
        d="M181.086 153.628H77.2004V156.716H181.086V153.628Z"
        fill="white"
      />
      <path d="M181.085 189.911H88.7873V193H181.085V189.911Z" fill="white" />
      <path
        d="M181.086 196.087H77.2004V199.175H181.086V196.087Z"
        fill="white"
      />
      <path
        d="M181.086 202.264H77.2004V205.352H181.086V202.264Z"
        fill="white"
      />
      <path
        d="M181.086 208.439H77.2004V211.528H181.086V208.439Z"
        fill="white"
      />
      <path
        d="M169.498 214.615H77.2004V217.704H169.498V214.615Z"
        fill="white"
      />
      <path
        d="M181.085 225.833H88.7873V228.921H181.085V225.833Z"
        fill="white"
      />
      <path
        d="M181.086 232.009H77.2004V235.097H181.086V232.009Z"
        fill="white"
      />
      <path
        d="M181.086 238.184H77.2004V241.272H181.086V238.184Z"
        fill="white"
      />
      <path d="M181.086 244.36H77.2004V247.449H181.086V244.36Z" fill="white" />
      <path
        d="M169.498 250.536H77.2004V253.625H169.498V250.536Z"
        fill="white"
      />
      <path
        d="M181.085 261.753H88.7873V264.841H181.085V261.753Z"
        fill="white"
      />
      <path
        d="M181.086 267.929H77.2004V271.017H181.086V267.929Z"
        fill="white"
      />
      <path
        d="M181.086 274.105H77.2004V277.194H181.086V274.105Z"
        fill="white"
      />
      <path d="M181.086 280.28H77.2004V283.369H181.086V280.28Z" fill="white" />
      <path
        d="M169.498 286.456H77.2004V289.544H169.498V286.456Z"
        fill="white"
      />
      <path d="M181.085 298.832H88.7873V301.92H181.085V298.832Z" fill="white" />
      <path
        d="M181.086 305.008H77.2004V308.096H181.086V305.008Z"
        fill="white"
      />
      <path
        d="M181.086 311.185H77.2004V314.273H181.086V311.185Z"
        fill="white"
      />
      <path
        d="M181.086 317.359H77.2004V320.448H181.086V317.359Z"
        fill="white"
      />
      <path
        d="M169.498 323.535H77.2004V326.624H169.498V323.535Z"
        fill="white"
      />
      <path d="M181.086 341.34H158.311V344.428H181.086V341.34Z" fill="white" />
    </g>
    <path
      opacity="0.1"
      d="M192.959 365.272H128.566C127.051 365.272 125.822 364.043 125.822 362.528V309.961C125.822 308.446 127.051 307.218 128.566 307.218H192.959C194.473 307.218 195.702 308.446 195.702 309.961V362.528C195.702 364.044 194.473 365.272 192.959 365.272Z"
      fill="black"
    />
    <path
      d="M201.578 376.259H137.185C135.67 376.259 134.441 375.03 134.441 373.516V320.948C134.441 319.434 135.67 318.205 137.185 318.205H201.578C203.092 318.205 204.321 319.434 204.321 320.948V373.516C204.32 375.032 203.092 376.259 201.578 376.259Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.3"
      d="M201.578 376.259H137.185C135.67 376.259 134.441 375.03 134.441 373.516V320.948C134.441 319.434 135.67 318.205 137.185 318.205H201.578C203.092 318.205 204.321 319.434 204.321 320.948V373.516C204.32 375.032 203.092 376.259 201.578 376.259Z"
      fill="white"
    />
    <path d="M155.172 352.032H141.276V358.98H155.172V352.032Z" fill="#C0C0C0" />
    <path d="M195.702 352.032H156.33V358.98H195.702V352.032Z" fill="#C0C0C0" />
    <path
      d="M155.172 342.769H141.276V350.875H155.172V342.769Z"
      fill="#C0C0C0"
    />
    <path d="M155.172 334.662H141.276V341.61H155.172V334.662Z" fill="#C0C0C0" />
    <path d="M195.702 342.769H156.33V350.875H195.702V342.769Z" fill="#C0C0C0" />
    <path d="M195.702 360.139H156.33V368.245H195.702V360.139Z" fill="#C0C0C0" />
    <path d="M195.702 334.662H156.33V341.61H195.702V334.662Z" fill="#C0C0C0" />
    <path
      d="M155.172 360.139H141.276V368.245H155.172V360.139Z"
      fill="#C0C0C0"
    />
    <path
      d="M155.172 325.398H141.276V333.504H155.172V325.398Z"
      fill="#C0C0C0"
    />
    <path d="M195.702 325.398H156.33V333.504H195.702V325.398Z" fill="#C0C0C0" />
    <path
      opacity="0.1"
      d="M212.957 264.895H148.564C147.05 264.895 145.821 263.666 145.821 262.152V209.584C145.821 208.069 147.05 206.841 148.564 206.841H212.957C214.472 206.841 215.701 208.069 215.701 209.584V262.152C215.701 263.667 214.472 264.895 212.957 264.895Z"
      fill="black"
    />
    <path
      d="M225.896 262.151H161.503C159.988 262.151 158.759 260.923 158.759 259.408V206.842C158.759 205.327 159.988 204.099 161.503 204.099H225.896C227.41 204.099 228.639 205.327 228.639 206.842V259.409C228.638 260.924 227.41 262.151 225.896 262.151Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.3"
      d="M225.896 262.151H161.503C159.988 262.151 158.759 260.923 158.759 259.408V206.842C158.759 205.327 159.988 204.099 161.503 204.099H225.896C227.41 204.099 228.639 205.327 228.639 206.842V259.409C228.638 260.924 227.41 262.151 225.896 262.151Z"
      fill="white"
    />
    <path d="M221.178 211.914H203.808V220.02H221.178V211.914Z" fill="#C0C0C0" />
    <path d="M202.65 211.914H185.28V220.02H202.65V211.914Z" fill="#C0C0C0" />
    <path d="M202.65 220.021H185.28V254.761H202.65V220.021Z" fill="#C0C0C0" />
    <path
      d="M221.178 220.021H203.808V254.761H221.178V220.021Z"
      fill="#C0C0C0"
    />
    <path d="M184.122 211.914H166.752V220.02H184.122V211.914Z" fill="#C0C0C0" />
    <path
      d="M184.122 220.021H166.752V254.761H184.122V220.021Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.1"
      d="M234.847 325.824H170.454C168.939 325.824 167.711 324.595 167.711 323.08V270.513C167.711 268.998 168.939 267.77 170.454 267.77H234.847C236.362 267.77 237.59 268.998 237.59 270.513V323.08C237.589 324.595 236.362 325.824 234.847 325.824Z"
      fill="black"
    />
    <path
      d="M258.32 322.101H193.927C192.412 322.101 191.183 320.872 191.183 319.358V266.79C191.183 265.276 192.412 264.047 193.927 264.047H258.32C259.834 264.047 261.063 265.276 261.063 266.79V319.358C261.062 320.873 259.834 322.101 258.32 322.101Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.3"
      d="M258.32 322.101H193.927C192.412 322.101 191.183 320.872 191.183 319.358V266.79C191.183 265.276 192.412 264.047 193.927 264.047H258.32C259.834 264.047 261.063 265.276 261.063 266.79V319.358C261.062 320.873 259.834 322.101 258.32 322.101Z"
      fill="white"
    />
    <path
      d="M253.602 272.131H226.968V291.817H253.602V272.131Z"
      fill="#C0C0C0"
    />
    <path d="M225.81 272.131H199.176V291.817H225.81V272.131Z" fill="#C0C0C0" />
    <path d="M225.81 292.975H199.176V314.977H225.81V292.975Z" fill="#C0C0C0" />
    <path
      d="M253.602 292.975H226.968V314.977H253.602V292.975Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.2"
      d="M260.55 266.792V305.712H236.545C233.534 305.712 231.6 303.559 231.6 300.548V264.024H258.315C259.832 264.024 260.55 265.275 260.55 266.792Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M236.31 210.316H171.917C170.402 210.316 169.173 209.087 169.173 207.572V155.005C169.173 153.49 170.402 152.262 171.917 152.262H236.31C237.824 152.262 239.053 153.49 239.053 155.005V207.572C239.053 209.088 237.825 210.316 236.31 210.316Z"
      fill="black"
    />
    <path
      d="M247.898 199.888H183.505C181.99 199.888 180.761 198.659 180.761 197.145V144.577C180.761 143.063 181.99 141.834 183.505 141.834H247.898C249.412 141.834 250.641 143.063 250.641 144.577V197.145C250.64 198.659 249.412 199.888 247.898 199.888Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.3"
      d="M247.898 199.888H183.505C181.99 199.888 180.761 198.659 180.761 197.145V144.577C180.761 143.063 181.99 141.834 183.505 141.834H247.898C249.412 141.834 250.641 143.063 250.641 144.577V197.145C250.64 198.659 249.412 199.888 247.898 199.888Z"
      fill="white"
    />
    <path d="M230.442 149.382H208.44V155.172H230.442V149.382Z" fill="#C0C0C0" />
    <path d="M218.862 156.33H208.44V192.228H218.862V156.33Z" fill="#C0C0C0" />
    <path d="M243.18 149.382H231.6V155.172H243.18V149.382Z" fill="#C0C0C0" />
    <path d="M243.18 156.33H231.6V192.228H243.18V156.33Z" fill="#C0C0C0" />
    <path d="M230.442 156.33H220.02V192.228H230.442V156.33Z" fill="#C0C0C0" />
    <path d="M207.282 156.33H188.754V192.228H207.282V156.33Z" fill="#C0C0C0" />
    <path
      d="M207.282 149.382H188.754V155.172H207.282V149.382Z"
      fill="#C0C0C0"
    />
    <path
      d="M440.147 367.31H361.301C358.291 367.31 355.851 364.871 355.851 361.861V283.015C355.851 280.005 358.291 277.565 361.301 277.565H440.147C443.156 277.565 445.596 280.005 445.596 283.015V361.861C445.596 364.871 443.156 367.31 440.147 367.31Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.25"
      d="M434.25 289.29H367.576V355.586H434.25V289.29Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M367.576 355.505L384.551 341.549L390.1 346.018L407.676 331.172L416.309 339.672L434.25 324.625V355.505H367.576Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M367.576 355.506L381.947 328.546L398.74 331.168L411.283 314.773L424.214 317.094L434.25 307.063V355.506H367.576Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M367.576 355.505L400.724 351.412L411.476 346.028L423.056 347.197L434.25 341.609V355.505H367.576Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M436.566 277.92V287.23C436.566 290.241 433.74 292.974 430.73 292.974H365.928V320.164C365.928 323.175 363.195 325.398 360.184 325.398H355.506V283.015C355.506 280.004 358.285 277.92 361.296 277.92H436.566Z"
      fill="black"
    />
    <path
      d="M330.846 291.101H252C248.991 291.101 246.551 288.662 246.551 285.652V206.806C246.551 203.796 248.991 201.356 252 201.356H330.846C333.856 201.356 336.296 203.796 336.296 206.806V285.652C336.296 288.663 333.856 291.101 330.846 291.101Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.8"
      d="M302.822 225.81H279.654C279.097 225.81 278.645 225.359 278.645 224.802V211.725C278.645 211.168 279.097 210.717 279.654 210.717H302.822C303.379 210.717 303.83 211.168 303.83 211.725V224.802C303.83 225.359 303.379 225.81 302.822 225.81Z"
      fill="white"
    />
    <path
      opacity="0.6"
      d="M285.147 254.703H266.795C266.238 254.703 265.786 254.251 265.786 253.694V244.19C265.786 243.633 266.238 243.182 266.795 243.182H285.147C285.704 243.182 286.156 243.633 286.156 244.19V253.694C286.156 254.251 285.704 254.703 285.147 254.703Z"
      fill="white"
    />
    <path
      opacity="0.6"
      d="M316.053 254.703H297.701C297.144 254.703 296.692 254.251 296.692 253.694V244.19C296.692 243.633 297.144 243.182 297.701 243.182H316.053C316.61 243.182 317.062 243.633 317.062 244.19V253.694C317.062 254.251 316.61 254.703 316.053 254.703Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M297.05 277.404H288.377C287.82 277.404 287.368 276.953 287.368 276.396V268.572C287.368 268.015 287.82 267.563 288.377 267.563H297.05C297.607 267.563 298.059 268.015 298.059 268.572V276.396C298.059 276.951 297.607 277.404 297.05 277.404Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M310.628 277.404H301.954C301.397 277.404 300.946 276.953 300.946 276.396V268.572C300.946 268.015 301.397 267.563 301.954 267.563H310.628C311.185 267.563 311.636 268.015 311.636 268.572V276.396C311.636 276.951 311.185 277.404 310.628 277.404Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M324.206 277.404H315.533C314.976 277.404 314.524 276.953 314.524 276.396V268.572C314.524 268.015 314.976 267.563 315.533 267.563H324.206C324.763 267.563 325.215 268.015 325.215 268.572V276.396C325.215 276.951 324.763 277.404 324.206 277.404Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M265.63 277.404H256.957C256.4 277.404 255.948 276.953 255.948 276.396V268.572C255.948 268.015 256.4 267.563 256.957 267.563H265.63C266.187 267.563 266.639 268.015 266.639 268.572V276.396C266.64 276.951 266.188 277.404 265.63 277.404Z"
      fill="white"
    />
    <path
      opacity="0.4"
      d="M280.368 277.404H271.695C271.138 277.404 270.686 276.953 270.686 276.396V268.572C270.686 268.015 271.138 267.563 271.695 267.563H280.368C280.925 267.563 281.377 268.015 281.377 268.572V276.396C281.377 276.951 280.925 277.404 280.368 277.404Z"
      fill="white"
    />
    <g opacity="0.3">
      <path
        d="M305.712 243.181H306.87V235.075H291.816V225.811H290.658V235.075H275.604V243.181H276.762V236.233H305.712V243.181Z"
        fill="white"
      />
      <path
        d="M275.604 261.709H260.55V267.499H261.708V262.867H275.604V267.499H276.762V254.761H275.604V261.709Z"
        fill="white"
      />
      <path
        d="M306.87 262.867V254.761H305.712V262.867H291.816V267.499H292.974V264.025H305.712V267.499H306.87V264.025H319.608V267.499H320.766V262.867H306.87Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.2"
      d="M335.82 206.807V232.758H272.211C269.2 232.758 266.34 230.025 266.34 227.014V201.492H330.852C333.851 201.492 335.82 203.797 335.82 206.807Z"
      fill="black"
    />
    <path
      d="M453.905 252.081H375.059C372.049 252.081 369.609 249.641 369.609 246.631V167.785C369.609 164.776 372.049 162.336 375.059 162.336H453.905C456.914 162.336 459.354 164.776 459.354 167.785V246.631C459.354 249.641 456.914 252.081 453.905 252.081Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.25"
      d="M447.818 174.061H381.144V240.356H447.818V174.061Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M394.664 214.813H387.95V232.028H394.664V214.813Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M394.664 214.813H387.95V240.355H394.664V214.813Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M406.251 202.746H399.537V228.095H406.251V202.746Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M406.251 202.746H399.537V240.356H406.251V202.746Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M417.839 210.168H411.125V230.514H417.839V210.168Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M417.839 210.168H411.125V240.356H417.839V210.168Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M429.426 199.746H422.712V227.117H429.426V199.746Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M429.426 199.746H422.712V240.356H429.426V199.746Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M441.013 183.823H434.299V221.925H441.013V183.823Z"
      fill="white"
    />
    <path
      opacity="0.35"
      d="M441.013 183.823H434.299V240.356H441.013V183.823Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M393.72 162.12V211.219C393.72 214.23 391.138 216.546 388.127 216.546H369.402V167.783C369.402 164.772 372.054 162.12 375.053 162.12H393.72Z"
      fill="black"
    />
    <path
      d="M379.9 190.531H301.055C298.045 190.531 295.605 188.091 295.605 185.082V106.236C295.605 103.226 298.045 100.786 301.055 100.786H379.9C382.91 100.786 385.35 103.226 385.35 106.236V185.082C385.35 188.091 382.91 190.531 379.9 190.531Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.25"
      d="M369.535 150.373C372.139 134.329 361.244 119.212 345.2 116.608C329.156 114.004 314.04 124.9 311.436 140.943C308.832 156.987 319.727 172.104 335.771 174.708C351.815 177.312 366.931 166.416 369.535 150.373Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M340.478 145.659L369.389 151.156C366.759 164.986 354.556 175.088 340.478 175.088C324.333 175.088 311.048 161.804 311.048 145.659C311.048 129.514 324.333 116.229 340.478 116.229V145.659Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M340.477 145.659L340.391 175.088C324.278 175.041 311.047 161.77 311.047 145.659C311.047 129.514 324.331 116.229 340.476 116.229V145.659H340.477Z"
      fill="white"
    />
    <path
      opacity="0.25"
      d="M340.477 145.659L324.965 120.65C327.269 119.221 329.76 118.116 332.367 117.369C334.974 116.622 337.671 116.238 340.383 116.229L340.477 145.659Z"
      fill="white"
    />
    <path
      d="M495.913 203.808C495.913 203.808 511.546 231.208 512.704 237.773C513.862 244.338 499.387 286.315 499.387 286.315C499.387 286.315 502.572 297.366 499.098 299.223C495.624 301.08 489.554 304.843 488.681 301.659C487.807 298.474 488.681 287.971 488.681 287.971L491.291 285.736C491.291 285.736 500.518 241.873 500.532 240.355C500.545 238.837 483.728 212.97 483.728 212.97L495.913 203.808Z"
      fill="#EAEAEA"
    />
    <path
      d="M438.28 471.34L428.888 472.325L428.414 466.882L427.128 452.071L436.52 451.075L437.944 467.438L438.28 471.34Z"
      fill="#EAEAEA"
    />
    <path
      d="M485.156 470.148H476.054L475.973 468.086L475.961 467.832L475.498 454.631L475.486 454.364L475.301 449.304H484.415L485.156 470.148Z"
      fill="#EAEAEA"
    />
    <path
      opacity="0.2"
      d="M436.52 451.075L437.944 467.438L428.414 466.882L427.128 452.071L436.52 451.075Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M485.098 468.99H485.086L475.973 467.832H475.961L475.486 455.094H475.498L484.623 455.823L485.086 468.59L485.098 468.99Z"
      fill="black"
    />
    <path
      d="M506.37 216.928L490.703 227.581L490.401 227.199L480.894 214.808L475.07 207.212L483.673 183.82C493.598 187.595 506.37 216.928 506.37 216.928Z"
      fill="#5D5D5D"
    />
    <path
      opacity="0.2"
      d="M490.401 227.2L480.894 214.809L488.525 203.102C488.525 203.102 493.192 215.191 490.401 227.2Z"
      fill="black"
    />
    <path
      d="M474.792 469.6H485.85C486.223 469.602 486.545 469.862 486.626 470.225L488.421 478.331C488.575 479.057 488.111 479.77 487.385 479.924C487.288 479.944 487.189 479.954 487.09 479.953C483.511 479.895 480.906 479.675 476.413 479.675C473.645 479.675 465.296 479.964 461.475 479.964C457.653 479.964 457.155 476.189 458.719 475.842C465.736 474.313 471.017 472.194 473.263 470.179C473.68 469.797 474.226 469.59 474.792 469.6Z"
      fill="#5D5D5D"
    />
    <path
      d="M427.893 469.311H438.951C439.324 469.313 439.646 469.573 439.727 469.936L441.522 478.042C441.676 478.768 441.212 479.48 440.486 479.634C440.389 479.655 440.29 479.665 440.191 479.663C436.612 479.606 434.007 479.385 429.514 479.385C426.746 479.385 418.397 479.675 414.576 479.675C410.754 479.675 410.256 475.9 411.82 475.553C418.837 474.024 424.117 471.905 426.364 469.89C426.781 469.508 427.327 469.3 427.893 469.311Z"
      fill="#5D5D5D"
    />
    <path
      d="M426.266 465.515H439.617C439.617 465.515 438.529 350.578 452.969 311.762C453.988 324.593 455.135 337.906 456.258 349.001C456.964 355.868 457.659 361.767 458.319 366.26C462.523 395.21 473.13 466.675 473.13 466.675H486.319C486.319 466.675 484.756 398.196 483.193 369.744C481.467 338.86 480.726 270.973 480.657 265.183H432.345C427.748 289.499 415.959 386.771 426.266 465.515Z"
      fill="#C0C0C0"
    />
    <path
      opacity="0.6"
      d="M426.266 465.515H439.617C439.617 465.515 438.529 350.578 452.969 311.762C453.988 324.593 455.135 337.906 456.258 349.001C456.964 355.868 457.659 361.767 458.319 366.26C462.523 395.21 473.13 466.675 473.13 466.675H486.319C486.319 466.675 484.756 398.196 483.193 369.744C481.467 338.86 480.726 270.973 480.657 265.183H432.345C427.748 289.499 415.959 386.771 426.266 465.515Z"
      fill="white"
    />
    <path
      d="M449.889 163.129C450.002 163.572 450.128 164.004 450.281 164.417C451.173 166.917 452.786 168.975 454.786 170.418C455.308 170.8 455.862 171.141 456.434 171.437C459.459 172.995 463.051 173.285 466.297 171.874C467.625 171.312 468.91 170.448 470.061 169.269C474.623 164.663 474.501 159.345 475.015 150.342C475.155 148.404 474.849 146.461 474.094 144.678C471.483 138.387 464.258 135.414 457.979 138.028C449.147 141.628 447.936 155.023 449.889 163.129Z"
      fill="#EAEAEA"
    />
    <path
      opacity="0.2"
      d="M454.785 170.417C455.307 170.799 455.861 171.14 456.433 171.436C459.457 172.994 463.049 173.284 466.295 171.873C465.662 170.133 464.687 168.817 464.687 168.817C464.687 168.817 459.307 167.989 454.785 170.417Z"
      fill="black"
    />
    <path
      d="M472.904 182.096C468.469 186.125 457.603 184.635 457.603 184.635C457.603 184.635 450.821 182.779 452.245 180.66C456.24 180 458.174 178.135 458.973 175.75C459.437 174.36 459.506 172.797 459.379 171.187C459.321 170.342 459.193 169.485 459.043 168.64L472.476 158.692C470.715 165.258 468.562 177.336 472.904 182.096Z"
      fill="#EAEAEA"
    />
    <path
      d="M454.199 144.046C454.296 144.298 454.061 144.817 453.893 145.414C453.569 146.568 453.407 147.769 453.403 148.967C453.391 152.959 455.26 156.598 458.063 159.367C459.065 163.001 460.422 166.56 462.116 169.911C462.286 170.248 462.47 170.601 462.787 170.808C463.159 171.053 463.637 171.046 464.081 171.031C466.697 170.94 469.312 170.849 471.928 170.758C472.391 170.742 472.921 170.692 473.195 170.318C473.344 170.113 473.383 169.849 473.482 169.616C474.193 167.92 477.182 168.724 478.212 167.201C478.631 166.582 478.576 165.763 478.819 165.055C479.464 163.176 482.127 162.197 482.03 160.213C481.973 159.059 480.92 158.009 481.238 156.9C481.551 155.812 482.976 155.442 483.509 154.443C484.053 153.424 483.484 152.183 482.871 151.203C482.257 150.224 481.561 149.106 481.876 147.995C482.001 147.553 482.276 147.173 482.48 146.764C483.25 145.221 482.922 143.203 481.703 141.983C481.213 141.492 480.587 141.109 480.265 140.495C479.734 139.48 480.184 138.12 479.538 137.173C478.898 136.234 477.553 136.192 476.419 136.259C475.285 136.326 473.947 136.317 473.271 135.405C472.787 134.752 472.806 133.811 472.272 133.2C471.514 132.334 470.119 132.576 469.051 133.003C467.984 133.43 466.786 133.975 465.742 133.495C465.022 133.165 464.581 132.422 463.926 131.977C463.01 131.356 461.683 131.43 460.842 132.15C460.259 132.648 459.908 133.401 459.251 133.799C458.329 134.357 457.156 134.036 456.108 133.786C455.06 133.536 453.775 133.45 453.09 134.28C452.113 135.463 453.168 137.509 452.174 138.675C451.603 139.344 450.569 139.432 449.95 140.058C449.098 140.92 449.624 142.662 450.811 142.91C451.16 142.983 451.61 143 451.738 143.332C451.791 143.47 451.767 143.627 451.808 143.768C451.936 144.215 452.567 144.243 453.006 144.089C453.799 143.809 454.117 143.834 454.199 144.046Z"
      fill="#5D5D5D"
    />
    <path
      d="M459.902 155.576C459.364 157.535 458.287 158.973 456.905 159.575C455.071 160.354 453.868 157.77 454.132 155.063C454.383 152.663 455.626 149.287 457.587 149.732C459.551 150.179 460.541 153.154 459.902 155.576Z"
      fill="#EAEAEA"
    />
    <path
      opacity="0.2"
      d="M452.969 311.711C453.988 324.541 455.135 337.881 456.258 348.975C457.902 332.96 459.917 321.542 459.917 321.542C459.917 321.542 461.805 319.99 466.992 311.711C472.18 303.419 466.992 287.972 466.992 287.972C467.155 302.331 452.969 311.711 452.969 311.711Z"
      fill="black"
    />
    <path
      d="M482.886 274.828L427.684 272.13C427.684 272.13 429.271 241.709 435.026 212.204C436.624 204.051 438.523 195.98 440.816 188.627C442.09 184.493 443.491 180.59 445.019 177.035C445.019 177.035 479.331 179.872 486.325 185.28C493.308 190.688 482.504 246.654 482.886 274.828Z"
      fill="#5D5D5D"
    />
    <path
      d="M454.096 177.958C454.096 177.958 459.34 173.841 469.183 174.06C479.026 174.279 479.991 177.958 479.991 177.958C479.991 177.958 490.799 183.35 482.307 192.228C473.815 201.106 457.095 178.609 457.095 178.609L454.096 177.958Z"
      fill="#263238"
    />
    <path
      opacity="0.25"
      d="M454.096 177.958C454.096 177.958 459.34 173.841 469.183 174.06C479.026 174.279 479.991 177.958 479.991 177.958C479.991 177.958 490.799 183.35 482.307 192.228C473.815 201.106 457.095 178.609 457.095 178.609L454.096 177.958Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M445.888 189.332C445.888 189.332 443.491 205 435.026 212.203C436.624 204.051 438.523 195.979 440.816 188.626L445.888 189.332Z"
      fill="black"
    />
    <path
      d="M438.814 177.243C438.814 177.243 404.528 170.231 399.702 168.395C394.878 166.558 369.402 142.185 369.402 142.185L363.82 146.403C363.82 146.403 382.91 171.19 392.465 178.332C402.019 185.473 435.457 196.271 435.457 196.271L438.814 177.243Z"
      fill="#EAEAEA"
    />
    <path
      d="M445.971 177.244L416.108 171.787L415.335 192.422L437.656 201.492C437.656 201.492 451.728 187.373 445.971 177.244Z"
      fill="#5D5D5D"
    />
    <path
      d="M369.402 142.184C366.652 139.053 356.641 132.273 355.193 131.694C353.746 131.115 351.589 139.973 352.023 141.131C352.457 142.289 357.365 144.749 359.837 145.039C362.309 145.328 364.961 147.867 364.961 147.867L369.402 142.184Z"
      fill="#EAEAEA"
    />
    <path
      d="M414.482 171.035L417.764 171.787L416.267 192.799L413.13 191.649L414.482 171.035Z"
      fill="#263238"
    />
    <path
      opacity="0.25"
      d="M414.482 171.035L417.764 171.787L416.267 192.799L413.13 191.649L414.482 171.035Z"
      fill="white"
    />
    <path
      d="M488.681 227.403L506.375 215.42L507.59 218.476L490.722 229.09L488.681 227.403Z"
      fill="#263238"
    />
    <path
      opacity="0.25"
      d="M488.681 227.403L506.375 215.42L507.59 218.476L490.722 229.09L488.681 227.403Z"
      fill="white"
    />
    <path
      d="M424.769 461.752L425.42 465.515H441.198V462.041L424.769 461.752Z"
      fill="#C0C0C0"
    />
    <path
      d="M471.615 463.2L473.13 466.674H486.36L487.228 463.2H471.615Z"
      fill="#C0C0C0"
    />
  </svg>
);
export default EmptyDashboardIcon;
