import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// Components
import {
  Filters,
  Header,
  Tabs,
  Pagination,
  Table,
} from "Components/AdminGeography";
// Hooks
import { GeographiesTable } from "hooks/useTable";
// Assets
// Store Utils
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchAdminGeographyAction } from "store/adminGeography";
import { IfetchGeographies } from "store/adminGeography/initialState";
import { TableProps } from "Components/AdminGeography/Table/types";
import { filtersInitialState } from "store/filters/initialState";
import { TailSpin } from "react-loader-spinner";
import NoRecordFound from "view/components/NoRecordFound";
import { saveFiltersDataToLocalStorage } from "utils/cacheOrRetriveFiltersDate";
import { setGeographyFiltersAction } from "store/filters/reducer.actions";
import useCustomBranding from "hooks/useCustomBranding";

export const GeographyListView = () => {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  useLayoutEffect(() => {
    dispatch(setGeographyFiltersAction());
  }, []);
  const { data, stateIs } = useSelector<RootState, IfetchGeographies>(
    (state) => state?.allGeographies
  );
  const { geographyFilter } = useSelector<RootState, filtersInitialState>(
    (state) => state.Filters
  );
  const {
    flexRender,
    columnVisibility,
    columnOrder,
    columnsConfig,
    table,
    setColumnsConfig,
    setColumnOrder,
    setColumnVisibility,
    setSorting,
    sorting,
  } = GeographiesTable(data?.geographies);

  // Initializing event listenerdispatch
  // Updating and storing data to localstorage
  // Initializing event listener
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      // dispatch(geographyResetFiltersAction());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAdminGeographyAction());
    saveFiltersDataToLocalStorage(geographyFilter);
  }, [geographyFilter]);

  // Handlers
  const toggleInputVisibility = (event: React.MouseEvent) => {
    event.stopPropagation(); // Stop event propagation here to avoid immediate outside click
    setShowInput((prevState: any) => !prevState);
  };
  const handleOutsideClick = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current?.contains(event.target as Node)) {
      setShowInput(false);
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full pt-4 pb-20">
      <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full gap-3">
        <Header />
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full gap-4">
          <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 w-full">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4">
              <div className="flex justify-start  flex-wrap items-center self-stretch flex-grow-0 flex-shrink-0 min-h-[60px]">
                <Tabs />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <Filters
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columns={columnsConfig}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                setColumnsConfig={setColumnsConfig}
              />
            </div>
          </div>
        </div>

        <RednderView
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columnsConfig}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      </div>
    </div>
  );
};
export default GeographyListView;

const RednderView: React.FC<TableProps> = ({
  flexRender,
  table,
  columnOrder,
  columns,
  columnVisibility,
  setSorting,
  sorting,
}) => {
  const { data, stateIs } = useSelector<RootState, IfetchGeographies>(
    (state) => state?.allGeographies
  );
  const { primaryColor } = useCustomBranding();
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 w-full dark:bg-secondaryLight bg-bgWhite">
      {stateIs === "Idle" && data?.geographies.length === 0 ? (
        <NoRecordFound />
      ) : stateIs === "Pending" ? (
        <div className="loader min-h-[45vh] justify-center items-center flex w-full">
          <TailSpin
            height="50"
            width="50"
            color={primaryColor}
            ariaLabel="tail-spin-loading"
            radius="2"
            wrapperStyle={{}}
            wrapperClass="tailspin-loader"
            visible={true}
          />
        </div>
      ) : (
        <Table
          flexRender={flexRender}
          table={table}
          columnOrder={columnOrder}
          columns={columns}
          columnVisibility={columnVisibility}
          setSorting={setSorting}
          sorting={sorting}
        />
      )}
      <Pagination />
    </div>
  );
};
