
import { RenderCellContentProps } from "../../types";
import ellipsize from "ellipsize";
import useDateFormatter from "hooks/formatDateWithPattern";
import useCustomBranding from "hooks/useCustomBranding";
import moment from "moment";


export const RenderCellContent: React.FC<RenderCellContentProps> = ({
  columnDef,
  cellValue,
}) => {
  const { formatDate } = useDateFormatter();
  const { getBrandPlaceholder } = useCustomBranding();

  if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "importedBy"
  ) {
    return (
      <div
        className="flex justify-start items-center relative min-w-[180px]"
      >
        <img
          src={
            cellValue?.image?.file?.thumb ? cellValue?.image?.file?.thumb : getBrandPlaceholder("listingProfile")
          }
          alt=""
          height="100"
          width="100"
          className="object-cover mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } if (
    typeof cellValue === "object" &&
    cellValue !== null &&
    columnDef.id === "name"
  ) {

    return (
      <div
        className="flex justify-start items-center relative min-w-[180px]"
      >
        <img
          src={
            cellValue?.personImage?.file?.thumb ? cellValue?.personImage?.file?.thumb : getBrandPlaceholder("listingProfile")
          }
          alt=""
          height="100"
          width="100"
          className="object-cover mx-2 Img_user_Data"
          style={{ flexShrink: 0 }}
        />
        <div
          style={{ flexWrap: "wrap" }}
          className=" flex flex-col w-full justify-start items-start relative gap-0.5"
        >
          <p className="text-sm w-[200px] text-left break-all text-textMid group-hover:text-primaryDark">
            {`${cellValue?.name}`}
          </p>
        </div>
      </div>
    );
  } else if (cellValue !== null && columnDef.id === "fileName") {
    return (
      <div
        className={`flex items-start flex-grow py-1 px-3`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}>
          {ellipsize(`${cellValue?.fileName}`, 50)}
        </p>
      </div>
    );
  }
  else if (cellValue !== null && columnDef.id === "dataReceivedFrom") {
    return (
      <div
        className={`relative w-[360px] flex items-start flex-grow py-1 px-3`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark break-words`}>
          {ellipsize(`${cellValue}`, 90)}
        </p>
      </div>
    );
  }


  else if (cellValue !== null && (columnDef.id === "importedDate" || columnDef.id === "date")) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${columnDef.id === "address" ? "justify-start" : "justify-start"
          }`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}>
          {moment.utc(cellValue?.toString()).format('DD MMM YYYY, hh:mm A')}
        </p>
      </div>
    );
  } else if (cellValue !== null) {
    return (
      <div
        className={`relative flex items-start flex-grow py-1 px-3 ${columnDef.id === "address" ? "justify-start" : "justify-start"
          }`}
      >
        <p className={`text-sm text-left text-textMid group-hover:text-primaryDark whitespace-nowrap`}>
          {ellipsize(`${cellValue}`, 50)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
