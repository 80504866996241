// utility/colorRangeUtility.ts

/**
 * Utility function to calculate corresponding colors to a given primary color
 * based on the range of differences from a reference theme.
 *
 * @param primary - The primary color in HEX format.
 * @param referenceTheme - The reference theme with primary and related shades.
 * @returns A theme object with calculated colors.
 */
export const calculateColorRange = (
    primary: string,
    referenceTheme: {
        primary: string;
        primaryDark?: string;
        primaryMid?: string;
        primaryMidLight?: string;
        bgBluish_2?: string;
        bgHighMidlight: string;
    }
): {
    primary: string;
    primaryDark: string;
    primaryMid: string;
    primaryMidLight: string;
    bgBluish_2: string;
    bgHighMidlight: string;
} => {
    const hexToRgb = (hex: string): { r: number; g: number; b: number } => {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    const rgbToHex = ({ r, g, b }: { r: number; g: number; b: number }): string =>
        `#${((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)
            .toUpperCase()}`;

    const calculateRelativeColor = (
        baseRgb: { r: number; g: number; b: number },
        referencePrimaryRgb: { r: number; g: number; b: number },
        referenceShadeRgb: { r: number; g: number; b: number }
    ): { r: number; g: number; b: number } => {
        return {
            r: Math.min(255, Math.max(0, baseRgb.r + (referenceShadeRgb.r - referencePrimaryRgb.r))),
            g: Math.min(255, Math.max(0, baseRgb.g + (referenceShadeRgb.g - referencePrimaryRgb.g))),
            b: Math.min(255, Math.max(0, baseRgb.b + (referenceShadeRgb.b - referencePrimaryRgb.b))),
        };
    };

    const primaryRgb = hexToRgb(primary);
    const referencePrimaryRgb = hexToRgb(referenceTheme.primary);

    const primaryDarkRgb = referenceTheme.primaryDark
        ? hexToRgb(referenceTheme.primaryDark)
        : referencePrimaryRgb;
    const primaryMidRgb = referenceTheme.primaryMid
        ? hexToRgb(referenceTheme.primaryMid)
        : referencePrimaryRgb;
    const primaryMidLightRgb = referenceTheme.primaryMidLight
        ? hexToRgb(referenceTheme.primaryMidLight)
        : referencePrimaryRgb;
    const bgBluish2Rgb = referenceTheme.bgBluish_2
        ? hexToRgb(referenceTheme.bgBluish_2)
        : referencePrimaryRgb;
    const bgHighMidlightRgb = hexToRgb(referenceTheme.bgHighMidlight);

    return {
        primary,
        primaryDark: rgbToHex(calculateRelativeColor(primaryRgb, referencePrimaryRgb, primaryDarkRgb)),
        primaryMid: rgbToHex(calculateRelativeColor(primaryRgb, referencePrimaryRgb, primaryMidRgb)),
        primaryMidLight: rgbToHex(calculateRelativeColor(primaryRgb, referencePrimaryRgb, primaryMidLightRgb)),
        bgBluish_2: rgbToHex(calculateRelativeColor(primaryRgb, referencePrimaryRgb, bgBluish2Rgb)),
        bgHighMidlight: rgbToHex(calculateRelativeColor(primaryRgb, referencePrimaryRgb, bgHighMidlightRgb)),
    };
};



export function hexToRgba(hex: string, opacity = 1) {
    // Remove '#' if present
    hex = hex.replace(/^#/, '');

    // Parse the hex color
    let r, g, b;
    if (hex.length === 3) {
        // Handle shorthand hex colors like #FFF
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error("Invalid HEX color.");
    }

    // Return rgba color string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
