import usePermissions from "hooks/usePermissions";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { RootState } from "store";
import { ICommunity } from "store/communityProfile";
import { updateColumnsVisibilityOfObservationsByStatus } from "store/directoriesOrders/reducer.actions";
import { openContactInfoModalAction } from "store/modals";
const generateDynamicCommunityLinks = (communityId: any) => {
  const { communities, programs } = usePermissions();
  return [
    {
      title: "Observations",
      link: `/community/${communityId}/observations`,
      isVisible: true,
    },
    {
      title: "Programs",
      link: `/community/${communityId}/programs`,
      isVisible: programs.canViewProgram,
    },
    {
      title: "Members",
      link: `/community/${communityId}/users`,
      isVisible: communities.canViewUserMembersCommunities,
    },
    {
      title: "Organizations",
      link: `/community/${communityId}/organizations`,
      isVisible: communities.canViewOrganizationsCommunities,
    },
    {
      title: "Objects",
      link: `/community/${communityId}/objects`,
      isVisible: communities.canViewObjectsCommunities,
    },
    {
      title: "Species",
      link: `/community/${communityId}/species`,
      isVisible: communities.canViewSpeciesCommunities,
    },
    {
      title: "Geography",
      link: `/community/${communityId}/geography`,
      isVisible: communities.canViewGeographyMapListCommunities,
    },
    {
      title: "Contact Info",
      link: ``,
      isVisible: communities.canViewContactInfo,
    },
  ];
};
export const ProfileNavigationLinks = () => {
  const communityProfile = useSelector<RootState, ICommunity>(
    (state) => state?.communityProfile
  );
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const navigationLinks = generateDynamicCommunityLinks(communityId);

  const handleClick = (event: any, link: any) => {
    if (link.title === "Contact Info") {
      dispatch(openContactInfoModalAction(communityProfile?.contactInfo));
    } else if (link.title === "Observations") {
      dispatch(updateColumnsVisibilityOfObservationsByStatus("community"));
    }
  };

  return (
    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-8">
      <div className="flex flex-wrap items-center justify-start flex-grow-0 gap-2 ml-20">
        {navigationLinks?.map(
          (link: any, index: number) =>
            link.isVisible && (
              <Link
                key={index}
                to={link.link}
                onClick={(event) => handleClick(event, link)}
                className="flex justify-center items-center flex-grow-0 flex-shrink-0 gap-1 p-1 rounded-lg bg-primaryExtraLight h-[32px] mr-1 hover:bg-primary_20 dark:bg-primary_40"
              >
                <div className="h-[32px] flex justify-center items-center flex-grow-0 flex-shrink-0 relative px-1 pt-1.5 pb-2 rounded">
                  <button className="flex-grow-0 flex-shrink-0 p-1 text-sm text-center text-secondaryMid dark:text-textMain">
                    {link.title}
                  </button>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  );
};
