import PlusIcon from "assets/icons/HeroIcons/PlusIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import { sentenceCase } from "change-case";
import { Field, FieldArray, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import apiLibrary from "services/api";
import { Step } from "store/addReportStepper/initialState";
import { ISummaryReports } from "store/reportsSummary";
import { v4 as uuidv4 } from "uuid";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { IReportSettingsFormValues } from "../..";
import { MaterialUiSelect } from "../Select";
import _, { forEach } from "lodash";
import { Toasts } from "view/components/Toasts";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import MultiSelect from "view/components/Multiselect";
import { RadioGroup } from "@mui/material";
import { RadioBtn } from "Components/RadioBtn";
import ellipsize from "ellipsize";
import BpCheckbox from "view/components/CheckBox";
import useCustomBranding from "hooks/useCustomBranding";
interface ReportSettingsProps extends FormikProps<IReportSettingsFormValues> {
  activeStep: any;
  isLastStep: any;
  steps: Step[];
  reportId: any;
  reportSummary: ISummaryReports;
  fetchReportsSummary: (id: number) => void;
  moveToPreviousStep: () => void;
  moveToNextStep: () => void;
  setAnythingUpdatedThisStep: (currentStep: string, isUpdated: boolean) => void;
}

interface ChoicesByUrl {
  titleName: string;
  url: string;
  valueName: string;
}

interface MetaInfo {
  choices: Array<{
    text: string;
    value: string;
  }> | null;
  choicesByUrl: ChoicesByUrl | null;
}

interface IColumn {
  metaInfo: MetaInfo;
  columnName: string;
  columnType: string;
  isSelected: boolean;
  type: string;
  inputType: string;
  columnId: string;
  columnTitle: string;
}

export const OtherQueryBuilder: React.FC<ReportSettingsProps> = ({
  activeStep,
  isLastStep,
  steps,
  reportId,
  reportSummary,
  fetchReportsSummary,
  moveToPreviousStep,
  moveToNextStep,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldError,
  setFieldTouched,
}) => {
  const [availableColumns, setAvailableColumns] = useState<IColumn[]>([]);
  const [isColumnsLoading, setIsColumnsLoading] = useState(false);
  const { primaryColor } = useCustomBranding();

  const handleClearAll = () => {
    setFieldValue("otherQueryFilter", []);
  };

  const getColumnsByReport = async () => {
    setIsColumnsLoading(true);
    try {
      const { data } = await apiLibrary.Reports.AddReportApis.getReportColumns(
        reportSummary.id
      );
      const availableColumns = data.availableColumns
        .filter((column: IColumn) =>
          [
            "text",
            "number",
            "radiogroup",
            "checkbox",
            "dropdown",
            "tagbox",
          ].includes(column.columnType)
        )
        .map((column: IColumn) => ({
          column: column.columnName,
          type: column.columnName === "status" ? "dropdown" : column.columnType,
          title: column.columnTitle,
          inputType: column.inputType ?? "text",
          metaInfo: column.metaInfo,
        }));

      setAvailableColumns(availableColumns);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(sentenceCase(errorMsg ?? ""));
    } finally {
      setIsColumnsLoading(false);
    }
  };

  useEffect(() => {
    getColumnsByReport();
    return () => {
      setAvailableColumns([]);
    };
  }, []);

  const filteredColumns = availableColumns.filter(
    (item: any) =>
      !values.otherQueryFilter.some((columnItem: any) => {
        return columnItem.column === item.column;
      })
  );

  if (isColumnsLoading) {
    return (
      <div className="flex items-center justify-center w-full loader">
        <TailSpin
          height="50"
          width="50"
          color={primaryColor}
          ariaLabel="tail-spin-loading"
          radius="2"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col items-start self-stretch justify-start w-full gap-4 bg-white rounded-lg">
      <div className="flex flex-col items-start self-stretch justify-start ">
        <div className="flex items-center justify-between w-full mt-2 mb-1">
          {/* <p className="text-base text-textMid font-Overpass dark:text-textMain">
            Filter Observations
          </p>
          <button type="button" onClick={handleClearAll}>
            <p className="text-sm font-semibold cursor-pointer text-textLink">
              Clear All
            </p>
          </button> */}
        </div>
        <FieldArray name="otherQueryFilter">
          {({ push, remove }) => {
            return (
              <div className="w-full">
                {values.otherQueryFilter.map((rowKey, index) => {
                  const otherQueryFilterErrors: any = errors?.otherQueryFilter
                    ? errors.otherQueryFilter[index]
                    : {
                      column: null,
                      condition: null,
                      operator: null,
                      type: null,
                      value: null,
                    };

                  const otherQueryFilterTouched = otherQueryFilterErrors || {
                    column: false,
                    condition: false,
                    operator: false,
                    type: false,
                    value: false,
                  };
                  if (
                    ["text", "number", "dropdown", "tagbox"].includes(
                      values?.otherQueryFilter[index]?.type
                    )
                  ) {
                    return (
                      <div
                        className="flex items-start w-full gap-3"
                        key={index}
                      >
                        {index > 0 ? (
                          <div className="w-[100px] flex-shrink-0 py-3">
                            <MaterialUiSelect
                              options={[
                                {
                                  label: "and",
                                  value: "and",
                                },
                                {
                                  label: "or",
                                  value: "or",
                                },
                              ]}
                              labelKey="label"
                              valueKey="value"
                              error={otherQueryFilterErrors?.operator}
                              initialValue={
                                values?.otherQueryFilter[index]?.operator
                              }
                              handleChange={(value: any) => {
                                setFieldValue(
                                  `otherQueryFilter[${index}].operator`,
                                  value.value
                                );
                              }}
                              width={100}
                              className="border-none captitalize"
                              styles={{ textTransform: "capitalize" }}
                            />
                          </div>
                        ) : (
                          <div className="w-[100px] dark:text-textMain mt-4 flex-shrink-0">
                            <p className="mt-[5px] text-center uppercase">
                              Where
                            </p>
                          </div>
                        )}

                        <div className="flex-1 max-w-[25%] py-3">
                          <ColumnsDropdown
                            rowIndex={index}
                            values={values}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            otherQueryFilterErrors={otherQueryFilterErrors}
                            otherQueryFilterTouched={otherQueryFilterTouched}
                            availableColumns={availableColumns}
                          />
                        </div>

                        <div className="flex-1 py-3">
                          <ConditionDropdown
                            rowIndex={index}
                            values={values}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            otherQueryFilterErrors={otherQueryFilterErrors}
                            otherQueryFilterTouched={otherQueryFilterTouched}
                            reportSummary={reportSummary}
                          />
                        </div>
                        <div className="flex-1 max-w-[25%] items-start justify-start w-full">
                          {renderComponentByType(
                            index,
                            values,
                            setFieldValue,
                            otherQueryFilterErrors,
                            otherQueryFilterTouched,
                            handleChange,
                            handleBlur
                          )}
                        </div>

                        <div className="w-[50px] cursor-pointer mt-3">
                          <TrashIcon onClick={() => remove(index)} />
                        </div>
                        {/* </div> */}
                      </div>
                    );
                  } else if (
                    ["radiogroup", "checkbox"].includes(
                      values?.otherQueryFilter[index]?.type
                    )
                  ) {
                    return (
                      <>
                        <div
                          className="flex items-start w-full gap-2"
                          key={index}
                        >
                          {index > 0 ? (
                            <div className="w-[100px] flex-shrink-0 py-3">
                              <MaterialUiSelect
                                options={[
                                  {
                                    label: "and",
                                    value: "and",
                                  },
                                  {
                                    label: "or",
                                    value: "or",
                                  },
                                ]}
                                labelKey="label"
                                valueKey="value"
                                error={otherQueryFilterErrors?.operator}
                                initialValue={
                                  values?.otherQueryFilter[index]?.operator
                                }
                                handleChange={(value: any) => {
                                  setFieldValue(
                                    `otherQueryFilter[${index}].operator`,
                                    value.value
                                  );
                                }}
                                width={100}
                                className="border-none captitalize"
                                styles={{ textTransform: "capitalize" }}
                              />
                            </div>
                          ) : (
                            <div className="w-[100px] dark:text-textMain self-center flex-shrink-0">
                              <p className="mt-[5px] text-center uppercase">
                                Where
                              </p>
                            </div>
                          )}

                          <div className="flex-1 max-w-[25%] py-3">
                            <ColumnsDropdown
                              rowIndex={index}
                              values={values}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              otherQueryFilterErrors={otherQueryFilterErrors}
                              otherQueryFilterTouched={otherQueryFilterTouched}
                              availableColumns={availableColumns}
                            />
                          </div>

                          <div className="flex-1 py-3">
                            <ConditionDropdown
                              rowIndex={index}
                              values={values}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              otherQueryFilterErrors={otherQueryFilterErrors}
                              otherQueryFilterTouched={otherQueryFilterTouched}
                              reportSummary={reportSummary}
                            />
                          </div>

                          <div className="w-[50px] cursor-pointer mt-3">
                            <TrashIcon onClick={() => remove(index)} />
                          </div>
                        </div>

                        <div className="flex-1 max-w-[25%] items-start justify-start w-full">
                          {renderComponentByType(
                            index,
                            values,
                            setFieldValue,
                            otherQueryFilterErrors,
                            otherQueryFilterTouched,
                            handleChange,
                            handleBlur
                          )}
                        </div>
                      </>
                    );
                  }
                })}
                <button
                  disabled={filteredColumns.length === 0}
                  type="button"
                  title={`${filteredColumns.length === 0 && "No More Colmuns To Add"
                    }`}
                  onClick={() => {
                    push({
                      column: filteredColumns[0].columnName,
                      columnId: filteredColumns[0]?.columnId,
                      condition: "==",
                      operator: filteredColumns.length === 0 ? "and" : "and",
                      type: filteredColumns[0].type,
                      value: "",
                    });
                  }}
                  className={`flex items-center mb-6 disabled:cursor-not-allowed mt-2 ${filteredColumns.length === 0 ? "opacity-[0.5]" : ""}`}
                >
                  <PlusIcon fill="#005C89" />
                  <p className={`ml-2 text-sm font-semibold text-textLink `}>
                    Add Another
                  </p>
                </button>
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

const ColumnsDropdown: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  availableColumns,
}) => {
  return (
    <MaterialUiSelect
      width={"100%"}
      options={availableColumns}
      labelKey="title"
      valueKey="column"
      error={otherQueryFilterErrors?.column}
      initialValue={values?.otherQueryFilter[rowIndex]?.column}
      handleChange={(value: any) => {
        const { metaInfo, column, type, inputType } = value;

        const updatedMetaInfo = {
          choicesByUrl: metaInfo.choicesByUrl || null,
          choices: metaInfo.choicesByUrl ? null : metaInfo.choices,
        };

        setFieldValue(`otherQueryFilter[${rowIndex}].column`, column);
        setFieldValue(`otherQueryFilter[${rowIndex}].value`, null);
        setFieldValue(`otherQueryFilter[${rowIndex}].type`, type);
        setFieldValue(`otherQueryFilter[${rowIndex}].inputType`, inputType);
        setFieldValue(`otherQueryFilter[${rowIndex}].condition`, null);
        setFieldValue(
          `otherQueryFilter[${rowIndex}].metaInfo`,
          updatedMetaInfo
        );
      }}
      inputStyles={{ border: "none" }}
      className="border-none capitalize"
    />
  );
};

const ConditionDropdown: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  reportSummary
}) => {
  const type = values.otherQueryFilter[rowIndex].type;
  const inputType = values.otherQueryFilter[rowIndex].inputType;
  const dropdownOptions = getDropdown(type === "text" ? inputType : type, reportSummary?.reportSetting?.isRepeat);
  return (
    <MaterialUiSelect
      options={dropdownOptions}
      labelKey="name"
      valueKey="value"
      error={otherQueryFilterErrors?.condition}
      initialValue={values?.otherQueryFilter[rowIndex]?.condition}
      handleChange={(value: any) => {
        setFieldValue(`otherQueryFilter[${rowIndex}].condition`, value.value);
      }}
      styles={{ textTransform: "capitalize" }}
      width="100%"
    />
  );
};

const RenderedInput: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  const type = values.otherQueryFilter[rowIndex].inputType;
  return (
    <TextInput
      label=""
      type={type}
      // inputClassName="border-2"
      name={`otherQueryFilter[${rowIndex}].value`}
      setFieldValue={setFieldValue}
      value={values?.otherQueryFilter[rowIndex]?.value}
      error={otherQueryFilterErrors?.value}
      touched={otherQueryFilterTouched}
      handleChange={(e: any) => {
        const value =
          type === "range" ? Number(e.target.value) : e.target.value;
        setFieldValue(`otherQueryFilter[${rowIndex}].value`, value);
      }}
      handleBlur={handleBlur}
      inputClassName="rounded-[4px] "
    />
  );
};

const RenderedDropdown: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (values?.otherQueryFilter[rowIndex]?.metaInfo)
      processTheMetaInfo(
        values?.otherQueryFilter[rowIndex]?.type,
        values?.otherQueryFilter[rowIndex]?.metaInfo
      )
        .then((res: any) => {
          setOptions(res);
        })
        .catch(() => {
          setOptions([]);
        });
  }, [values?.otherQueryFilter]);

  return (
    <MaterialUiSelect
      options={options}
      labelKey="text"
      valueKey="value"
      initialValue={values?.otherQueryFilter?.[rowIndex]?.value?.value}
      error={otherQueryFilterErrors?.value}
      handleChange={(value: any) => {
        setFieldValue(`otherQueryFilter[${rowIndex}].value`, value);
      }}
      width="100%"
      className="border-none captitalize"
    />
  );
};

const RenderedRadiogroup: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // console.log
    if (values?.otherQueryFilter[rowIndex]?.metaInfo)
      processTheMetaInfo(
        values?.otherQueryFilter[rowIndex]?.type,
        values?.otherQueryFilter[rowIndex]?.metaInfo
      )
        .then((res: any) => {
          setOptions(res);
        })
        .catch(() => {
          setOptions([]);
        });
  }, [values?.otherQueryFilter]);

  const updateRadioGroupAnswer = (e: any) => {
    const { value } = e.target;

    setFieldValue(
      `otherQueryFilter[${rowIndex}].value`,
      options.find((item: any) => item.value.toString() === value)
    );
  };
  return (
    <div className="flex flex-col">
      <RadioGroup
        row
        onBlur={handleBlur}
        onChange={updateRadioGroupAnswer}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={
          values?.otherQueryFilter?.[rowIndex]?.value?.["value"] ?? ""
        }
        name="preferred_unit"
      >
        {options.map((item: any) => {
          return (
            <p className=" dark:text-textMain">
              <RadioBtn
                value={item.value}
                labelContent={
                  <span
                    className=""
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                    }}
                  >
                    {item.text}
                  </span>
                }
              />
            </p>
          );
        })}
      </RadioGroup>
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pl-3">
        {otherQueryFilterErrors?.value && (
          <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
            {otherQueryFilterErrors?.value}
          </p>
        )}
      </div>
    </div>
  );
};

const RenderedCheckboxes: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  const answers = Array.isArray(values?.otherQueryFilter[rowIndex]?.value)
    ? values?.otherQueryFilter[rowIndex]?.value
    : [];
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // console.log
    if (values?.otherQueryFilter[rowIndex]?.metaInfo)
      processTheMetaInfo(
        values?.otherQueryFilter[rowIndex]?.type,
        values?.otherQueryFilter[rowIndex]?.metaInfo
      )
        .then((res: any) => {
          setOptions(res);
        })
        .catch(() => {
          setOptions([]);
        });
  }, [values?.otherQueryFilter]);

  const handleSelection = (dropDown: { text: string; value: any }) => {
    const existingCommunityIndex = answers.findIndex(
      (item: { text: string; value: any }) => item.value === dropDown.value
    );

    let updatedAnswer =
      existingCommunityIndex !== -1
        ? [
          ...answers.slice(0, existingCommunityIndex),
          ...answers.slice(existingCommunityIndex + 1),
        ]
        : [...answers, dropDown];

    setFieldValue(
      `otherQueryFilter[${rowIndex}].value`,
      updatedAnswer.length > 0 ? updatedAnswer : null
    );
  };

  return (
    <div className="flex flex-col pl-4">
      <div className="flex flex-wrap w-full ">
        {options.map((dropDown: any, index: any) => (
          <div
            key={index}
            className="flex items-center justify-start w-auto rounded-lg"
          >
            <BpCheckbox
              checked={answers.some(
                (item: any) => item.value === dropDown.value
              )}
              label={
                <div className="flex whitespace-nowrap justify-start items-center flex-grow relative py-0.5">
                  <p className="dark:text-inputText">{dropDown.text}</p>
                </div>
              }
              labelPlacement="end"
              onChange={(e) => handleSelection(dropDown)}
            />
          </div>
        ))}
      </div>
      <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 w-full pl-3">
        {otherQueryFilterErrors?.value && (
          <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
            {otherQueryFilterErrors?.value}
          </p>
        )}
      </div>
    </div>
  );
};

const RenderedTagbox: React.FC<any> = ({
  rowIndex,
  values,
  handleBlur,
  setFieldValue,
  otherQueryFilterErrors,
  otherQueryFilterTouched,
  handleChange,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // console.log
    if (values?.otherQueryFilter[rowIndex]?.metaInfo)
      processTheMetaInfo(
        values?.otherQueryFilter[rowIndex]?.type,
        values?.otherQueryFilter[rowIndex]?.metaInfo
      )
        .then((res: any) => {
          setOptions(res);
        })
        .catch(() => {
          setOptions([]);
        });
  }, [values?.otherQueryFilter]);

  const [inputValue, setInputValue] = useState("");

  const processedAnswer = transformData(
    values?.otherQueryFilter[rowIndex]?.value
  );
  return (
    <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded">
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${otherQueryFilterTouched?.value && otherQueryFilterErrors?.value
              ? "border-accent_1Dark"
              : "border-lineDark dark:border-lineLight"
              }`}
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
              preserveAspectRatio="xMidYMid meet"
            >
              <path
                d="M21.7495 20.6895L16.0855 15.0255C17.4466 13.3914 18.1253 11.2956 17.9805 9.17389C17.8356 7.05219 16.8784 5.06801 15.3079 3.6341C13.7374 2.2002 11.6745 1.42697 9.54844 1.47528C7.42236 1.52359 5.39674 2.38971 3.89298 3.89347C2.38922 5.39723 1.5231 7.42284 1.47479 9.54893C1.42648 11.675 2.19971 13.7379 3.63361 15.3084C5.06752 16.8789 7.0517 17.8361 9.1734 17.981C11.2951 18.1258 13.391 17.4471 15.025 16.086L20.689 21.75L21.7495 20.6895ZM2.99948 9.74996C2.99948 8.41494 3.39536 7.1099 4.13706 5.99987C4.87876 4.88983 5.93296 4.02467 7.16636 3.51378C8.39976 3.00289 9.75696 2.86921 11.0663 3.12966C12.3757 3.39011 13.5784 4.03299 14.5224 4.97699C15.4665 5.921 16.1093 7.12373 16.3698 8.4331C16.6302 9.74248 16.4966 11.0997 15.9857 12.3331C15.4748 13.5665 14.6096 14.6207 13.4996 15.3624C12.3895 16.1041 11.0845 16.5 9.74948 16.5C7.95987 16.498 6.24414 15.7862 4.9787 14.5207C3.71326 13.2553 3.00146 11.5396 2.99948 9.74996Z"
                fill="#9B9FA5"
              />
            </svg>
            <div className="flex flex-col items-start justify-start flex-grow w-full ">
              <MultiSelect
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                }}
                placeholder="Please select option"
                options={options}
                defaultValue={{ value: "", label: "" }}
                isMulti={true}
                value={processedAnswer ?? []}
                isDisable={false}
                transformY="translateY(-130%)"
                onChange={
                  (option: any[]) => {
                    const updatedOptions = option.map((item: any) => {
                      return { value: item?.value, text: item?.label };
                    });

                    setFieldValue(
                      `otherQueryFilter[${rowIndex}].value`,
                      updatedOptions
                    );
                    // setSelectedOption(option);
                  }
                  // handleCommunityChange(
                  //   selectedOptions,
                  //   setFieldValue
                  // )
                }
              />
            </div>
          </div>
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
            {otherQueryFilterErrors?.value && (
              <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
                {otherQueryFilterErrors?.value}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderComponentByType(
  index: any,
  values: any,
  setFieldValue: any,
  otherQueryFilterErrors: any,
  otherQueryFilterTouched: any,
  handleChange: any,
  handleBlur: any
) {
  const type = values.otherQueryFilter[index].type;
  switch (type) {
    case "status":
    case "dropdown":
      return (
        <div className="w-full py-3">
          <RenderedDropdown
            rowIndex={index}
            className=""
            values={values}
            setFieldValue={setFieldValue}
            otherQueryFilterErrors={otherQueryFilterErrors}
            otherQueryFilterTouched={otherQueryFilterTouched}
            handleChange={handleChange}
          />
        </div>
      );
    case "tagbox":
      return (
        <div className="w-full py-3">
          <RenderedTagbox
            rowIndex={index}
            className=""
            values={values}
            setFieldValue={setFieldValue}
            otherQueryFilterErrors={otherQueryFilterErrors}
            otherQueryFilterTouched={otherQueryFilterTouched}
            handleChange={handleChange}
          />
        </div>
      );
    case "radiogroup":
      return (
        <RenderedRadiogroup
          rowIndex={index}
          className=""
          values={values}
          setFieldValue={setFieldValue}
          otherQueryFilterErrors={otherQueryFilterErrors}
          otherQueryFilterTouched={otherQueryFilterTouched}
          handleChange={handleChange}
        />
      );

    case "checkbox":
      return (
        <RenderedCheckboxes
          rowIndex={index}
          className=""
          values={values}
          setFieldValue={setFieldValue}
          otherQueryFilterErrors={otherQueryFilterErrors}
          otherQueryFilterTouched={otherQueryFilterTouched}
          handleChange={handleChange}
        />
      );
    case "text":
      return (
        <div className="w-full">
          <RenderedInput
            rowIndex={index}
            className=""
            values={values}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            otherQueryFilterErrors={otherQueryFilterErrors}
            otherQueryFilterTouched={otherQueryFilterTouched}
            handleChange={handleChange}
          />
        </div>
      );

    default:
      return (
        <div className="w-full">
          <RenderedInput
            rowIndex={index}
            className=""
            values={values}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            otherQueryFilterErrors={otherQueryFilterErrors}
            otherQueryFilterTouched={otherQueryFilterTouched}
            handleChange={handleChange}
          />
        </div>
      );
  }
}

function getDropdown(type: string, repeat: boolean) {
  switch (type) {
    case "id":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    case "string":
    case "text":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];
    case "boolean":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];
    case "integer":
    case "float":
    case "number":
    case "range":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    case "array":
      return ["Equal To ==", "Not Equal to!=", "Between", "Not Between!="];
    case "decimal":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
        {
          name: "Less Than or Equal To <=",
          value: "<=",
        },
        {
          name: "Less Than <",
          value: "<",
        },
        {
          name: "Greater Than or Equal To >=",
          value: ">=",
        },
        {
          name: "Greater Than >",
          value: ">",
        },
      ];
    case "binary":
    case "binary_id":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];

    case "dropdown":
    case "radiogroup":
      return [
        {
          name: "Equal To ==",
          value: "==",
        },
        {
          name: "Not Equal To !=",
          value: "!=",
        },
      ];

    case "tagbox":
    case "checkbox":
      return [
        {
          name: "Contains",
          value: "contains",
        },
        {
          name: "Not Contains",
          value: "not_contains",
        },
      ];
    case "date":
    case "datetime-local":
    case "time":
    case "utc_date":
    case "utc_datetime":
    case "naive_datetime":
    case "utc_datetime_usec":
    case "naive_datetime_usec":
    case "time_usec":
      return repeat ?
        [
          {
            name: "Equal To ==",
            value: "==",
          },
          {
            name: "Not Equal To !=",
            value: "!=",
          },
          {
            name: "Less Than or Equal To <=",
            value: "<=",
          },
          {
            name: "Less Than <",
            value: "<",
          },
          {
            name: "Greater Than or Equal To >=",
            value: ">=",
          },
          {
            name: "Greater Than >",
            value: ">",
          },
        ] : [
          {
            name: "is",
            value: "==",
          },
          {
            name: "is Not",
            value: "!=",
          },
          {
            name: "was On or Before",
            value: "<=",
          },
          {
            name: "was Before",
            value: "<",
          },
          {
            name: "was On or After",
            value: ">=",
          },
          {
            name: "was After",
            value: ">",
          },
        ];

    default:
      return [];
  }
}

const SIMPLE_TYPES = ["radiogroup", "checkbox", "dropdown"];

function transformData(items: any[]) {
  if (Array.isArray(items)) {
    return items?.map((p: any) => ({ label: p?.text, value: p?.value })) ?? [];
  } else {
    return [];
  }
}
async function processTheMetaInfo(type: string, meta: MetaInfo) {
  try {
    let data: any = [];

    if (meta.choicesByUrl) {
      const response = await axios.get(meta.choicesByUrl.url);
      data = response.data;
    } else {
      data = meta.choices;
    }

    if (SIMPLE_TYPES.includes(type)) {
      return data;
    } else {
      return transformData(data);
    }
  } catch (error) {
    console.error("Error processing the meta info:", error);
    return []; // Return empty array on error
  }
}

// const SIMPLE_TYPES = ["checkbox"];

// function transformData(items: any[]) {
//   if (Array.isArray(items)) {
//     return items?.map((p: any) => ({ label: p?.text, value: p?.value })) ?? [];
//   } else {
//     return [];
//   }
// }
// async function processTheMetaInfo(type: string, meta: MetaInfo) {
//   try {
//     let data: any = [];

//     if (meta.choicesByUrl) {
//       const response = await axios.get(meta.choicesByUrl.url);
//       data = response.data;
//     } else {
//       data = meta.choices;
//     }

//     if (SIMPLE_TYPES.includes(type)) {
//       return data;
//     } else if (["radiogroup", "dropdown"].includes(type)) {
//       return data.map((item: any) => ({
//         text: item.text,
//         value: item.value?.toString() ?? "",
//       }));
//     } else {
//       return transformData(data);
//     }
//   } catch (error) {
//     console.error("Error processing the meta info:", error);
//     return []; // Return empty array on error
//   }
// }
