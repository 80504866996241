import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { closeEmailVerificationModelAction } from "store/modals/reducer.actions";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import { convertKeysToCamelCase } from "utils/caseConvertor";
import { getUserProfileAction } from "store/userProfile";
import Button from "view/components/Button";
import Roles from "hooks/roles";
import { updateAuthDataAction } from "store/auth/reducer.actions";
import { useTimer } from "react-timer-hook";
import {
  addTimeAndSaveToLocalStorage,
  getDifferenceBetweenStoredAndCurrentTime,
} from "utils/timeDiffrence";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import { useParams } from "react-router-dom";
import usePermissions from "hooks/usePermissions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  border: "none",
};

export const EmailVerificationModal: React.FC = () => {
  const { isMyProfile } = Roles();
  const { personId } = useParams();
  const dispatch = useDispatch();
  const { verifyEmailPhone } = usePermissions();

  const { emailVerificationModal } = useSelector(
    (state: RootState) => state.modals
  );
  const [otp, setOtp] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const getNewTime = () => {
    const expiryTimestamp = new Date();

    const timeDifferenceInSeconds = getDifferenceBetweenStoredAndCurrentTime(
      emailVerificationModal.value
    );
    if (timeDifferenceInSeconds !== null && timeDifferenceInSeconds < 300) {
      expiryTimestamp.setSeconds(
        expiryTimestamp.getSeconds() + timeDifferenceInSeconds
      );
    } else {
      expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 300);
      let uniqueKey = `time_${emailVerificationModal.value}`;
      localStorage.removeItem(uniqueKey);
    }

    return expiryTimestamp;
  };

  const { seconds, minutes, isRunning, start, restart } = useTimer({
    expiryTimestamp: getNewTime(),
    onExpire: () => console.warn("onExpire called"),
  });

  useEffect(() => {
    if (emailVerificationModal.isOpen) {
      start();
    }
  }, [emailVerificationModal.isOpen]);

  const handleClose = () => {
    if (isRunning) {
      addTimeAndSaveToLocalStorage(
        emailVerificationModal.value,
        `${minutes}:${seconds}`
      );
    }

    dispatch(closeEmailVerificationModelAction());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = value.replace(/[^0-9]/g, "").substr(0, 6);

    setOtp(formattedValue);
    if (formattedValue.length === 2) {
      inputRef.current?.setSelectionRange(3, 3);
    } else if (formattedValue.length === 4) {
      inputRef.current?.setSelectionRange(6, 6);
    }
  };

  const verifyEmailOrPhoneNumber = async () => {
    try {
      dispatch(setLoadingProgress(getRandomProgress()));
      setIsLoading(true);
      const res = await apiLibrary.userProfile.confirmEmailOrPhone(
        {
          otp: otp,
          phone_or_email: emailVerificationModal.value,
        },
        personId
      );
      const convertedData = convertKeysToCamelCase(res?.data);
      if (isMyProfile(convertedData.personId)) {
        dispatch(updateAuthDataAction(convertedData));
        dispatch(getUserProfileAction(convertedData));
      } else {
        dispatch(getUserProfileAction(convertedData));
      }
      Toasts.success(res.message);
      handleClose();
      setOtp("");
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };

  const resendOtop = async () => {
    if (!verifyEmailPhone.canCreateVerifyEmailPhone) {
      return;
    }
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      setIsLoading(true);
      const res = await apiLibrary.userProfile.verifyEmailOrPhone(
        emailVerificationModal.value,
        personId
      );
      restart(getNewTime());
      Toasts.success(res.message);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setIsLoading(false);
      dispatch(setLoadingProgress(100));
    }
  };
  useEffect(() => {
    if (!isRunning) {
      let uniqueKey = `time_${emailVerificationModal.value}`;
      localStorage.removeItem(uniqueKey);
    }
  }, [isRunning]);
  return (
    <>
      <Modal
        open={emailVerificationModal.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="">
          <div className="flex items-center justify-center w-full h-full bg-bgWhite dark:bg-secondaryLight ">
            <div className="flex flex-col justify-start items-start w-[480px] rounded-lg">
              <div
                className="relative flex flex-col items-start justify-start gap-4 p-6 bg-white rounded-lg"
                style={{
                  boxShadow:
                    "0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                }}
              >
                <div className="flex justify-between w-full align-content-center">
                  <div className="flex flex-col justify-start items-start py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-full text-xl font-semibold text-left text-secondaryMid dark:text-textMain">
                        Verify Your Email
                      </p>
                    </div>
                  </div>
                  <button onClick={handleClose}>
                    <XCloseIcon width={25} height={25} fill="#2C3236" />
                  </button>
                </div>
                <div className="flex justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative gap-2 pb-1.5">
                  <p className="flex-grow w-full text-sm text-left text-secondaryMidLight">
                    Enter The 6-Digit Code To Verify{" "}
                    {emailVerificationModal.value}
                  </p>
                </div>
                <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0">
                  <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1">
                      <p className="flex-grow w-full dark:text-caption text-sm font-medium text-left capitalize text-secondaryMid">
                        Verify Code
                      </p>
                    </div>
                    <input
                      ref={inputRef}
                      type="text"
                      maxLength={6}
                      value={otp}
                      onChange={handleChange}
                      className="flex-grow w-full px-2 text-xl font-semibold text-left dark:text-textMain  outline-none focus:outline-none text-secondaryMid dark:bg-secondaryLight  rounded bg-white border border-lineDark"
                      placeholder="XXXXXX"
                      pattern="\d{2}-\d{2}-\d{2}"
                      required
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center items-start self-stretch w-[432px] flex-grow-0 flex-shrink-0 relative gap-1.5 pt-2">
                  {isRunning ? (
                    <div className="flex flex-col justify-center items-start  relative gap-1.5 pt-2">
                      <p className="self-stretch flex-grow-0 flex-shrink-0  text-sm text-left text-[#6f747b]">
                        A verification code has been sent to your email, this
                        code will remain valid for 5 minutes.
                      </p>
                      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 rounded-3xl">
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative py-[7px]">
                          <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-tertiary">
                            {minutes}:{seconds} Remaining
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    verifyEmailPhone.canCreateVerifyEmailPhone && (
                      <div className="flex flex-col justify-center items-start w-full relative gap-1.5 pt-2">
                        <p className="self-stretch flex-grow-0 flex-shrink-0  text-sm text-left text-[#6f747b]">
                          Your code has expired.
                        </p>
                        <button
                          onClick={resendOtop}
                          disabled={
                            isLoading ||
                            !verifyEmailPhone.canCreateVerifyEmailPhone
                          }
                          type="button"
                          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 disabled:cursor-not-allowed rounded-3xl"
                        >
                          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
                            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center capitalize text-primary">
                              Send me code again
                            </p>
                          </div>
                        </button>
                      </div>
                    )
                  )}
                </div>
                <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                  <Button
                    type="button"
                    text="Cancel"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="outlined"
                    onClick={handleClose}
                  />

                  <Button
                    type="button"
                    text="Verify"
                    filledColor="primary"
                    outlinedColor="primary"
                    textColor="textWhite"
                    className="px-5 py-2"
                    width="35"
                    height="13"
                    fontStyle="font-semibold"
                    variant="filled"
                    onClick={verifyEmailOrPhoneNumber}
                    disabled={isLoading || otp.length < 6}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
