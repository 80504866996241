import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IProtocol } from "store/protocolProfile/initialState";
import WarningIcon from "assets/icons/HeroIcons/WarningIcon";
import useDateFormatter from "hooks/formatDateWithPattern";
import useCustomBranding from "hooks/useCustomBranding";

export const ProtocolDetails = () => {
  const protocolProfile = useSelector<RootState, IProtocol>(
    (state) => state?.protocolProfile
  );
  const { formatDate } = useDateFormatter();
  const { getBrandPlaceholder } = useCustomBranding();
  const protocolAttachedForm = protocolProfile?.form?.find((form: any) => form.isInherit === false);
  return (
    <div className="px-8 pt-6">
      <div>
        <p className="font-semibold text-[18px] text-secondaryMid px-4 capitalize dark:text-textMain">
          Protocol details
        </p>
      </div>

      <div className="flex gap-4 px-4 mt-8 items-center">
        {protocolAttachedForm ? (
          <>
            <div className="w-8 h-8 rounded-full overflow-hidden">
              <img
                src={protocolAttachedForm.image?.thumb || getBrandPlaceholder("listingProfile")}
                alt={protocolAttachedForm.name}
                className="w-full h-full object-cover"
              />
            </div>
            <p className="break-all dark:text-textMain">{protocolAttachedForm.name}</p>
          </>
        ) : (
          <div className="flex items-center gap-2 text-accent_2">
            <WarningIcon />
            <p className="capitalize">No form selected</p>
          </div>
        )}
      </div>

      <div className="flex gap-4 px-4 mt-4 items-center">
        {protocolProfile?.program ? (
          <>
            <div className="w-8 h-8 rounded-full overflow-hidden">
              <img
                src={protocolProfile.program.image?.thumb || getBrandPlaceholder("listingProfile")}
                alt={protocolProfile.program.name}
                className="w-full h-full object-cover"
              />
            </div>
            <p className="break-all capitalize dark:text-textMain">{protocolProfile.program.name}</p>
          </>
        ) : (
          <div className="flex items-center gap-2 text-accent_2">
            <WarningIcon />
            <p className="capitalize">No program selected</p>
          </div>
        )}
      </div>

      <div className="pl-6 pt-6">
        <div className="py-1">
          <p className=" text-secondaryMid capitalize mr-2 dark:text-caption inline">
            Created by:
          </p>
          <p className="text-secondaryMid capitalize inline dark:text-textMain ">
            {protocolProfile?.createdBy?.name}
          </p>
        </div>

        <div className="py-1">
          <p className="text-secondaryMid capitalize mr-2 dark:text-caption inline">Edited :</p>
          <p className="text-secondaryMid capitalize inline dark:text-textMain ">
            {formatDate(protocolProfile?.edited?.date)}
          </p>
        </div>
      </div>
    </div>
  );
};
