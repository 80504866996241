import IsnProfilePlaceholder from "assets/images/Placeholders/isn/isnLogo.png";
import SignProfilePlaceholder from "assets/images/Placeholders/custom-branding/signProfilePlaceholder.png";
import CustomProfilePlaceholder from "assets/images/Placeholders/custom-branding/CustomProfilePlaceholder.png";
import IsnUserProfilePlaceholder from "assets/images/Placeholders/isn/isnUserProfilePlaceholder.png";
import SignUserProfilePlaceholder from "assets/images/Placeholders/custom-branding/signUserProfilePlaceholder.svg";
import IsnCoverPlaceholder from "assets/images/Placeholders/isn/isnCoverPlaceholder.png";
import SignCoverPlaceholder from "assets/images/Placeholders/custom-branding/signCoverPlaceholder.png";
import IsnListProfilePlaceholder from "assets/images/Placeholders/isn/isnListProfilePlaceholder.png";
import SignListProfilePlaceholder from "assets/images/Placeholders/custom-branding/signListProfilePlaceholder.png";
import IsnModuleProfilePlaceholder from "assets/images/Placeholders/isn/isnModuleProfilePlaceholder.png";
import SignModuleProfilePlaceholder from "assets/images/Placeholders/custom-branding/signModuleProfilePlaceholder.png";
import IsnModuleCoverPlaceholder from "assets/images/Placeholders/isn/isnModuleCoverPlaceholder.png";
import SignModuleCoverPlaceholder from "assets/images/Placeholders/custom-branding/signModuleCoverPlaceholder.png";
import IsnModalCoverPlaceholder from "assets/images/Placeholders/isn/isnModalCoverPlaceholder.png";
import SignModalCoverPlaceholder from "assets/images/Placeholders/custom-branding/signModalCoverPlaceholder.png";
import IsnOrganizationProfilePlaceholder from "assets/images/Placeholders/isn/isnOrganizationProfilePlaceholder.png";
import SignOrganizationProfilePlaceholder from "assets/images/Placeholders/custom-branding/signOrganizationProfilePlaceholder.png";
import IsnMessageArchivePlaceholder from "assets/images/Placeholders/isn/isnMessageArchivePlaceholder.png";
import SignMessageArchivePlaceholder from "assets/images/Placeholders/custom-branding/signMessageArchivePlaceholder.svg";
import customBrandingListPlaceholder from "assets/images/Placeholders/custom-branding/customBrandingListPlaceholder.png";
import customBrandingBannerPlaceholder from "assets/images/Placeholders/custom-branding/customBrandingBannnerPlaceholder.png";
import SIGNLogo from "assets/logo/SIGNLogo";
import ISNSidebarLogo from "assets/logo/ISNSidebarLogo";
import ISNLogo from "assets/logo/ISNLogo";
import { hexToRgba } from "./colorRange";

// PLACEHOLDERS
export const brandPlaceholders: any = {
  profile: {
    isn_admin: IsnProfilePlaceholder,
    sign_admin: SignProfilePlaceholder,
    default: CustomProfilePlaceholder,
  },
  userProfile: {
    isn_admin: IsnUserProfilePlaceholder,
    sign_admin: SignUserProfilePlaceholder,
    default: customBrandingListPlaceholder,
  },
  listingProfile: {
    isn_admin: IsnListProfilePlaceholder,
    sign_admin: SignListProfilePlaceholder,
    default: customBrandingListPlaceholder,
  },
  moduleProfile: {
    isn_admin: IsnModuleProfilePlaceholder,
    sign_admin: SignModuleProfilePlaceholder,
    default: customBrandingListPlaceholder,
  },
  organizationProfile: {
    isn_admin: IsnOrganizationProfilePlaceholder,
    sign_admin: SignOrganizationProfilePlaceholder,
    default: customBrandingListPlaceholder,
  },
  cover: {
    isn_admin: IsnCoverPlaceholder,
    sign_admin: SignCoverPlaceholder,
    default: customBrandingBannerPlaceholder,
  },
  moduleCover: {
    isn_admin: IsnModuleCoverPlaceholder,
    sign_admin: SignModuleCoverPlaceholder,
    default: customBrandingListPlaceholder,
  },
  modalCover: {
    isn_admin: IsnModalCoverPlaceholder,
    sign_admin: SignModalCoverPlaceholder,
    default: customBrandingListPlaceholder,
  },
  messageArchive: {
    isn_admin: IsnMessageArchivePlaceholder,
    sign_admin: SignMessageArchivePlaceholder,
    default: customBrandingListPlaceholder,
  },
};

// THEMES
export const themes: any = {
  sign_admin: {
    primary: "#8D2A25",
    primaryDark: "#7C241F",
    primaryMid: "#8D2A25",
    primaryExtraLight: "#F6EDDD",
    primaryMidLight: "#8D2A25",
    bgBluish_2: "#F6EDDD",
    textLink: "#8D2A25",
    bgHighMidlight: "#ededed3b",
  },
  isn_admin: {
    primary: "#005C89",
    primaryDark: "#024C6F",
    primaryMid: "#0A7BAC",
    primaryExtraLight: "#EBF2F6",
    primaryMidLight: "#11A3E2",
    bgBluish_2: "#E6EFF4",
    bgHighMidlight: "#F2F7F9",
    primary_20: hexToRgba("#005C89", 0.2),
    primary_40: hexToRgba("#005C89", 0.4),
  },
};

export const brandLogos: any = {
  isn_admin: ISNLogo,
  sign_admin: SIGNLogo,
};

export const brandFavicons: any = {
  isn_admin: "/isn-favicon.ico",
  sign_admin: "/sign-favicon.ico",
  custom: "/custom-favicon.ico",
};

export const staticBrands: string[] = ["isn_admin", "sign_admin"];

export const getAppName = () => {
  const brands = JSON.parse(localStorage.getItem("brands-themes") || "[]");
  const domain = window.location.origin;

  const existingBrand = brands.find((brand: any) => brand.domain === domain);
  // Return the app name if found, otherwise return "isn_admin"
  return existingBrand?.data?.apps?.admin ?? "isn_admin";
};

export const appName = () => {
  const brands = JSON.parse(localStorage.getItem("brands-themes") || "[]");
  const domain = window.location.origin;

  const existingBrand = brands.find((brand: any) => brand.domain === domain);
  // Return the app name if found, otherwise return "isn_admin"
  return existingBrand?.data?.apps?.app ?? "isn";
};
