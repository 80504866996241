import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { addSurveyFormScema } from "utils/validationSchemas";
import { Toasts } from "view/components/Toasts";
import PersonPlaceholder from "assets/images/Placeholders/OrganizationPlaceholder.png";
import Button from "view/components/Button";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useDateFormatter from "hooks/formatDateWithPattern";
import {
  closeAddSurveyFormModal,
  closeNotificationsCenterModalAction,
} from "store/modals/reducer.actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import apiLibrary from "services/api";
import { useNavigate, useParams } from "react-router-dom";
import { SideNav } from "store/sideBar/initialState";
import { fetchNotificationCenterAction } from "store/notificationCenter";
import { filtersInitialState } from "store/filters/initialState";
import { IfetchNotifications } from "store/notificationCenter/initialState";
import ArrowUpRightExpandIcon from "assets/icons/HeroIcons/ArrowUpRightExpandIcon";
import { CheveronRightIcon } from "assets/icons/HeroIcons";
import usePermissions from "hooks/usePermissions";

/**
 * Component for adding, editing, or using a form as a template.
 * @returns {JSX.Element} The component's JSX representation
 */

export const NotificationsCenterModal = () => {
  const { isCollapsed } = useSelector<RootState, SideNav>(
    (state) => state?.sideBar
  );
  const { notifications } = usePermissions();
  const style: React.CSSProperties = {
    position: "absolute",
    top: "2%",
    left: `${isCollapsed ? "250px" : "408px"}`,
    transform: "translate(-50%, 0%)",
    backgroundColor: "#ffff",
    borderRadius: "10px",
    zIndex: "50px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  };
  // State variables
  const { notificationsCenterModal } = useSelector(
    (state: RootState) => state.modals
  );
  const { notificationCenterFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state?.Filters);
  const { timeSince } = useDateFormatter();
  const { data } = useSelector<RootState, IfetchNotifications>(
    (state) => state.notificationCenter
  );
  const { programId } = useParams();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const navigate = useNavigate();
  /**
   * Handle modal closure.
   * @example
   * handleClose();
   */
  const handleClose = () => {
    dispatch(closeNotificationsCenterModalAction());
  };
  const handleNotificationClick = async (notificationId: number) => {
    try {
      const response =
        await apiLibrary.NotificationCenter.readNotification(notificationId);
      Toasts.success(response.message);
      dispatch(fetchNotificationCenterAction());
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };
  const handleMarkAllRead = async () => {
    try {
      const response =
        await apiLibrary.NotificationCenter.readAllNotifications();
      Toasts.success(response.message);
      dispatch(fetchNotificationCenterAction());
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    }
  };
  const handleChangeRoute = () => {
    navigate("/notification-center/list");
    handleClose();
  };
  useEffect(() => {
    dispatch(fetchNotificationCenterAction());
  }, [notificationCenterFilter]);

  return (
    <Modal
      open={notificationsCenterModal.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
      BackdropProps={{
        style: {
          backgroundColor: "transparent", // Makes the backdrop transparent
          boxShadow: "none", // Removes any shadow that might be visible
        },
      }}
    >
      <Box
        sx={style}
        className={`dark:bg-secondaryLight w-[360px] ${data.unreadCount > 8 ? "h-screen " : "h-screen"} relative`}
      >
        <div className="flex-col h-full  flex-">
          <div className="flex py-2 justify-between items-center h-[6vh]">
            <div className="w-2/5 px-2 mt-[2px] dark:text-textMain">
              Unread: {data.unreadCount ?? 0}
            </div>
            <div className="flex items-center justify-end w-3/5 px-3">
              <div className="mr-4">
                {notifications.canCreateNotifications && (
                  <button
                    className={`mt-[2px] dark:text-textMain text-primary ${!notifications.canCreateNotifications && "opacity-50 cursor-not-allowed"}`}
                    onClick={handleMarkAllRead}
                    disabled={!notifications.canCreateNotifications}
                  >
                    Mark all as read
                  </button>
                )}
              </div>
              <div className="flex gap-2">
                {notifications.canViewNotifications && (
                  <button onClick={handleChangeRoute}>
                    <ArrowUpRightExpandIcon className={"w-5 h-5 mt-1"} />
                  </button>
                )}
                <button onClick={handleClose}>
                  <XCloseIcon className="" fill="#6F747B" />
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-h-[88vh] min-h-[88vh] overflow-y-auto">
            <div className="flex flex-col max-h-[88vh] min-h-[88vh] overflow-y-auto">
              {data?.notificationsCenter
                ?.slice(0, 10)
                .map((notification: any, index: number) => {
                  return (
                    <>
                      {!notification?.isRead && (
                        <div
                          className={`mb-1 ${
                            !notification.isRead &&
                            "bg-bgHighMidlight cursor-pointer dark:bg-secondaryLight"
                          } `}
                          key={index}
                          onClick={
                            !notification.isRead &&
                            notifications.canReadNotifications
                              ? () => handleNotificationClick(notification.id)
                              : undefined
                          }
                          // disabled={!notifications.canReadNotifications}
                        >
                          <div
                            className={`flex px-4 py-3 ${!notifications.canReadNotifications && "opacity-50 cursor-not-allowed"}`}
                          >
                            <div className="flex items-center">
                              {!notification.isRead && (
                                <div className="w-2 h-2 mr-2 rounded-full bg-primaryMidLight"></div>
                              )}

                              <div className=" w-[35px] h-[35px] rounded-full">
                                <img
                                  src={
                                    notification?.profileImage
                                      ? notification?.profileImage
                                      : PersonPlaceholder
                                  }
                                  alt={notification?.title}
                                  className="object-cover w-full h-full rounded-full"
                                />
                              </div>
                            </div>
                            <div className="w-full ml-3">
                              <p className="text-xs font-normal text-textLight">
                                {timeSince(notification?.createdAt)}
                              </p>

                              <p
                                className="w-full mr-1 overflow-hidden text-sm font-normal leading-5 custom-html-content text-textNeutralGray dark:text-caption font-Overpass overflow-ellipsis line-clamp-2"
                                dangerouslySetInnerHTML={{
                                  __html: notification?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
            <div className="px-2 flex items-center justify-end gap-1 cursor-pointer h-[4vh]">
              <button className="flex items-center" onClick={handleChangeRoute}>
                <p className="dark:text-textMain">View more</p>
                <CheveronRightIcon />
              </button>
            </div>
          </div>
          <div className="px-2 flex items-center justify-end gap-1 cursor-pointer h-[4vh]">
            <button className="flex items-center" onClick={handleChangeRoute}>
              <p className="dark:text-textMain">View more</p>
              <CheveronRightIcon />
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
