import React, { useEffect } from "react";
// React Lib
import { RadioGroup } from "@mui/material";
// Components
import { RadioBtn } from "Components/RadioBtn";
import { useSelector } from "react-redux";
import useCustomBranding from "hooks/useCustomBranding";

interface IProps {
  setFieldValue: any;
  values: any;
  editMode?: boolean;
}

export const PubliclyVisible: React.FC<IProps> = ({
  setFieldValue,
  values,
  editMode,
}) => {
  // Access the theme colors from the Redux store
  const { theme } = useCustomBranding();

  // Access the theme colors
  const primary = theme?.colors?.primary;

  // useEffect for bydefault radio value
  useEffect(() => {
    // Set default value if none exists
    if (!values.type) {
      setFieldValue("type", "private"); // Default to "private"
    }
  }, [values.type, setFieldValue]);

  return (
    <div>
      <p className="text-lg font-semibold font-Overpass text-secondaryMid py-2">
        Publicly Visible
      </p>
      <p className="text-sm text-secondaryMid font-Overpass py-1">
        Indicate if this brand will be publicly visible to all users or only
        visible to those who have received the unique brand code. To log in to a
        brand, all users will be provided valid credentials upon invitation to
        the brand.
      </p>
      <div>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={values.type === "public" ? "public" : "private"}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue("type", event.target.value);
          }}
        >
          <div className="">
            <RadioBtn
              value="public"
              labelContent={
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#2C3236",
                  }}
                >
                  <p className="text-sm font-Overpass text-left capitalize text-textMid dark:text-textMain ml-1">
                    Public
                  </p>
                </span>
              }
              sx={{
                color:
                  !editMode || values.type !== "public" ? "#00000042" : primary, // Example of overriding the default checked color
                "&.Mui-checked": {
                  color: !editMode ? "#00000042" : primary, // This will make the checked state green
                },
              }}
              disabled={!editMode}
            />
            <RadioBtn
              value="private"
              labelContent={
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#2C3236",
                  }}
                >
                  <p className="text-sm font-Overpass text-left capitalize text-textMid dark:text-textMain ml-1">
                    Private
                  </p>
                </span>
              }
              sx={{
                color:
                  !editMode || values.type !== "private"
                    ? "#00000042"
                    : primary, // Example of overriding the default checked color
                "&.Mui-checked": {
                  color: !editMode ? "#00000042" : primary, // This will make the checked state green
                },
              }}
              disabled={!editMode}
            />
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};
