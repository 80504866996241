import { useSelector } from 'react-redux';
import useCustomBranding from 'hooks/useCustomBranding';

// Static brand logos and styles

const FormHeader = () => {
  const { theme, DefaultLogo } = useCustomBranding();

  const CURRENT_BRAND_LOGO = theme?.logo ?
    <img src={theme?.logo?.file?.thumb} alt={`${theme?.brandName} Logo`} style={{ width: '42px', height: '42px' }} /> :
    <DefaultLogo width={42} height={42} />

  const CURRENT_BRAND_NAME = theme?.brandName;
  const CURRENT_BRAND_STYLE = 'text-primaryDark dark:text-primary';

  return (
    <div className='flex justify-center items-center gap-3 pt-[62px] pb-[60px] xl:pt-[72px] xl:pb-[70px] bg-white'>
      <div className='flex justify-start items-center'>
        {CURRENT_BRAND_LOGO}
      </div>
      <p className={`text-lg font-bold ${CURRENT_BRAND_STYLE}`}>{CURRENT_BRAND_NAME}</p>
    </div>
  );
};

export default FormHeader;
